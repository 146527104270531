import styled from 'styled-components';
import Box from '@hitagi/core/Box';
import { modifyPseudoOutlineColor, pseudoOutline } from '@hitagi/core/styles/cssChunks';

export const PickBan = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  justify-content: space-between;
  line-height: 1;
  font-weight: 600;
  background-color: ${({ theme }) => theme.palette.background.clearGlass[2]};
  align-items: center;

  /* NOTE: styles below are not finilized. just an experiment for discussion. */
  position: relative;

  @media print {
    img {
      z-index: 1;
    }

    ${pseudoOutline}
    ${modifyPseudoOutlineColor(props => props.theme.palette.background.tintedGlass)}
  }
`;

export const Cell = styled(Box)`
  padding: ${({ theme, pb, paddingBottom }) => theme.spacing(2, 2, pb ?? paddingBottom ?? 2, 2)};

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.palette.background.clearGlass[1]};
  }
`;

const getOutcomeColor = ({ theme, isWin }) => isWin
  ? theme.palette.outcome.victory
  : theme.palette.outcome.defeat;
export const OutcomeIndicator = styled.div`
  width: 4px;
  background-color: ${getOutcomeColor};
`;

export const Label = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.clearGlass[2]};
  text-align: center;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 0 0.6em;
`;

export const OnlyPrintBox = styled(Box)`
  display: none;

  @media print {
    display: ${props => props.display ?? 'block'};
  }
`;

export const NonCmBansWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${props => props.theme.spacing(0.5, 1.5)};

  ${PickBan} {
    margin: ${props => props.theme.spacing(0.5)};
  }
`;
