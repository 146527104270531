import React, { useState, useEffect, useCallback } from 'react';
import Container from '@hitagi/core/Layout/Container';
import { useParams, Link } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import { EMPTY_ARRAY } from '@hitagi/utils/empties';
import { get } from '@hitagi/utils';
import Text from '@hitagi/core/Text';
import Box from '@hitagi/core/Box';
import Button from '@hitagi/core/Button';
import ActionHeader from '../../../ActionHeader';
import { useTeamMatchesQuery } from '../../queries';
import { groupMatches } from '../helpers';
import { useLinkMutation } from './queries';
import DroppableSeries from './DroppableSeries';
import PageError from '../../../PageError';
import PageSpinner from '../../../PageSpinner';

const isDroppableOfMatch = droppable => droppable.droppableId.startsWith('match-');
const isDroppableOfSeries = droppable => droppable.droppableId.startsWith('series-');

const getGroupIndex = (series, droppable) => {
  if (isDroppableOfMatch(droppable)) {
    const matchId = +droppable.droppableId.replace('match-', '');
    return series.find(s => s[0].id === matchId);
  }
  if (isDroppableOfSeries(droppable)) {
    const seriesId = +droppable.droppableId.replace('series-', '');
    return series.find(s => s[0].seriesId === seriesId);
  }
  return undefined;
};

const Review = () => {
  const params = useParams();
  const teamId = +params.teamId || 0;

  const { loading, error, data } = useTeamMatchesQuery(teamId, { isValidated: false });

  const [series, setSeries] = useState(EMPTY_ARRAY);

  const matches = get(['yogurt', 'overview', 'matches'], data);
  useEffect(() => {
    if (matches) {
      setSeries(groupMatches(matches));
    }
  }, [matches]);

  const [linkMatchIds] = useLinkMutation();

  const onDragEnd = useCallback(({ source: src, destination: dst }) => {
    // dropped nowhere
    if (!dst) {
      return;
    }

    // did not move anywhere
    if (src.droppableId === dst.droppableId) {
      return;
    }

    setSeries(prev => {
      const srcGroup = getGroupIndex(prev, src);
      const dstGroup = getGroupIndex(prev, dst);

      if (!srcGroup && !dstGroup) {
        return prev;
      }

      linkMatchIds({
        variables: {
          teamId,
          matchIds: [
            srcGroup[0].id,
            dstGroup[0].id,
          ],
        },
      });

      const next = Array.from(prev);

      // reorder
      const [removed] = srcGroup.splice(src.index, 1);
      dstGroup.splice(dst.index, 0, removed);

      // cleanup
      if (!srcGroup.length) {
        next.splice(next.indexOf(srcGroup), 1);
      }

      return next;
    });
  }, [teamId, linkMatchIds]);

  if (error) {
    return <PageError error={error} />;
  }

  if (loading && !matches) {
    return <PageSpinner />;
  }

  if (!matches || !matches.length) {
    return (
      <Container center col>
        <ActionHeader title="You don't have any matches to review" />
        <Text textAlign="center">
          When you import or upload match, it&apos;ll show up here
        </Text>
        <Box row marginTop={2} alignItems="center">
          <Button as={Link} to={`/teams/${params.teamId}/upload`}>
            Upload
          </Button>
          <Box marginX={2}>or</Box>
          <Button as={Link} to={`/teams/${params.teamId}/import`}>
            Import
          </Button>
        </Box>
      </Container>
    );
  }

  const renderSeries = s => (
    <DroppableSeries
      key={s[0].id}
      series={s}
      teamId={teamId}
      dotaTeamId={data.yogurt.team.team?.id}
    />
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container marginY={3}>
        {series.map(renderSeries)}
      </Container>
    </DragDropContext>
  );
};

export default Review;
