import React from 'react';
import Container from '@hitagi/core/Layout/Container';
import Material from '@hitagi/core/Material';
import List from '@hitagi/core/List';
import MenuItem from '@hitagi/core/MenuItem';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import Button from '@hitagi/core/Button';
import StarIcon from '@hitagi/icons/Star';
import { Link } from 'react-router-dom';
import SparklesIcon from '@hitagi/icons/Sparkles';
import Divider from '@hitagi/core/Divider';
import Box from '@hitagi/core/Box';
import { useTeamsQuery } from '../utils/team';
import ActionHeader from './ActionHeader';
import PageSpinner from './PageSpinner';
import PageError from './PageError';
import TeamImage from './TeamImage';

const Dashboard = () => {
  const { loading, error, data } = useTeamsQuery();

  if (loading) {
    return <PageSpinner />;
  }

  if (error) {
    return <PageError error={error} />;
  }

  const teams = data && data.yogurt.teams;
  if (!teams || !teams.length) {
    return (
      <Container center marginY={3} col alignItems="center">
        <ActionHeader
          emoji="😪"
          ariaLabel="Sleepy Face"
          title="You don't have any datasets"
        />
        <Button mt={2} as={Link} to="/teams/new">
          Create a dataset
        </Button>
      </Container>
    );
  }

  const defaultTeam = teams.find(team => team.isDefault);

  const renderTeam = team => (
    <MenuItem key={team.id} as={Link} to={`/teams/${team.id}`}>
      <ListItemElement>
        <TeamImage id={team.team?.id || 0} />
      </ListItemElement>
      <ListItemText primary={team.name} />
      {team.isDefault && (
        <ListItemElement>
          <Button isIconOnly disableOutline disableBg disabled>
            <StarIcon />
          </Button>
        </ListItemElement>
      )}
    </MenuItem>
  );

  return (
    <Container marginY={3} center>
      <Material padding={0} width={420}>
        <List>
          {defaultTeam && renderTeam(defaultTeam)}
          {teams.map(team => !team.isDefault && renderTeam(team))}
          {teams.length > 0 && <Divider m="4px" />}
          <MenuItem as={Link} to="/teams/new">
            <ListItemElement as={Box} width="100%" height="100%">
              <SparklesIcon />
            </ListItemElement>
            <ListItemText primary="Create a dataset" />
          </MenuItem>
        </List>
      </Material>
    </Container>
  );
};

export default Dashboard;
