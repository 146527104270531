import React from 'react';
import PropTypes from 'prop-types';
import { MenuDisclosure } from '@hitagi/core/Menu';

function FilterDisclosure({ active, ...restProps }) {
  return (
    <MenuDisclosure
      variant="outlined"
      color={active ? 'primary' : 'default'}
      {...restProps}
    />
  );
}

FilterDisclosure.propTypes = {
  active: PropTypes.bool,
};

FilterDisclosure.sc = MenuDisclosure.sc;

export default FilterDisclosure;
