import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Client } from '@hitagi/redux/client';
import Text from '@hitagi/core/Text';
import Material from '@hitagi/core/Material';
import Box from '@hitagi/core/Box';
import Divider from '@hitagi/core/Divider';
import Spinner from '@hitagi/core/Spinner';
import Ellipsis from '@hitagi/core/Ellipsis';
import CheckmarkIcon from '@hitagi/icons/Checkmark';
import BugIcon from '@hitagi/icons/Bug';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import tus from 'tus-js-client';
import uuid from 'uuid/v5';
import { useUser } from '../../../../utils/user';

const Indicator = styled.span`
  margin-right: ${props => props.theme.spacing(1)};
  height: 1.1em;
`;

const endpoint = `${Client.url}/${Client.urlVersion}/replay/upload`;
const retryDelays = [0, 3000, 5000, 10000, 20000];

const initialState = { loading: false, error: null, progress: 0 };
const reducer = (state, action) => {
  switch (action.type) {
  case 'request':
    return { ...initialState, loading: true };
  case 'progress':
    return { ...state, progress: action.payload };
  case 'success':
    return { ...state, loading: false };
  case 'failure':
    return { ...state, loading: false, error: action.payload };
  default:
    throw new Error();
  }
};

const renderState = state => {
  const { loading, error, progress } = state;

  if (error) {
    return (
      <Text color="red.main">
        <Indicator as={BugIcon} />
        Error! <br /> {error.message}
      </Text>
    );
  }

  if (loading) {
    return (
      <>
        <Indicator as={Spinner} />
        <Ellipsis>Uploading</Ellipsis>
        {Math.round(progress * 100)}%
      </>
    );
  }

  if (progress === 1) {
    return <><Indicator as={CheckmarkIcon} /> Done!</>;
  }

  return null;
};

const UploadFile = ({ file }) => {
  const user = useUser();
  const namespace = user.data.profile.captainJackIdentityId;

  const params = useParams();
  const teamId = +params.teamId;

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const upload = new tus.Upload(file, {
      endpoint,
      retryDelays,
      chunkSize: 10000000,
      metadata: {
        fileName: file.name,
        matchReplayUploadTeamId: teamId,
        uuid: uuid(file.name, namespace),
      },
      headers: {
        authorization: `Bearer ${Client.token}`,
      },
      onError: err => {
        dispatch({ type: 'failure', payload: err });
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        dispatch({ type: 'progress', payload: bytesUploaded / bytesTotal });
      },
      onSuccess: () => {
        dispatch({ type: 'success' });
      },
    });

    dispatch({ type: 'request' });
    upload.start();

    return () => upload.abort();
  }, [file, namespace, teamId]);

  return (
    <Material>
      <Text variant="callout">
        {file.name}
      </Text>
      <Divider />
      <Box row alignItems="center">
        {renderState(state)}
      </Box>
    </Material>
  );
};

UploadFile.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
};

export default UploadFile;
