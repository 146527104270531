import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@hitagi/core/Menu';
import Box from '@hitagi/core/Box';
import Scrollbar from '@hitagi/core/Scrollbar';
import Button from '@hitagi/core/Button';
import MenuContent from './MenuContent';
import MenuActions from './MenuActions';

function Filter({
  children,
  canClear,
  onClear,
  onSave: onSaveProp,
  anchorEl,
  onOpen,
  onClose,
  scrollable,
  searchInput,
  ...restProps
}) {
  const onSave = event => {
    onClose();
    if (onSaveProp) {
      onSaveProp(event);
    }
  };

  return (
    <Menu anchorEl={anchorEl} onOpen={onOpen} onClose={onClose} {...restProps}>
      {searchInput && (
        <Box paddingTop={2} paddingX={2}>
          {searchInput}
        </Box>
      )}
      {scrollable ? (
        <Scrollbar height={310}>
          <MenuContent>
            {children}
          </MenuContent>
        </Scrollbar>
      ) : (
        <MenuContent>
          {children}
        </MenuContent>
      )}
      {(onClear || onSave) && (
        <MenuActions>
          {onClear && (
            <Button onClick={onClear} disableOutline disableBg disabled={!canClear}>
              Clear
            </Button>
          )}
          <Box flexGrow={1} />
          {onSave && (
            <Button onClick={onSave}>
              Save
            </Button>
          )}
        </MenuActions>
      )}
    </Menu>
  );
}

Filter.propTypes = {
  children: PropTypes.node,
  canClear: PropTypes.bool,
  onClear: PropTypes.func,
  onSave: PropTypes.func,
  anchorEl: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  scrollable: PropTypes.bool,
  searchInput: PropTypes.node,
};

export default Filter;
