import React from 'react';
import { omit, phyAvatar } from '@hitagi/utils';
import styled from 'styled-components';

const StyledImg = styled.img`
  display: block;
  object-fit: contain;
`;

// if `steamAccountId` is passed - portrait will be rendered.
type SteamAccountIdProps = { steamAccountId: number }

// if `avatar` is passed - avatar from that url will be rendered,
// except if it's default questionmark - it'll be replaced to Phy's custom
// default version.
type AvatarProps = { avatar: string }

const isSteamAccountIdProps = (props: SteamAccountIdProps | AvatarProps)
  : props is SteamAccountIdProps => (props as any).steamAccountId !== undefined;

const PROPS_TO_OMIT = ['steamAccountId', 'avatar'] as (
  keyof SteamAccountIdProps & keyof AvatarProps
);

export type PlayerImageProps = SteamAccountIdProps | AvatarProps
  & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet' | 'sizes'>

const PlayerImage = (props: PlayerImageProps) => {
  const src = isSteamAccountIdProps(props)
    ? `${process.env.REACT_APP_CDN_HOST}/images/dota2/players/${props.steamAccountId}.png`
    : phyAvatar(props.avatar);
  return <StyledImg src={src} {...omit(PROPS_TO_OMIT, props)} />;
};

export default PlayerImage;
