import PropTypes from 'prop-types';
import { useUser } from '../utils/user';

// TODO(carceri):
// 1. add 'loading' fallback, add 'unauthenticated' fallback;
//    for example on home page we display Spinner and ShrugMan ¯\_(ツ)_/¯
// 2. replace all possible occurences of prev said thing.
const ProtectedNode = ({ children }) => {
  const { loading, isLoggedIn } = useUser();

  if (!loading && isLoggedIn) {
    return children;
  }

  return null;
};

ProtectedNode.propTypes = {
  children: PropTypes.node,
};

export default ProtectedNode;
