import React from 'react';
import PropTypes from 'prop-types';
import Container from '@hitagi/core/Layout/Container';
import DetailItem from '@hitagi/core/DetailItem';
import DetailList from '@hitagi/core/DetailList';
import { get } from '@hitagi/utils';
import { FormattedNumber } from 'react-intl';

const renderRecord = (matchCount, winCount) => (
  <DetailItem
    label="Record"
    value={`${(winCount)} - ${matchCount - winCount}`}
  />
);

// TODO: move to utils in hitagi (not dota utils, applicable to probably all games)
const getWinRate = (matchCount, winCount) => winCount / matchCount;
const renderWinRate = (matchCount, winCount) => (
  <DetailItem
    label="Win Rate"
    value={<FormattedNumber value={getWinRate(matchCount, winCount)} style="percent" maximumFractionDigits={2} />}
  />
);

const DraftsSummary = ({ info }) => {
  const matchCount = get(['overview', 'matchCount'], info);
  const winCount = get(['overview', 'winCount'], info);

  return (
    <Container>
      <DetailList>
        {renderRecord(matchCount, winCount)}
        {renderWinRate(matchCount, winCount)}
      </DetailList>
    </Container>
  );
};

DraftsSummary.propTypes = {
  info: PropTypes.shape({
    overview: PropTypes.shape({
      matchCount: PropTypes.number,
      winCount: PropTypes.number,
    }),
  }),
};

export default DraftsSummary;
