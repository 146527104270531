import React, { useState } from 'react';
import { Position, PositionIcon, PositionName, POSITION_NAME_MAPPING } from '@hitagi/dota/Position';
import { useIntl } from 'react-intl';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import Divider from '@hitagi/core/Divider';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import LabelButton from '@hitagi/core/LabelButton';
import Phantom from '@hitagi/core/Phantom';
import SelectMenu from '@hitagi/core/SelectMenu';
import SvgIcon from '@hitagi/icons/SvgIcon';
import messages from './messages';

export type PositionPageModifierProps = {
  positionId: Position.Id | undefined
  setPositionId: (positionId: Position.Id | undefined) => void
  unlistUnion?: boolean
}

const PositionPageModifier = (props: PositionPageModifierProps) => {
  const {
    positionId,
    setPositionId,
    unlistUnion,
    ...restProps
  } = props;

  const intl = useIntl();

  const [search, setSearch] = useState('');
  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };
  const handleSelection = (event: React.MouseEvent<HTMLLIElement>) => {
    const { value } = event.currentTarget.dataset;
    const normalizedValue = value === undefined ? undefined : (+value as Position.Id);
    setPositionId(normalizedValue);
    setSearch('');
  };

  const normalizedSearch = search.toLowerCase();
  const renderItem = (value: Position.Id) => {
    const string = intl.formatMessage(POSITION_NAME_MAPPING[value]).toLowerCase();
    if (!string.includes(normalizedSearch)) return null;
    return (
      <SelectMenu.Item
        key={value}
        selected={value === positionId}
        data-value={value}
        onClick={handleSelection}
      >
        <ListItemElement>
          <PositionIcon id={value} />
        </ListItemElement>
        <ListItemText primary={<PositionName id={value} />} />
      </SelectMenu.Item>
    );
  };

  const unionSelected = positionId === undefined;

  return (
    <SelectMenu {...restProps}>
      <summary>
        <LabelButton>
          {!unionSelected && (
            <LabelButton.Graphic size={LabelButton.Graphic.size.medium}>
              <PositionIcon id={positionId!} />
            </LabelButton.Graphic>
          )}
          <LabelButton.Text>
            {unionSelected
              ? intl.formatMessage(messages.allPositions)
              : <PositionName id={positionId!} />}
          </LabelButton.Text>
          <Divider vertical margin={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.Filter
          placeholder={intl.formatMessage(messages.filterPositions)}
          value={search}
          onChange={handleSearch}
        />
        <SelectMenu.List>
          {!unlistUnion && !search && (
            <SelectMenu.Item
              selected={!Position.isId(positionId)}
              onClick={handleSelection}
            >
              <ListItemElement>
                <SvgIcon />
              </ListItemElement>
              <ListItemText primary={intl.formatMessage(messages.allPositions)} />
            </SelectMenu.Item>
          )}
          <Phantom>
            {() => Position.ids.map(renderItem)}
          </Phantom>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default PositionPageModifier;
