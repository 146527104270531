import React from 'react';
import Container from '@hitagi/core/Layout/Container';
import Spinner from '@hitagi/core/Spinner';

const PageSpinner = props => (
  <Container center marginY={3} {...props}>
    <Spinner />
  </Container>
);

export default PageSpinner;
