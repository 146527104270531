import './entry';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { Persist } from '@hitagi/redux/persist';
import { Client } from '@hitagi/redux/client';
import { setTheme } from '@hitagi/redux/actions/ui';
import { getToken } from '@hitagi/utils/auth';
import { getMyProfile } from '@hitagi/redux/actions/me';
import querystring from 'querystring';
import { setDotaConstants, selectDotaConstants } from './store/allDotaConstants';
import { DOTA_CONSTANTS_QUERY_VERSION, fetchDotaConstants, setLocalDotaConstants, fetchNewGameVersion } from './utils/dotaConstants';
import App from './components/App';
import store from './store';
import apollo from './apollo';
import { createTheme } from './utils/theme';
import { history, BrowserRouter } from './router';

const initTheme = () => {
  const themeType = Persist.ui.theme.type.get() || 'light';
  const theme = createTheme(themeType);

  store.dispatch(setTheme(theme));
};

const initUser = () => {
  const token = getToken() || Persist.me.token.get();
  if (!token) {
    return;
  }

  Client.setToken(token);
  store.dispatch(getMyProfile());
};

const initLocation = () => {
  let { search } = history.location;
  const { pathname } = history.location;
  const params = querystring.parse(search.slice(1));

  // we should not expose token in url after we get it
  delete params.token;

  search = Object.keys(params).length > 0
    ? `?${querystring.stringify(params)}`
    : '';

  history.replace({ ...history.location, pathname, search });
};

const initDotaConstants = async () => {
  const state = store.getState();
  const languageEnum = 'ENGLISH';
  const localConsts = selectDotaConstants(state);

  const shouldFetchNewConsts = localConsts.queryVersion !== DOTA_CONSTANTS_QUERY_VERSION
    || localConsts.languageEnum !== languageEnum
    || !localConsts.abilities?.length
    || !localConsts.heroes?.length
    || !localConsts.items?.length
    || !localConsts.gameVersions?.length;

  if (!shouldFetchNewConsts) {
    const lastKnownGameVersionId = localConsts.gameVersions[0].id;
    const newGameVersion = await fetchNewGameVersion(apollo, lastKnownGameVersionId);
    if (!newGameVersion) return;
  }

  const newConsts = await fetchDotaConstants(apollo, languageEnum);
  if (!newConsts) return;

  store.dispatch(setDotaConstants(newConsts));
  setLocalDotaConstants(newConsts);
};

const rootElement = document.getElementById('root');
const render = () => {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <ApolloProvider client={apollo}>
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </ReduxProvider>,
    rootElement,
  );
};

Promise.resolve()
  .then(initUser)
  .then(initLocation)
  .then(initTheme)
  .then(render)
  .then(initDotaConstants)
  .catch(error => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error('app failed:', error);
    }
  });
