import { defineMessages } from 'react-intl';

export default defineMessages({
  allGameModes: 'All game modes',
  allPositions: 'All positions',
  allTeams: 'All teams',
  filterGameModes: 'Filter game modes',
  filterHeroes: 'Filter heroes',
  filterPositions: 'Filter positions',
  filterTeams: 'Filter teams',
  allPlayers: 'All players',
  filterPlayers: 'Filter players',
  allLeagues: 'All leagues',
  filterLeagues: 'Filter leagues',
  allLobbyTypes: 'All lobby types',
  filterLobbyTypes: 'Filter lobby types',
});
