import React, { useState, useCallback } from 'react';
import Container from '@hitagi/core/Layout/Container';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import styled from 'styled-components';
import Dropzone from './Dropzone';
import File from './File';

const FilesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  margin: ${({ theme }) => theme.spacing(3, 0)};
  align-content: start;
`;

const Upload = () => {
  const [files, setFiles] = useState();

  const onFiles = useCallback(acceptedFiles => {
    setFiles((prevFiles = []) => acceptedFiles.reduce((acc, file) => {
      const isDem = file.name.endsWith('.dem');
      if (!isDem) {
        return acc;
      }

      const isNew = acc.findIndex(f => f.name === file.name) === -1;
      if (!isNew) {
        return acc;
      }

      acc.push(file);
      return acc;
    }, [...prevFiles]));
  }, []);

  return (
    <Container marginY={3}>
      <Dropzone onFiles={onFiles} />
      <FilesSection row marginY={2}>
        {files && files.map(file => <File key={file.name} file={file} />)}
      </FilesSection>
      <Box display="flex" justifyContent="center" mt={3}>
        <Text color="text.secondary" textAlign="center" width={600}>
          After you load your matches, go to the Review tab to make
          any changes and bring them into your Dataset.
          Then, you can see your Draft and Heroes
          data on their respective pages.
        </Text>
      </Box>
    </Container>
  );
};

export default Upload;
