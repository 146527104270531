import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DetailList from '@hitagi/core/DetailList';
import DetailItem from '@hitagi/core/DetailItem';
import Box from '@hitagi/core/Box';
import { PatchName } from '@hitagi/dota/Patch';
import { matchType } from '../../MatchRow/Draft/propTypes';

const Wrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.palette.background.clearGlass[2]};
  border-top-left-radius: ${props => props.theme.shape.borderRadius}px;
  border-top-right-radius: ${props => props.theme.shape.borderRadius}px;
  padding: ${props => props.theme.spacing(1, 2)};

  dt, dd {
    display: inline-block;
    font-size: 1em;
  }

  dt {
    margin-right: ${props => props.theme.spacing(1)};
  }
`;

const MatchSeriesHeader = ({ series, menu }) => {
  const [match] = series;

  return (
    <Wrapper>
      <DetailList>
        <DetailItem
          variant="secondary"
          label="Patch"
          value={<PatchName id={match.gameVersionId} />}
        />
        {match.seriesId && (
          <DetailItem
            variant="secondary"
            label="Series ID"
            value={match.seriesId}
          />
        )}
        {match.league && (
          <DetailItem
            variant="secondary"
            label="League"
            value={(
              <a href={`https://stratz.com/leagues/${match.league.id}`} target="_blank" rel="noreferrer">
                {match.league.displayName}
              </a>
            )}
          />
        )}
      </DetailList>
      <Box flexGrow={1} />
      {menu}
    </Wrapper>
  );
};

MatchSeriesHeader.propTypes = {
  series: PropTypes.arrayOf(matchType),
  menu: PropTypes.node,
};

export default MatchSeriesHeader;
