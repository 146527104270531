import React from 'react';
import Container from '@hitagi/core/Layout/Container';
import useQueryStringReducer from '@hitagi/core/useQueryStringReducer';
import { get } from '@hitagi/utils';
import { useParams } from 'react-router-dom';
import { reducer, initialState, stringifiers, parsers } from '../Matches/flux';
import { useTeamMatchesQuery } from '../queries';
import TableBan from './TableBan';
import TablePick from './TablePick';
import TableWin from './TableWin';
import Filters from '../Matches/Filters';
import PageSpinner from '../../PageSpinner';
import PageError from '../../PageError';

const Heroes = () => {
  const params = useParams();
  const teamId = +params.teamId;

  const [state, dispatch] = useQueryStringReducer(reducer, initialState, stringifiers, parsers);
  const { loading, error, data } = useTeamMatchesQuery(teamId, {
    ...state,
    isValidated: true,
  });

  const matches = get(['yogurt', 'overview', 'matches'], data);
  const heroes = get(['yogurt', 'heroSummary'], data);

  if (!heroes && loading) {
    return <PageSpinner />;
  }

  if (!heroes && error) {
    return <PageError error={error} />;
  }

  const matchCount = get(['overview', 'matchCount'], data.yogurt);

  return (
    <>
      <Filters state={state} dispatch={dispatch} matches={matches} />
      <Container display="flex" justifyContent="center">
        <TableWin heroes={heroes} />
        <TablePick heroes={heroes} matchCount={matchCount} />
        <TableBan heroes={heroes} matchCount={matchCount} />
      </Container>
    </>
  );
};

export default Heroes;
