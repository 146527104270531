import React from 'react';
import { useMenuState } from '@hitagi/core/Menu';

const createFilter = (Disclosure, Filter) => function (props) {
  const menu = useMenuState();
  return (
    <>
      <Disclosure {...menu} {...props} />
      {menu.anchorEl && <Filter {...menu} {...props} />}
    </>
  );
};

export default createFilter;
