import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@hitagi/core/FormControl';
import InputLabel from '@hitagi/core/InputLabel';
import InputCaption from '@hitagi/core/InputCaption';
import Input from '@hitagi/core/Input';
import Button from '@hitagi/core/Button';
import ChevronRightIcon from '@hitagi/icons/ChevronRight';
import QuickSearch from '@hitagi/quick-search';
import styled from 'styled-components';
import Spinner from '@hitagi/core/Spinner';
import Alert from '@hitagi/core/Alert';
import ButtonBase from '@hitagi/core/ButtonBase';
import CrossmarkIcon from '@hitagi/icons/Crossmark';
import teamSection from '../../QuickSearchResultsTeam/teamSection';
import TeamImage from '../../TeamImage';

const QUIKC_SEARCH_SECTIONS = [teamSection];

const Form = styled.form`
  width: 320px;

  /* FormControl */
  > div {
    margin-bottom: ${props => props.theme.spacing(2)};

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  /* Button */
  > button {
    margin-top: ${props => props.theme.spacing(4)};
  }
`;

const RequiredMark = styled.span.attrs({ children: '*' })`
  color: ${props => props.theme.palette.red.main};
`;

const TeamLogoAdornment = styled(TeamImage)`
  width: 18px;
  height: 18px;
  object-fit: cover;
`;

const initialState = {
  name: '',
  email: '',
};

const fn = () => {};

const NewTeamForm = ({ onSubmit: onSubmitProp, loading, errors }) => {
  const [values, setValues] = useState(initialState);
  const [team, setTeam] = useState();

  const onChange = useCallback(event => {
    const { name, value } = event.target;
    setValues(prev => {
      if (!name) {
        return prev;
      }
      return { ...prev, [name]: value };
    });
  }, []);

  const onSubmit = event => {
    event.preventDefault();
    if (onSubmitProp) {
      onSubmitProp({
        id: team.id,
        name: values.name,
        email: values.email,
      });
    }
  };

  const onTeamClear = useCallback(() => {
    setTeam(prevTeam => {
      setValues(prevValues => {
        if (prevTeam.name === prevValues.name) {
          return { ...prevValues, name: '' };
        }
        return prevValues;
      });
      return undefined;
    });
  }, []);

  const getTeamResult = useCallback((type, result) => {
    const onClick = () => {
      setTeam(result);
      setValues(prev => {
        if (!prev.name) {
          return { ...prev, name: result.name };
        }
        return prev;
      });
    };
    return { onClick };
  }, []);

  return (
    <Form onChange={onChange} onSubmit={onSubmit}>
      <FormControl fullWidth disabled={loading} error={Boolean(errors.id)}>
        {team ? (
          <Input
            startAdornment={<TeamLogoAdornment id={team.id} />}
            defaultValue={team.name}
            readOnly
            endAdornment={<ButtonBase type="button" onClick={onTeamClear}><CrossmarkIcon height="1em" /></ButtonBase>}
          />
        ) : (
          <QuickSearch
            as="div"
            width="100%"
            getResultProps={getTeamResult}
            placeholder="Search teams"
            sections={QUIKC_SEARCH_SECTIONS}
          />
        )}
        <InputLabel>
          Dota 2 Team <RequiredMark />
        </InputLabel>
        <InputCaption>
          {errors.id || "If you struggle to find your team by name, try entering the team's ID instead"}
        </InputCaption>
      </FormControl>

      <FormControl id="name" fullWidth disabled={loading} error={Boolean(errors.name)}>
        <Input
          placeholder="August Scrim Matches"
          required
          value={values.name}
          onChange={fn}
        />
        <InputLabel>
          Dataset Name <RequiredMark />
        </InputLabel>
        <InputCaption>
          {errors.name || (
            <>
              This is the name that will represent this dataset in Yogurt.
              <br />
              It’s possible to create multiple datasets in Yogurt
              that connect to the same DOTA 2 team.
              This is so you can import different matches
              for different instances of the same team.
            </>
          )}
        </InputCaption>
      </FormControl>

      <FormControl id="email" fullWidth disabled={loading}>
        <Input
          placeholder="yogurt@team.email"
          required
          type="email"
          value={values.email}
          onChange={fn}
        />
        <InputLabel>
          Email Address <RequiredMark />
        </InputLabel>
        <InputCaption>
          {errors.email || (
            <>
              This email will permanently be associated with this dataset.
              <br />
              It is the only email that can ever be used to communicate with STRATZ
              regarding the management of this dataset.
              <br />
              If no member of your organization retains access to this email account,
              then an existing member of the organization must
              contact
              {' '}
              <a href="mailto:ken@stratz.com">
                ken@stratz.com
              </a>
              {' '}
              for assistance.
            </>
          )}
        </InputCaption>
      </FormControl>

      {errors.other && (
        <Alert
          width="100%"
          severity="error"
          title="Error"
          description={errors.other}
          marginTop={1}
        />
      )}

      {loading ? (
        <Button fullWidth disabled={loading} isIconOnly>
          <Spinner />
        </Button>
      ) : (
        <Button fullWidth disabled={loading}>
          Create&nbsp;<ChevronRightIcon height="1em" />
        </Button>
      )}
    </Form>
  );
};

NewTeamForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    other: PropTypes.string,
  }),
};

export default NewTeamForm;
