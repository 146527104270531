import { DataRow, DataRowProvider, getComparator, HeadCol, useSorting } from '@hitagi/core/DataRow';
import Text from '@hitagi/core/Text';
import React, { useMemo, useState } from 'react';
import Box from '@hitagi/core/Box';
import { HeroImage } from '@hitagi/dota/Hero';
import Button from '@hitagi/core/Button';
import Glass from '../../Glass';
import { ComputedDuo, ComputedHero, MAX_ROWS } from './common';
import IndexCol, { IndexColSizes } from '../../DataRowCols/IndexCol';
import WinLossCol, { WinLossHeadCol } from '../../DataRowCols/WinLossCol';
import WinRateMeterCol, { WinRateMeterColSizes } from '../../DataRowCols/WinRateMeterCol';
import RatingMeterCol, { RatingMeterColSizes } from '../../DataRowCols/RatingMeterCol';

type TopDuo = ComputedDuo & {
  heroId2: number
}

const renderDataRowHead = (against: boolean) => (
  <Glass disableBrBottom>
    <DataRow disableUnderline px={1}>
      <Box width={IndexColSizes.width} />
      <HeadCol flexGrow={1}>
        {against ? 'Played against' : 'Played with'}
      </HeadCol>
      <WinLossHeadCol />
      <HeadCol<ComputedDuo>
        width={WinRateMeterColSizes.width}
        sortField={against ? 'winRateAgainst' : 'winRateWith'}
      >
        Win rate
      </HeadCol>
      <HeadCol<ComputedDuo>
        width={RatingMeterColSizes.width}
        sortField={against ? 'wilsonScoreAgainst' : 'wilsonScoreWith'}
      >
        Rating
      </HeadCol>
    </DataRow>
  </Glass>
);

type HeroDuosTopProps = {
  computedHeroes: ComputedHero[]
}

const HeroDuosTop = React.memo((props: HeroDuosTopProps) => {
  const { computedHeroes } = props;

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  const computed = useMemo(() => {
    const topDuosWith: Record<string, TopDuo> = {};
    const topDuosAgainst: Record<string, TopDuo> = {};

    let duoMinWilsonScoreWith = Infinity;
    let duoMaxWilsonScoreWith = -Infinity;
    let duoMinWilsonScoreAgainst = Infinity;
    let duoMaxWilsonScoreAgainst = -Infinity;

    computedHeroes.forEach(computedHero => {
      computedHero.duos.forEach(computedDuo => {
        const topDuo = { ...computedDuo, heroId2: computedHero.heroId };
        const key = [computedHero.heroId, computedDuo.heroId]
          .sort((a, b) => a - b)
          .join('-');

        if (topDuo.matchCountWith > 0 && !topDuosWith[key]) {
          topDuosWith[key] = topDuo;

          duoMinWilsonScoreWith = Math.min(duoMinWilsonScoreWith, topDuo.wilsonScoreWith);
          duoMaxWilsonScoreWith = Math.max(duoMaxWilsonScoreWith, topDuo.wilsonScoreWith);
        }

        if (topDuo.matchCountAgainst > 0 && !topDuosAgainst[key]) {
          topDuosAgainst[key] = topDuo;

          duoMinWilsonScoreAgainst = Math.min(duoMinWilsonScoreAgainst, topDuo.wilsonScoreAgainst);
          duoMaxWilsonScoreAgainst = Math.max(duoMaxWilsonScoreAgainst, topDuo.wilsonScoreAgainst);
        }
      });
    });

    return {
      topDuosWith: Object.values(topDuosWith),
      topDuosAgainst: Object.values(topDuosAgainst),
      duoMinWilsonScoreWith,
      duoMaxWilsonScoreWith,
      duoMinWilsonScoreAgainst,
      duoMaxWilsonScoreAgainst,
    };
  }, [computedHeroes]);

  const {
    topDuosWith,
    topDuosAgainst,
    duoMinWilsonScoreWith,
    duoMaxWilsonScoreWith,
    duoMinWilsonScoreAgainst,
    duoMaxWilsonScoreAgainst,
  } = computed;

  const sortingStateWith = useSorting<TopDuo>('wilsonScoreWith');
  const sortedWith = topDuosWith
    .sort(getComparator(sortingStateWith.sortField, sortingStateWith.sortDirection));
  let rowsWith = sortedWith.map((duo, i) => (
    <DataRow key={`${duo.heroId}-${duo.heroId2}`} px={1}>
      <IndexCol index={i} />
      <Box flexGrow={1} />
      <HeroImage id={duo.heroId} variant="icon" width={30} height={30} />
      <HeroImage id={duo.heroId2} variant="icon" width={30} height={30} />
      <Box flexGrow={1} />
      <WinLossCol w={duo.winCountWith} l={duo.matchCountWith - duo.winCountWith} />
      <WinRateMeterCol winRate={duo.winRateWith} />
      <RatingMeterCol
        value={duo.wilsonScoreWith}
        minValue={duoMinWilsonScoreWith}
        maxValue={duoMaxWilsonScoreWith}
      />
    </DataRow>
  ));
  if (!expanded && sortedWith.length > MAX_ROWS) {
    rowsWith = rowsWith.slice(0, MAX_ROWS);
  }

  const sortingStateAgainst = useSorting<TopDuo>('wilsonScoreAgainst');
  const sortedAgainst = topDuosAgainst
    .sort(getComparator(sortingStateAgainst.sortField, sortingStateAgainst.sortDirection));
  let rowsAgainst = sortedAgainst.map((duo, i) => (
    <DataRow key={`${duo.heroId}-${duo.heroId2}`} px={1}>
      <IndexCol index={i} />
      <Box flexGrow={1} />
      <HeroImage id={duo.heroId} variant="icon" width={30} height={30} />
      <HeroImage id={duo.heroId2} variant="icon" width={30} height={30} />
      <Box flexGrow={1} />
      <WinLossCol w={duo.winCountAgainst} l={duo.matchCountAgainst - duo.winCountAgainst} />
      <WinRateMeterCol winRate={duo.winRateAgainst} />
      <RatingMeterCol
        value={duo.wilsonScoreAgainst}
        minValue={duoMinWilsonScoreAgainst}
        maxValue={duoMaxWilsonScoreAgainst}
      />
    </DataRow>
  ));
  if (!expanded && sortedAgainst.length > MAX_ROWS) {
    rowsAgainst = rowsAgainst.slice(0, MAX_ROWS);
  }

  return (
    <Glass p={2} col>
      <Text variant="title">
        Top Duos
      </Text>
      <Box row alignItems="start" mt={2}>
        <Glass flexGrow={1} flexBasis={0} col>
          <DataRowProvider disableMobileMatch sortingState={sortingStateWith}>
            {renderDataRowHead(false)}
            {rowsWith}
            {!rowsWith.length && (
              <DataRow>
                <Text color="text.secondary" textAlign="center" flexGrow={1}>
                  no matches
                </Text>
              </DataRow>
            )}
          </DataRowProvider>
        </Glass>
        <Box mx={1} />
        <Glass flexGrow={1} flexBasis={0} col>
          <DataRowProvider disableMobileMatch sortingState={sortingStateAgainst}>
            {renderDataRowHead(true)}
            {rowsAgainst}
            {!rowsAgainst.length && (
              <DataRow>
                <Text color="text.secondary" textAlign="center" flexGrow={1}>
                  no matches
                </Text>
              </DataRow>
            )}
          </DataRowProvider>
        </Glass>
      </Box>
      {(sortedWith.length > MAX_ROWS || sortedAgainst.length > MAX_ROWS) && (
        <Button onClick={handleExpandClick} disableOutline disableBg size="small" mt={2} mx="auto">
          {expanded ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Glass>
  );
});

export default HeroDuosTop;
