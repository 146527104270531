/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import SelectMenu from '@hitagi/core/SelectMenu';
import LabelButton from '@hitagi/core/LabelButton/LabelButton';
import Text from '@hitagi/core/Text';
import Divider from '@hitagi/core/Divider';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import Box from '@hitagi/core/Box';
import { Position } from '@hitagi/dota/Position';
import { arrayToString, numberArrayFromString } from '@hitagi/utils';
import Filter, { FilterDisclosure, createFilter } from '../../../Filter/index';
import CheckboxPageModifier from '../../../PageModifiers/Checkbox';
import PositionPageModifier from '../../../PageModifiers/Position';

const PICK_ORDER_IDS = Array(5).fill().map((_, i) => i + 1);
const PICK_ORDER_GQL_ENUM_MAPPING = {
  1: 'FIRST_PICK',
  2: 'SECOND_PICK',
  3: 'THIRD_PICK',
  4: 'FOURTH_PICK',
  5: 'FIFTH_PICK',
};
const pickOrderToGqlEnum = (num) => PICK_ORDER_GQL_ENUM_MAPPING[num];
const pickOrderFromGqlEnum = (em) => PICK_ORDER_IDS
  .find(key => PICK_ORDER_GQL_ENUM_MAPPING[key] === em);

// NOTE: this is 100% applicable to CM and AP, don't care about other game modes.
// pick orders should be ordered from lower to higher (important for pickPhaseKey finding)
const PICK_ORDERS_BY_PICK_PHASE = {
  1: [1, 2],
  2: [3, 4],
  3: [5],
};

const PickOrderPageModifier = (props) => {
  const { pickOrders, setPickOrders } = props;

  const handleSelection = (ev) => {
    const { value } = ev.currentTarget.dataset;
    const normalizedValue = value === undefined ? undefined : numberArrayFromString(value);
    setPickOrders(normalizedValue);
  };

  const pickOrder = pickOrders?.length === 1 ? pickOrders[0] : undefined;

  const pickPhaseKeys = Object.keys(PICK_ORDERS_BY_PICK_PHASE);
  let pickPhaseKey;
  if ((pickOrders?.length ?? 0) > 1) {
    const sortedPickOrders = pickOrders.slice().sort((a, b) => a - b);
    pickPhaseKey = pickPhaseKeys.find(key => (
      PICK_ORDERS_BY_PICK_PHASE[key].every((po, i) => po === sortedPickOrders[i])
    ));
  }

  return (
    <SelectMenu>
      <summary>
        <LabelButton>
          {pickOrder !== undefined && (
            <>
              <LabelButton.Graphic size={LabelButton.Graphic.size.max}>
                <Text variant="callout" mx={1}>
                  {pickOrder}
                </Text>
              </LabelButton.Graphic>
              <LabelButton.Text>
                Pick Order
              </LabelButton.Text>
            </>
          )}

          {pickPhaseKey !== undefined && (
            <>
              <LabelButton.Graphic size={LabelButton.Graphic.size.max}>
                <Text variant="callout" mx={1}>
                  {pickPhaseKey}
                </Text>
              </LabelButton.Graphic>
              <LabelButton.Text>
                Pick Phase
              </LabelButton.Text>
            </>
          )}

          {pickOrder === undefined && pickPhaseKey === undefined && (
            <LabelButton.Text>
              Pick Order/Phase
            </LabelButton.Text>
          )}
          <Divider vertical margin={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.List>
          <SelectMenu.Item selectable={false}>
            <Text variant="caption">
              Pick order
            </Text>
          </SelectMenu.Item>
          <Divider m={0} />
          {PICK_ORDER_IDS.map((id) => (
            <SelectMenu.Item
              key={id}
              selected={id === pickOrder}
              data-value={id}
              onClick={handleSelection}
            >
              {id}
            </SelectMenu.Item>
          ))}

          <SelectMenu.Item selectable={false}>
            <Text variant="caption">
              Pick phase
            </Text>
          </SelectMenu.Item>
          <Divider m={0} />
          {Object.keys(PICK_ORDERS_BY_PICK_PHASE).map(key => (
            <SelectMenu.Item
              key={key}
              selected={key === pickPhaseKey}
              data-value={arrayToString(PICK_ORDERS_BY_PICK_PHASE[key])}
              onClick={handleSelection}
            >
              {key}
            </SelectMenu.Item>
          ))}
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

const PositionPickOrderDisclosure = ({
  filterPositionIsUs,
  filterPosition,
  filterPositionOrder,
  ...restProps
}) => (
  <FilterDisclosure
    active={filterPositionIsUs !== undefined
      || filterPosition !== undefined
      || filterPositionOrder !== undefined}
    {...restProps}
  >
    Position Pick Order
  </FilterDisclosure>
);

const PositionPickOrderInput = (props) => {
  const {
    filterPositionIsUs,
    setFilterPositionIsUs,
    filterPosition,
    setFilterPosition,
    filterPositionOrder,
    setFilterPositionOrder,
    ...restProps
  } = props;

  const [positionId, setPositionId] = useState(Position.fromGqlEnum(filterPosition));
  const [pickOrders, setPickOrders] = useState(filterPositionOrder?.map(pickOrderFromGqlEnum));
  const [opponent, setOpponent] = useState(
    filterPositionIsUs === undefined ? undefined : !filterPositionIsUs,
  );

  const handleClear = () => {
    setPositionId(undefined);
    setPickOrders(undefined);
    setOpponent(undefined);
  };

  const canClear = positionId !== undefined || pickOrders !== undefined;

  const handleSave = () => {
    let nextFilterPositionIsUs;
    if (positionId !== undefined && pickOrders !== undefined) {
      nextFilterPositionIsUs = !opponent;
    }
    setFilterPositionIsUs(nextFilterPositionIsUs);
    setFilterPosition(Position.toGqlEnum(positionId));
    setFilterPositionOrder(pickOrders?.map(pickOrderToGqlEnum));
  };

  return (
    <Filter
      onClear={handleClear}
      canClear={canClear}
      onSave={handleSave}
      {...restProps}
    >
      <Box row>
        <PositionPageModifier
          positionId={positionId}
          setPositionId={setPositionId}
          unlistUnion
        />
        <Box mx={1} />
        <PickOrderPageModifier
          pickOrders={pickOrders}
          setPickOrders={setPickOrders}
        />
        <Box mx={1} />
        <CheckboxPageModifier
          value={opponent}
          toggle={() => setOpponent(!opponent)}
          message="Opponent"
        />
      </Box>
    </Filter>
  );
};

const PositionPickOrderFilter = createFilter(
  PositionPickOrderDisclosure, PositionPickOrderInput,
);

export default PositionPickOrderFilter;
