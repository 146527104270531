import React, { useState } from 'react';
import { GameMode, GameModeName, GAME_MODE_NAME_MAPPING } from '@hitagi/dota/GameMode';
import { useIntl } from 'react-intl';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import Divider from '@hitagi/core/Divider';
import LabelButton from '@hitagi/core/LabelButton';
import Phantom from '@hitagi/core/Phantom';
import SelectMenu from '@hitagi/core/SelectMenu';
import messages from './messages';

const ALL_VALUES = GameMode.ids.slice(1); // cut off NONE

export type GameModePageModifierProps = {
  gameMode: GameMode.Id | undefined
  setGameMode: (gameMode: GameMode.Id | undefined) => void
  unlistUnion?: boolean
  include?: GameMode.Id[]
}

const GameModePageModifier = (props: GameModePageModifierProps) => {
  const {
    gameMode,
    setGameMode,
    unlistUnion,
    include,
    ...restProps
  } = props;

  const intl = useIntl();

  const [search, setSearch] = useState('');
  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };
  const handleSelection = (event: React.MouseEvent<HTMLLIElement>) => {
    const { value } = event.currentTarget.dataset;
    const normalizedValue = value === undefined ? undefined : (+value as GameMode.Id);
    setGameMode(normalizedValue);
    setSearch('');
  };

  const normalizedSearch = search.toLowerCase();
  const renderItem = (value: GameMode.Id) => {
    if (include !== undefined && !include.includes(value)) return null;
    const string = intl.formatMessage(GAME_MODE_NAME_MAPPING[value]).toLowerCase();
    if (!string.includes(normalizedSearch)) return null;
    return (
      <SelectMenu.Item
        key={value}
        selected={value === gameMode}
        data-value={value}
        onClick={handleSelection}
      >
        <GameModeName id={value} />
      </SelectMenu.Item>
    );
  };

  const unionSelected = gameMode === undefined;

  return (
    <SelectMenu {...restProps}>
      <summary>
        <LabelButton>
          <LabelButton.Text>
            {unionSelected
              ? intl.formatMessage(messages.allGameModes)
              : <GameModeName id={gameMode!} />}
          </LabelButton.Text>
          <Divider vertical margin={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.Filter
          placeholder={intl.formatMessage(messages.filterGameModes)}
          value={search}
          onChange={handleSearch}
        />
        <SelectMenu.List>
          {!unlistUnion && !search && (
            <SelectMenu.Item
              selected={unionSelected}
              onClick={handleSelection}
            >
              {intl.formatMessage(messages.allGameModes)}
            </SelectMenu.Item>
          )}
          <Phantom>
            {() => ALL_VALUES.map(renderItem)}
          </Phantom>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default GameModePageModifier;
