import React from 'react';
import { BoxProps } from '@hitagi/core/Box';
import Alert from '@hitagi/core/Alert';
import Button from '@hitagi/core/Button';
import Container from '@hitagi/core/Layout/Container';

const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK;

type PageErrorProps = {
  // we should be able to reset the padding when this components is used inside the Container.
  disablePadding?: boolean
  error?: Error
  message?: React.ReactNode
} & BoxProps

const PageError = (props: PageErrorProps) => {
  const { error, message = 'Unspecified Error.', disablePadding, ...restProps } = props;
  if (disablePadding) restProps.padding = '0 !important';

  const action = DISCORD_LINK && (
    <Button as="a" href={DISCORD_LINK} target="_blank" rel="noopener noreferrer">
      Let Us Know
    </Button>
  );

  return (
    <Container marginY={3} {...restProps}>
      {/* @ts-expect-error TODO: it wants `nonce` prop; nani? */}
      <Alert
        severity="error"
        title="Something went wrong!"
        description={error?.message ?? message}
        action={action}
      />
    </Container>
  );
};

export default PageError;
