// import { gql } from '@apollo/client';
import { gql } from 'apollo-boost';
import { DOTA_CONSTANTS_FRAGMENTS } from '@hitagi/dota/Context';

// NOTE: VERY IMPORTANT!
// whenever u make a change to QUERY (here, or on hitagi) -> increase QUERY_VERSION.
// also version can be changed for a different reason - for example when
// there's a need to refresh user's constants because of Ken's or Valve's bugs.
export const DOTA_CONSTANTS_QUERY_VERSION = 6;
const QUERY = gql`
  query Constants($languageEnum: Language) {
    constants {
      abilities(language: $languageEnum) { ...Ability }
      heroes(language: $languageEnum) { ...Hero }
      items(language: $languageEnum) { ...Item }
      gameVersions { ...GameVersion }
    }
  }
  ${DOTA_CONSTANTS_FRAGMENTS}
`;

export const getLocalDotaConstants = () => {
  try {
    return JSON.parse(localStorage.getItem('constants'));
  } catch (_) {
    return null;
  }
};

export const setLocalDotaConstants = constants => {
  try {
    localStorage.setItem('constants', JSON.stringify(constants));
  } catch (_) {
    /* shrug */
  }
};

export const fetchNewGameVersion = async (apolloClient, lastKnownGameVersionId) => {
  const queryResult = await apolloClient.query({
    query: gql`
      query NewGameVersion($id: Short!) {
        constants {
          gameVersion(id: $id) {
            id
          }
        }
      }
    `,
    variables: {
      id: lastKnownGameVersionId + 1,
    },
    fetchPolicy: 'no-cache',
  });
  return queryResult.data?.constants?.gameVersion;
};

const omitTypenames = input => {
  if (Array.isArray(input)) {
    return input.map(omitTypenames);
  }

  if (typeof input === 'object') {
    return Object.keys(input).reduce((acc, key) => {
      if (key === '__typename') {
        return acc;
      }

      const value = input[key];
      if (value === null) {
        return acc;
      }

      return { ...acc, [key]: omitTypenames(value) };
    }, {});
  }

  return input;
};

export const fetchDotaConstants = async (apolloClient, languageEnum) => {
  const queryResult = await apolloClient.query({
    query: QUERY,
    variables: { languageEnum },
    fetchPolicy: 'no-cache',
  });

  return omitTypenames({
    ...queryResult.data.constants,
    queryVersion: DOTA_CONSTANTS_QUERY_VERSION,
    languageEnum,
  });
};
