import React from 'react';
import Container from '@hitagi/core/Layout/Container';
import Text from '@hitagi/core/Text';
import { Navigate } from 'react-router-dom';
import { useUser } from '../utils/user';
import PageError from './PageError';
import PageSpinner from './PageSpinner';

const Home = () => {
  const user = useUser();

  if (user.loading) {
    return <PageSpinner />;
  }

  if (user.error) {
    return <PageError error={user.error} />;
  }

  if (user.isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  if (!user.isLoggedIn) {
    return <Navigate to="/get-started" replace />;
  }

  return (
    <Container center>
      <Text fontSize="3em" fontWeight={200}>
        ¯\_(ツ)_/¯
      </Text>
    </Container>
  );
};

export default Home;
