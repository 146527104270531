import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { MatchDraftRowFragments } from '../MatchRow/Draft';

export const GET_TEAM_MATCHES = gql`
  query TeamMatches($teamId: Long!, $input: FilterMatchReplayUploadRequestType!) {
    yogurt {
      team(matchReplayUploadTeamId: $teamId) {
        id
        team {
          id
        }
      }

      basicOverview: overview(matchReplayUploadTeamId: $teamId) {
        matchCount
      }

      overview(matchReplayUploadTeamId: $teamId, request: $input) {
        matchCount
        winCount
        matches {
          ...MatchDraftRow
          seriesId
          gameVersionId
          league {
            displayName
            id
          }
          isValidated
          invalidated @client
          deleted @client
        }
      }

      heroSummary(matchReplayUploadTeamId: $teamId, request: $input) {
        heroId
        banCountAgainst
        banCountWith
        matchCountAgainst
        matchCountWith
        winCountAgainst
        winCountWith
        withScore @client
        againstScore @client
      }
    }
  }

  ${MatchDraftRowFragments.matchReplayUploadTypeFragment}
`;
export const useTeamMatchesQuery = (teamId, input) => useQuery(GET_TEAM_MATCHES, {
  variables: {
    teamId,
    input: {
      take: 32,
      skip: 0,
      ...input,
    },
  },
  fetchPolicy: 'cache-and-network',
});

export const GET_TEAM_MEMBERS = gql`
  query TeamMembers($teamId: Long!) {
    yogurt {
      teamMembers(matchReplayUploadTeamId: $teamId) {
        isAdmin
        captainJackIdentityId
        isDefaultTeam
        matchUploadTeamId
        steamAccount {
          id
          name
          avatar
        }
      }
    }
  }
`;
export const useTeamMembersQuery = teamId => useQuery(GET_TEAM_MEMBERS, { variables: { teamId } });
