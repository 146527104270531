import React from 'react';
import { Navigate } from 'react-router-dom';
import FourOhFour from '../components/FourOhFour';
import Home from '../components/Home';
import GetStarted from '../components/GetStarted';
import Dashboard from '../components/Dashboard';
import ProtectedRoute from '../components/ProtectedRoute';
import TeamsNew from '../components/Teams/New';
import Team from '../components/Team';
import TeamDrafts from '../components/Team/Matches/Drafts';
import TeamHeroes from '../components/Team/Heroes';
import TeamUpload from '../components/Team/Matches/Upload';
import TeamImport from '../components/Team/Matches/Import';
import TeamReview from '../components/Team/Matches/Review';
import TeamMembers from '../components/Team/Members';
import TeamHeroesDuos from '../components/Team/HeroDuos';

export default [
  {
    path: '',
    element: <Home />,
  },
  {
    path: 'get-started',
    element: <GetStarted />,
  },
  {
    path: 'dashboard',
    element: <ProtectedRoute><Dashboard /></ProtectedRoute>,
  },
  {
    path: 'teams',
    name: 'Teams',
    children: [
      {
        path: 'new',
        element: <ProtectedRoute><TeamsNew /></ProtectedRoute>,
      },
    ],
  },
  {
    path: 'teams/:teamId',
    nameVar: 'name',
    fallbackName: 'Team',
    element: <ProtectedRoute><Team /></ProtectedRoute>,
    children: [
      {
        path: '',
        element: <Navigate to="drafts" replace />,
      },
      {
        path: 'drafts',
        name: 'Drafts',
        element: <TeamDrafts />,
      },
      {
        path: 'heroes',
        name: 'Heroes',
        children: [
          {
            path: '',
            name: 'Summary',
            element: <TeamHeroes />,
          },
          {
            path: 'duos',
            name: 'Synergies',
            element: <TeamHeroesDuos />,
          },
        ],
      },
      {
        path: 'upload',
        name: 'Upload',
        element: <TeamUpload />,
      },
      {
        path: 'import',
        name: 'Import',
        element: <TeamImport />,
      },
      {
        path: 'review',
        name: 'Review',
        element: <TeamReview />,
      },
      {
        path: 'members',
        name: 'Members',
        element: <TeamMembers />,
      },
    ],
  },
  {
    path: '*',
    element: <FourOhFour />,
  },
];
