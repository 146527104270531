import React from 'react';

// NOTE: hitagi uses useId from react 18, I don't want to upgrade to react 18
// now.
let id = 0;
React.useId = () => {
  const ref = React.useRef();
  if (!ref.current) {
    id += 1;
    ref.current = id;
  }
  return ref.current;
};

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);

  // Mark all the components you want to be notified
  // about their re-renders before export line like so:
  // Component.whyDidYouRender = true;
  // https://github.com/welldone-software/why-did-you-render#usage
}
