import React from 'react';
import PropTypes from 'prop-types';
import Text from '@hitagi/core/Text';
import Material from '@hitagi/core/Material';
import Box from '@hitagi/core/Box';
import DetailList from '@hitagi/core/DetailList';

const SummaryCard = ({ children, title, subtitle, ...restProps }) => (
  <Box col {...restProps}>
    <Material col padding={1} elevation={0}>
      <Box row alignItems="baseline" marginBottom="0.3em">
        <Text transform="uppercase">
          {title}
        </Text>
        {subtitle && (
          <>
            &nbsp;
            <Text transform="uppercase" variant="caption">
              {subtitle}
            </Text>
          </>
        )}
      </Box>
      <DetailList>
        {children}
      </DetailList>
    </Material>
  </Box>
);

SummaryCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
};

export default SummaryCard;
