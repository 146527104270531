import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedNumber } from 'react-intl';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';

const Stat = styled.div`
  margin-right: ${props => props.theme.spacing(1)};
  text-align: end;
  width: 8ch;
`;

const TableStat = ({ stat, percent }) => (
  <Box flexShrink={0} row>
    <Stat>
      {stat}
    </Stat>
    <Box as="span" width="5ch">
      <Text color="disabled" as="span">
        <FormattedNumber
          value={percent}
          style="percent"
        />
      </Text>
    </Box>
  </Box>
);

TableStat.propTypes = {
  stat: PropTypes.node,
  percent: PropTypes.number,
};

export default TableStat;
