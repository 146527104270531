import React, { useRef, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import Container from '@hitagi/core/Layout/Container';
import Phantom from '@hitagi/core/Phantom';
import useQueryStringReducer from '@hitagi/core/useQueryStringReducer';
import { wilsonScore } from '@hitagi/utils';
import Filters from '../Matches/Filters';
import PageNoData from '../../PageNoData';
import PageError from '../../PageError';
import PageSpinner from '../../PageSpinner';
import HeroDuosHero from './HeroDuosHero';
import { reducer, initialState, stringifiers, parsers } from '../Matches/flux';
import { STEAM_ACCOUNT_PAGE_MODIFIER_FRAMGNET } from '../../PageModifiers/SteamAccount';
import { ComputedDuo, ComputedHero, HERO_DUOS_HERO_FRAGMENT, MatchReplayUploadHeroSummaryTypeFragment } from './common';
import HeroDuosTop from './HeroDuosTop';

const GET_DUOS = gql`
  query GetDuos($yogurtTeamId: Long!, $request: FilterMatchReplayUploadRequestType, $skipMatches: Boolean!) {
    yogurt {
      heroSummary(matchReplayUploadTeamId: $yogurtTeamId, request: $request) {
        heroId
        matchCountWith
        matchCountAgainst
        ...MatchReplayUploadHeroSummaryTypeFragment
      }
      overview(matchReplayUploadTeamId: $yogurtTeamId, request: { take: 256 }) {
        matches @skip(if: $skipMatches) {
          radiantTeam { ...TeamTypeFragment }
          direTeam { ...TeamTypeFragment }
          league {
            id
            displayName
          }
          players {
            heroId
            steamAccount { ...SteamAccountPageModifierSteamAccountTypeFragment }
          }
        }
      }
    }
  }
  fragment TeamTypeFragment on TeamType {
    id
    name
  }
  ${HERO_DUOS_HERO_FRAGMENT}
  ${STEAM_ACCOUNT_PAGE_MODIFIER_FRAMGNET}
`;

type GetDuos = {
  yogurt: {
    heroSummary: Array<{
      matchCountWith: number
      matchCountAgainst: number
    } & MatchReplayUploadHeroSummaryTypeFragment>
    overview: {
      matches: any[]
    }
  }
}

type GetDuosVariables = {
  yogurtTeamId: number
  request: {
    isValidated: true
  } & Record<string, unknown>
  skipMatches: boolean
}

const renderFeedback = (queryResult: any) => {
  const { loading, error, data } = queryResult;

  if (loading) return <PageSpinner />;
  if (error) return <PageError error={error} />;

  const heroSummary = (data as any)?.yogurt.heroSummary;
  if (!heroSummary?.length) return <PageNoData />;

  return null;
};

const HeroDuos = () => {
  const params = useParams();
  const yogurtTeamId = +params.teamId;

  const [state, dispatch] = useQueryStringReducer(reducer, initialState, stringifiers, parsers);

  const matchesRef = useRef<any[] | undefined>(undefined);

  const queryResult = useQuery<GetDuos, GetDuosVariables>(
    GET_DUOS,
    {
      variables: {
        yogurtTeamId,
        request: {
          isValidated: true,
          ...state,
        },
        skipMatches: Boolean(matchesRef.current),
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (!matchesRef.current) {
      matchesRef.current = queryResult.data?.yogurt.overview.matches;
    }
  }, [queryResult.data]);

  const matches = matchesRef.current ?? queryResult.data?.yogurt.overview.matches;
  const heroSummary = queryResult.data?.yogurt.heroSummary;

  const computedHeroes = useMemo(() => {
    if (!heroSummary) return null;

    const heroes = heroSummary.map<ComputedHero>(hero => {
      const duos: ComputedDuo[] = [];

      let duoMinWilsonScoreWith = Infinity;
      let duoMaxWilsonScoreWith = -Infinity;
      let duoMinWilsonScoreAgainst = Infinity;
      let duoMaxWilsonScoreAgainst = -Infinity;

      hero.duos.forEach(duo => {
        if (!duo.matchCountWith && !duo.matchCountAgainst) return;

        const wilsonScoreWith = wilsonScore(
          duo.winCountWith, duo.matchCountWith, undefined,
        );
        const wilsonScoreAgainst = wilsonScore(
          duo.winCountAgainst, duo.matchCountAgainst, undefined,
        );

        duos.push({
          ...duo,
          winRateWith: duo.matchCountWith ? duo.winCountWith / duo.matchCountWith : 0,
          wilsonScoreWith,
          winRateAgainst: duo.matchCountAgainst ? duo.winCountAgainst / duo.matchCountAgainst : 0,
          wilsonScoreAgainst,
        });

        duoMinWilsonScoreWith = Math.min(duoMinWilsonScoreWith, wilsonScoreWith);
        duoMaxWilsonScoreWith = Math.max(duoMaxWilsonScoreWith, wilsonScoreWith);
        duoMinWilsonScoreAgainst = Math.min(duoMinWilsonScoreAgainst, wilsonScoreAgainst);
        duoMaxWilsonScoreAgainst = Math.max(duoMaxWilsonScoreAgainst, wilsonScoreAgainst);
      });

      return {
        ...hero,
        duos,
        duoMinWilsonScoreWith,
        duoMaxWilsonScoreWith,
        duoMinWilsonScoreAgainst,
        duoMaxWilsonScoreAgainst,
      };
    });

    heroes.sort((a, b) => (b.matchCountWith + b.matchCountAgainst)
      - (a.matchCountWith + a.matchCountAgainst));

    return heroes;
  }, [heroSummary]);

  return (
    <>
      <Filters state={state} dispatch={dispatch} matches={matches} />
      {renderFeedback(queryResult) || (
        <Container>
          <HeroDuosTop computedHeroes={computedHeroes!} />
          {computedHeroes!.map(computedHero => {
            if (!computedHero.matchCountWith && !computedHero.matchCountAgainst) return null;
            return (
              <Phantom key={computedHero.heroId}>
                {() => <HeroDuosHero computedHero={computedHero} />}
              </Phantom>
            );
          })}
        </Container>
      )}
    </>
  );
};

export default HeroDuos;
