import React from 'react';
import PropTypes from 'prop-types';
import Box from '@hitagi/core/Box';
import { kenDate } from '@hitagi/utils';
import { getScore, getRelativeOutcome } from '@hitagi/dota/utils/match';
import { GameMode } from '@hitagi/dota/GameMode';
import MatchDetails from './MatchDetails';
import TeamDraft from './TeamDraft';
import { Cell, NonCmBansWrapper, OutcomeIndicator } from './styled';
import { matchType } from './propTypes';
import { detailsWidth } from './sizes';
import HeroBan from './HeroBan';

const MatchRow = ({ match, isPrimaryTeamRadiant, onPositionClick, ...restProps }) => {
  const score = getScore(match.players);
  if (!isPrimaryTeamRadiant) {
    score.reverse();
  }

  return (
    <Box row {...restProps}>
      <OutcomeIndicator isWin={getRelativeOutcome(match.didRadiantWin, isPrimaryTeamRadiant)} />
      <Cell col justifyContent="center" width={detailsWidth}>
        <MatchDetails
          startDate={kenDate(match.startDateTime)}
          duration={match.durationSeconds}
          matchId={match.id}
          score={score}
          gameMode={match.gameMode}
        />
      </Cell>
      <Box col flexGrow={1}>
        <Box row flexGrow={1}>
          <Cell width="50%" pb={match.gameMode !== GameMode.CAPTAINS_MODE ? 1 : undefined}>
            <TeamDraft
              match={match}
              isRadiant={isPrimaryTeamRadiant}
              onPositionClick={onPositionClick}
            />
          </Cell>
          <Cell width="50%" pb={match.gameMode !== GameMode.CAPTAINS_MODE ? 1 : undefined}>
            <TeamDraft
              match={match}
              isRadiant={!isPrimaryTeamRadiant}
              onPositionClick={onPositionClick}
            />
          </Cell>
        </Box>
        {match.gameMode !== GameMode.CAPTAINS_MODE && (
          <NonCmBansWrapper>
            {match.pickBans?.map(pickBan => !pickBan.isPick
              && pickBan.wasBannedSuccessfully
              && <HeroBan key={pickBan.bannedHeroId} heroId={pickBan.bannedHeroId} />)}
          </NonCmBansWrapper>
        )}
      </Box>
    </Box>
  );
};

MatchRow.propTypes = {
  match: matchType,
  isPrimaryTeamRadiant: PropTypes.bool,
  onPositionClick: PropTypes.func,
};

MatchRow.defaultProps = {
  isPrimaryTeamRadiant: true,
};

export default MatchRow;
