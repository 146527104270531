import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { usePopperState } from '@hitagi/core/Popper';
import ListItem from '@hitagi/core/ListItem';
import ListItemText from '@hitagi/core/ListItemText';
import ListItemElement from '@hitagi/core/ListItemElement';
import Tooltip from '@hitagi/core/Tooltip';
import Menu from '@hitagi/core/Menu';
import MenuItem from '@hitagi/core/MenuItem';
import Spinner from '@hitagi/core/Spinner';
import Button from '@hitagi/core/Button';
import EllipsisIcon from '@hitagi/icons/EllipsisV';
import CogIcon from '@hitagi/icons/Cog';
import { kenAvatar } from '@hitagi/utils';
import { GET_TEAM_MEMBERS } from '../queries';
import { GET_TEAMS } from '../../../utils/team';

const AdminIcon = styled(CogIcon)`
  padding-left: ${props => props.theme.spacing(1)};
  color: ${props => props.theme.palette.text.disabled};
`;

const REMOVE_MEMBER = gql`
  mutation removeTeamMember($memberId: ID!, $teamId: Long!) {
    yogurt {
      removeTeamMember(captainJackIdentityId: $memberId, matchReplayUploadTeamId: $teamId)
    }
  }
`;

const UPDATE_MEMBER = gql`
  mutation updateTeamMember($memberId: ID!, $teamId: Long!, $isAdmin: Boolean!) {
    yogurt {
      updateTeamMember(captainJackIdentityId: $memberId, matchReplayUploadTeamId: $teamId, isAdmin: $isAdmin)
    }
  }
`;

const Member = ({
  steamAccount,
  isAdmin,
  captainJackIdentityId,
  isUserAdmin,
  isUserMe,
  teamId,
}) => {
  const [updateTeamMember, update] = useMutation(UPDATE_MEMBER);
  const [removeTeamMember, remove] = useMutation(REMOVE_MEMBER);
  const menu = usePopperState();

  if (remove.data && remove.data.yogurt.removeTeamMember && isUserMe) {
    return <Navigate to="/dashboard" replace />;
  }

  const onRemove = () => {
    removeTeamMember({
      variables: { memberId: captainJackIdentityId, teamId },
      refetchQueries: [
        { query: isUserMe ? GET_TEAMS : GET_TEAM_MEMBERS, variables: { teamId } },
      ],
    });
    menu.onClose();
  };

  const onSetAdmin = () => {
    updateTeamMember({
      variables: { memberId: captainJackIdentityId, teamId, isAdmin: !isAdmin },
      refetchQueries: [
        { query: GET_TEAM_MEMBERS, variables: { teamId } },
      ],
    });
    menu.onClose();
  };

  const name = (
    <>
      {steamAccount.name}
      {isAdmin && (
        <Tooltip content="Administrator" placement="top">
          <AdminIcon height="1em" />
        </Tooltip>
      )}
    </>
  );

  return (
    <ListItem>
      <ListItemElement>
        <img src={kenAvatar(steamAccount.avatar)} alt="" />
      </ListItemElement>
      <ListItemText primary={name} />
      <ListItemElement>
        <Button isIconOnly disableOutline disableBg onClick={menu.onOpen}>
          {remove.loading || update.loading ? <Spinner /> : <EllipsisIcon />}
        </Button>
        <Menu
          onOpen={menu.onOpen}
          onClose={menu.onClose}
          anchorEl={menu.anchorEl}
        >
          <MenuItem value={1} onClick={onRemove}>
            {!isUserMe && isUserAdmin ? 'Remove from dataset' : 'Leave dataset'}
          </MenuItem>
          {isUserAdmin && !isUserMe && (
            <MenuItem value={1} onClick={onSetAdmin}>
              {isAdmin ? 'Remove Admin' : 'Set Admin'}
            </MenuItem>
          )}
        </Menu>
      </ListItemElement>
    </ListItem>
  );
};

Member.propTypes = {
  steamAccount: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  isAdmin: PropTypes.bool,
  captainJackIdentityId: PropTypes.string,
  isUserAdmin: PropTypes.bool,
  isUserMe: PropTypes.bool,
  teamId: PropTypes.number,
};

export default Member;
