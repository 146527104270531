import React, { useCallback } from 'react';
import Box from '@hitagi/core/Box';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { EMPTY_OBJECT } from '@hitagi/utils';
import { Navigate } from 'react-router-dom';
import { GET_TEAMS } from '../../../utils/team';
import Form from './NewTeamForm';
import ActionHeader from '../../ActionHeader';

const CREATE_TEAM = gql`
  mutation createTeam($name: String!, $email: String!, $id: Int!) {
    yogurt {
      createTeam(matchReplayUploadTeamName: $name, emailAddress: $email, teamId: $id) {
        id
        name
      }
    }
  }
`;

const CREATE_TEAM_OPTIONS = {
  refetchQueries: [
    { query: GET_TEAMS },
  ],
};

const parseError = error => {
  if (!error) {
    return EMPTY_OBJECT;
  }

  const errors = error.graphQLErrors.reduce((acc, err) => {
    const msg = err.message.toLowerCase();
    if (msg.includes('teamid')) {
      acc.id = err.message;
    } else if (msg.includes('teamname')) {
      acc.name = err.message;
    } else if (msg.includes('emailaddress')) {
      acc.email = err.message;
    }
    return acc;
  }, {});

  if (Object.keys(errors).length === 0) {
    errors.other = error.message;
  }

  return errors;
};

const NewTeam = () => {
  const [
    createTeam,
    { loading, error, called, data },
  ] = useMutation(CREATE_TEAM, CREATE_TEAM_OPTIONS);

  const onSubmit = useCallback(variables => createTeam({ variables }), [createTeam]);

  if (called && !loading && !error) {
    return <Navigate to={`/teams/${data.yogurt.createTeam.id}`} replace />;
  }

  const errors = parseError(error);

  return (
    <Box col margin={3} alignItems="center">
      <ActionHeader
        emoji="🚀"
        ariaLabel="Rocket"
        title="Let&apos;s create your new team"
      />
      <Box marginBottom={4} />
      <Form onSubmit={onSubmit} loading={loading} errors={errors} />
    </Box>
  );
};

export default NewTeam;
