import React from 'react';
import LabelButton from '@hitagi/core/LabelButton';
import Checkbox from '@hitagi/core/Checkbox';
import Divider from '@hitagi/core/Divider';

type CheckboxPageModifierProps = {
  value?: boolean
  toggle: () => void
  message: React.ReactNode
}

// TODO: may not be a bad thing to add this into hitagi as a 'preset' or something.

const CheckboxPageModifier = (props: CheckboxPageModifierProps) => {
  const { value, toggle, message, ...restProps } = props;
  return (
    <LabelButton onClick={toggle} {...restProps}>
      <LabelButton.Control>
        <Checkbox checked={Boolean(value)} />
      </LabelButton.Control>
      <Divider vertical margin={0} />
      <LabelButton.Text>
        {message}
      </LabelButton.Text>
    </LabelButton>
  );
};

export default CheckboxPageModifier;
