import React from 'react';
import { FormattedNumber } from 'react-intl';
import Box, { BoxProps } from '@hitagi/core/Box';
import Meter from '@hitagi/core/Meter';
import omit from '@hitagi/utils/omit';
import { DefaultMeterColSizes, MeterColSizes } from './common';

export const WinRateMeterColSizes = DefaultMeterColSizes;

type ComputedProps = {
  winRate: number
}

type ComputationalProps = {
  winCount: number
  matchCount: number
}

type ValueProps = ComputedProps | ComputationalProps

const isComputedWinRateProps = (props: ValueProps): props is ComputedProps => (
  (props as ComputedProps).winRate !== undefined
);

const isWinRateProps = (props: ValueProps): props is ComputationalProps => {
  props = props as ComputationalProps; // eslint-disable-line no-param-reassign
  return props.winCount !== undefined && props.matchCount !== undefined;
};

type WinRateMeterColProps = ValueProps
  & { sizes?: MeterColSizes }
  & BoxProps

const propsToOmit = ['winRate', 'winCount', 'matchCount', 'sizes'] as Array<keyof WinRateMeterColProps>;

const WinRateMeterCol = (props: WinRateMeterColProps) => {
  const { sizes = WinRateMeterColSizes } = props;

  let winRate = 0;
  if (isWinRateProps(props)) {
    winRate = (props.winCount || 0) / (props.matchCount || 1);
  } else if (isComputedWinRateProps(props)) {
    winRate = props.winRate;
  }

  const meterColor = winRate >= 0.5 ? 'outcome.victory' : 'outcome.defeat';

  return (
    <Box row width={sizes.width} fontWeight="bold" {...omit(propsToOmit, props)}>
      <Box width={sizes.valueWidth} display="flex" justifyContent="flex-end" mr={1}>
        <FormattedNumber
          value={winRate}
          style="percent"
          minimumFractionDigits={1}
          maximumFractionDigits={1}
        />
      </Box>
      <Box flexGrow={1}>
        <Meter value={winRate} foreground={meterColor} />
      </Box>
    </Box>
  );
};

export default WinRateMeterCol;
