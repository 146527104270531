import ApolloClient, { gql } from 'apollo-boost';
import { Client } from '@hitagi/redux/client';
import { Persist } from '@hitagi/redux/persist';
import getWilsonScore from 'wilson-score';

const typeDefs = gql`
  extend type MatchReplayUploadHeroSummaryType {
    withScore: Float!
    againstScore: Float!
  }
  extend type MatchReplayUploadMatchType {
    invalidated: Boolean
    deleted: Boolean
  }
`;

const resolvers = {
  MatchReplayUploadHeroSummaryType: {
    withScore: hero => getWilsonScore(hero.winCountWith, hero.matchCountWith),
    againstScore: hero => getWilsonScore(hero.winCountAgainst, hero.matchCountAgainst),
  },
  MatchReplayUploadMatchType: {
    invalidated: match => match.invalidated ?? null,
    deleted: match => match.deleted ?? null,
  },
};

const client = new ApolloClient({
  uri: `${Client.getUrl()}/graphql`,
  request: op => {
    const token = Persist.me.token.get();
    op.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });
  },
  typeDefs,
  resolvers,
});

export default client;
