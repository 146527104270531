import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Scrollable, { useScrollable } from '@hitagi/core/Scrollable';
import ScrollButton from '@hitagi/core/ScrollButton';
import Box from '@hitagi/core/Box';
import Button from '@hitagi/core/Button';

const Wrapper = styled(Box)`
  ${Button.sc} {
    flex-shrink: 0;
    margin-right: ${props => props.theme.spacing(1)};

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

function FilterList({ children, ...restProps }) {
  const ref = useRef();
  const {
    isScrollable,
    onRightScroll,
    onLeftScroll,
    onScroll,
    buttonsVisibility,
    scrollIntoView,
  } = useScrollable(ref);

  return (
    <Wrapper row {...restProps}>
      {isScrollable && (
        <ScrollButton
          direction="left"
          onClick={onLeftScroll}
          disabled={!buttonsVisibility.left}
        />
      )}
      <Scrollable ref={ref} onScroll={onScroll}>
        {React.Children.map(children, child => {
          if (!React.isValidElement) {
            return null;
          }
          return React.cloneElement(child, { onClick: scrollIntoView });
        })}
      </Scrollable>
      {isScrollable && (
        <ScrollButton
          direction="right"
          onClick={onRightScroll}
          disabled={!buttonsVisibility.right}
        />
      )}
    </Wrapper>
  );
}

FilterList.propTypes = {
  children: PropTypes.node,
};

export default FilterList;
