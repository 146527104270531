import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@hitagi/core/MenuItem';
import ListItemElement from '@hitagi/core/ListItemElement';
import BugIcon from '@hitagi/icons/Bug';
import CheckmarkIcon from '@hitagi/icons/Checkmark';
import Spinner from '@hitagi/core/Spinner';
import CrossmarkIcon from '@hitagi/icons/Crossmark';
import { matchType } from '../../../../MatchRow/Draft/propTypes';
import { useUnlinkMutation } from '../queries';

const renderIcon = state => {
  if (state.error) {
    return <BugIcon />;
  }
  if (state.loading) {
    return <Spinner />;
  }
  if (state.called) {
    return <CheckmarkIcon />;
  }
  return <CrossmarkIcon height="1em" width={18} />;
};

const UnlinkItem = ({ series }) => {
  const [unlink, state] = useUnlinkMutation({
    matchIds: series.map(m => m.id),
  });

  return (
    <MenuItem onClick={unlink}>
      <ListItemElement>
        {renderIcon(state)}
      </ListItemElement>
      Unlink Series
    </MenuItem>
  );
};

UnlinkItem.propTypes = {
  series: PropTypes.arrayOf(matchType),
};

export default UnlinkItem;
