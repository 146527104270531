import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

export const GET_TEAMS = gql`
  query Teams {
    yogurt {
      teams {
        id
        name
        isDefault
        team {
          id
        }
      }
    }
  }
`;
export const useTeamsQuery = () => useQuery(GET_TEAMS);
