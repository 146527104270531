import React from 'react';
import Box, { BoxProps } from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import { HeadCol, SortField } from '@hitagi/core/DataRow';
import { FormattedMessage } from 'react-intl';
import dotaMessages from '@hitagi/dota/messages';

export const WinLossColSizes = {
  width: 100,
  valueWidth: 32,
};

type WinLossHeadColProps<T> = {
  wSortField?: SortField<T>
  lSortField?: SortField<T>
}

export function WinLossHeadCol<T>({ wSortField, lSortField }: WinLossHeadColProps<T>) {
  return (
    <Box width={WinLossColSizes.width} row justifyContent="space-between">
      <HeadCol<T> sortField={wSortField} align="end" width={WinLossColSizes.valueWidth}>
        <FormattedMessage {...dotaMessages.wonSimple} />
      </HeadCol>
      <HeadCol<T> sortField={lSortField} align="start" width={WinLossColSizes.valueWidth}>
        <FormattedMessage {...dotaMessages.lostSimple} />
      </HeadCol>
    </Box>
  );
}

type WinLossColProps = {
  w: number
  l: number
} & BoxProps

const WinLossCol = ({ w, l, ...restProps }: WinLossColProps) => (
  <Box width={WinLossColSizes.width} row justifyContent="space-between" fontWeight="bold" {...restProps}>
    <Text variant="inherit" textAlign="end" width={WinLossColSizes.valueWidth}>
      {w}
    </Text>
    <Text color="text.disabled">-</Text>
    <Text variant="inherit" textAlign="start" width={WinLossColSizes.valueWidth}>
      {l}
    </Text>
  </Box>
);

export default WinLossCol;
