import React from 'react';
import PropTypes from 'prop-types';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import Tooltip from '@hitagi/core/Tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { getStringWidth } from '@hitagi/core/styles/utils';
import { FactionImage, FactionName } from '@hitagi/dota/Faction';
import { getRelativeOutcome } from '@hitagi/dota/utils/match';
import { Label, OnlyPrintBox } from './styled';
import { matchType } from './propTypes';
import messages from './messages';

const renderFirstPick = (match, isRadaint) => {
  if (!match.pickBans) {
    return null;
  }

  const firstPick = match.pickBans.find(pickban => pickban.isPick);
  if (!firstPick) {
    return null;
  }
  if (firstPick.isRadiant !== isRadaint) {
    return null;
  }

  return (
    <Label marginX={1}>
      <FormattedMessage {...messages.firstPick} />
    </Label>
  );
};

const TeamDraftHeader = ({ match, isRadiant, ...restProps }) => {
  const intl = useIntl();

  const isWin = getRelativeOutcome(match.didRadiantWin, isRadiant);

  const victoryStr = intl.formatMessage(messages.victory);
  const defeatStr = intl.formatMessage(messages.defeat);

  const team = isRadiant ? match.radiantTeam : match.direTeam;

  return (
    <Box row alignItems="center" {...restProps}>
      <Tooltip
        content={<FactionName isRadiant={isRadiant} />}
        as={Box}
        display="inline-flex"
      >
        <FactionImage isRadiant={isRadiant} width={18} />
      </Tooltip>
      {team && (
        <Label marginX={1}>
          {team.name}
          <OnlyPrintBox display="inline">
            {' ('}
            <FactionName isRadiant={isRadiant} />
            )
          </OnlyPrintBox>
        </Label>
      )}
      <Box flexGrow={1} />
      {renderFirstPick(match, isRadiant)}
      <Label>
        <Box width={getStringWidth(victoryStr, defeatStr)}>
          <Text color={isWin ? 'outcome.victory' : 'outcome.defeat'} fontWeight="bold">
            {isWin ? victoryStr : defeatStr}
          </Text>
        </Box>
      </Label>
    </Box>
  );
};

TeamDraftHeader.propTypes = {
  match: matchType,
  isRadiant: PropTypes.bool,
};

export default TeamDraftHeader;
