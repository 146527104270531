import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import QuickSearch, { playerSection } from '@hitagi/quick-search';
import Spinner from '@hitagi/core/Spinner';
import List from '@hitagi/core/List';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import Material from '@hitagi/core/Material';
import ListItem from '@hitagi/core/ListItem';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import Bug from '@hitagi/icons/Bug';
import Container from '@hitagi/core/Layout/Container';
import { kenAvatar } from '@hitagi/utils';
import { useTeamMembersQuery, GET_TEAM_MEMBERS } from '../queries';
import { useUser } from '../../../utils/user';
import Member from './Member';
import PageError from '../../PageError';

const QUICK_SEARCH_SECTIONS = [playerSection];

const ADD_MEMBER = gql`
  mutation addTeamMember($steamId: Long!, $teamId: Long!) {
    yogurt {
      addTeamMember(steamAccountId: $steamId, matchReplayUploadTeamId: $teamId)
    }
  }
`;

const getOptions = teamId => ({
  refetchQueries: [
    { query: GET_TEAM_MEMBERS, variables: { teamId } },
  ],
});

const TeamMembers = () => {
  const params = useParams();
  const teamId = +params.teamId || 0;

  const user = useUser();
  const members = useTeamMembersQuery(teamId);
  const [addTeamMember, add] = useMutation(ADD_MEMBER, getOptions(teamId));
  const [newMember, setNewMember] = useState(null);

  useEffect(() => {
    setNewMember(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members.data && members.data.yogurt.teamMembers]);

  if (members.error) {
    return <PageError />;
  }

  if (members.loading) {
    return (
      <Container display="flex" justifyContent="center" marginTop={3}>
        <Spinner />
      </Container>
    );
  }

  const membersList = members.data && members.data.yogurt.teamMembers;
  if (!members) {
    return null;
  }

  const getResultProps = (type, result) => {
    const onResultClick = () => {
      setNewMember(result);
      addTeamMember({ variables: { steamId: result.id, teamId } });
    };

    return { onClick: onResultClick };
  };

  const me = membersList.find(m => m.steamAccount.id === user.data.steamAccount.id);
  const amIAdmin = me && me.isAdmin;
  const meId = me && me.steamAccount.id;

  const addErrorMessage = (
    <Text color="red.main">
      {add.error && add.error.message}
    </Text>
  );

  return (
    <Container display="flex" justifyContent="center" marginTop={3}>
      <Box col width={420}>
        {amIAdmin && (
          <Box col>
            <QuickSearch
              as="div"
              width="100%"
              getResultProps={getResultProps}
              placeholder="Player Search"
              sections={QUICK_SEARCH_SECTIONS}
            />
          </Box>
        )}
        <Material padding={0} marginTop={3}>
          <List>
            {newMember && (
              <ListItem>
                <ListItemElement>
                  <img src={kenAvatar(newMember.avatar)} alt="" />
                </ListItemElement>
                <ListItemText primary={newMember.name} secondary={add.error && addErrorMessage} />
                <ListItemElement>
                  <Box height={34} width={34} row justifyContent="center" alignItems="center">
                    {add.error ? <Bug /> : <Spinner />}
                  </Box>
                </ListItemElement>
              </ListItem>
            )}
            {membersList.map(member => member.steamAccount.id !== (newMember && newMember.id) && (
              <Member
                {...member}
                key={member.steamAccount.id}
                teamId={teamId}
                isUserAdmin={amIAdmin}
                isUserMe={member.steamAccount.id === meId}
              />
            ))}
          </List>
        </Material>
      </Box>
    </Container>
  );
};

export default TeamMembers;
