import React, { useMemo, useState, useCallback } from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import QuickSearch from '@hitagi/quick-search';
import { useHeroSection } from '@hitagi/quick-search/Results/Hero';
import List from '@hitagi/core/List';
import ListItem from '@hitagi/core/ListItem';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import MenuItem from '@hitagi/core/MenuItem';
import { HeroImage, HeroName } from '@hitagi/dota/Hero';
import Box from '@hitagi/core/Box';
import Divider from '@hitagi/core/Divider';
import { EMPTY_ARRAY } from '@hitagi/utils/empties';
import SelectMenu from '@hitagi/core/SelectMenu';
import LabelButton from '@hitagi/core/LabelButton/LabelButton';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import Filter, { FilterDisclosure, createFilter } from '../Filter/index';
import messages from './messages';

const HeroFilterDisclosure = ({
  heroIds,
  friendHeroIds,
  enemyHeroIds,
  friendBannedHeroIds,
  enemyBannedHeroIds,
  ...restProps
}) => {
  const active = Boolean(heroIds
    || friendHeroIds
    || enemyHeroIds
    || friendBannedHeroIds
    || enemyBannedHeroIds);
  return (
    <FilterDisclosure active={active} {...restProps}>
      <FormattedMessage {...messages.heroes} />
    </FilterDisclosure>
  );
};

const TYPE_ANY = 'any';
const TYPE_FRIEND = 'friend';
const TYPE_ENEMY = 'enemy';
const TYPE_FRIEND_BANNED = 'friend_banned';
const TYPE_ENEMY_BANNED = 'enemy_banned';
const TYPE_NONE = 'none';

const TYPE_NAME_MAPPING = {
  [TYPE_ANY]: 'Any',
  [TYPE_FRIEND]: 'Friend',
  [TYPE_ENEMY]: 'Enemy',
  [TYPE_FRIEND_BANNED]: 'Friend banned',
  [TYPE_ENEMY_BANNED]: 'Enemy Banned',
  [TYPE_NONE]: 'None',
};

const filterAnys = item => item.type === TYPE_ANY;
const filterFriends = item => item.type === TYPE_FRIEND;
const filterEnemies = item => item.type === TYPE_ENEMY;
const filterFriendBans = item => item.type === TYPE_FRIEND_BANNED;
const filterEnemyBans = item => item.type === TYPE_ENEMY_BANNED;

const getItemHeroId = item => item.heroId;
const getItemHeroIds = items => items.map(getItemHeroId);

const getState = props => {
  const { heroIds, friendHeroIds, enemyHeroIds, friendBannedHeroIds, enemyBannedHeroIds } = props;

  const items = [];

  if (heroIds) {
    heroIds.forEach(heroId => items.push({ heroId, type: TYPE_ANY }));
  }
  if (friendHeroIds) {
    friendHeroIds.forEach(heroId => items.push({ heroId, type: TYPE_FRIEND }));
  }
  if (enemyHeroIds) {
    enemyHeroIds.forEach(heroId => items.push({ heroId, type: TYPE_ENEMY }));
  }
  if (friendBannedHeroIds) {
    friendBannedHeroIds.forEach(heroId => items.push({ heroId, type: TYPE_FRIEND_BANNED }));
  }
  if (enemyBannedHeroIds) {
    enemyBannedHeroIds.forEach(heroId => items.push({ heroId, type: TYPE_ENEMY_BANNED }));
  }

  return items;
};

const HeroFilterInput = props => {
  const {
    heroIds,
    setHeroIds,
    friendHeroIds,
    setFriendHeroIds,
    enemyHeroIds,
    setEnemyHeroIds,
    friendBannedHeroIds,
    setFriendBannedHeroIds,
    enemyBannedHeroIds,
    setEnemyBannedHeroIds,
    ...restProps
  } = props;

  const [items, setItems] = useState(getState(props));

  const heroSection = useHeroSection();
  const searchSections = useMemo(() => [heroSection], [heroSection]);

  const getResultProps = useCallback((type, hero) => {
    const onClick = () => {
      setItems(prev => {
        if (prev.findIndex(item => item.heroId === hero.id && !item.type) !== -1) {
          return prev;
        }
        return [...prev, { heroId: hero.id, type: TYPE_ANY }];
      });
    };
    return { onClick };
  }, []);

  const canClear = items.length > 0;
  const onClear = () => setItems(EMPTY_ARRAY);

  const onSave = () => batch(() => {
    const anys = items.filter(filterAnys);
    if (!anys.length) {
      setHeroIds(undefined);
    } else {
      setHeroIds(getItemHeroIds(anys));
    }

    const friends = items.filter(filterFriends);
    if (!friends.length) {
      setFriendHeroIds(undefined);
    } else {
      setFriendHeroIds(getItemHeroIds(friends));
    }

    const enemies = items.filter(filterEnemies);
    if (!enemies.length) {
      setEnemyHeroIds(undefined);
    } else {
      setEnemyHeroIds(getItemHeroIds(enemies));
    }

    const friendBans = items.filter(filterFriendBans);
    if (!friendBans.length) {
      setFriendBannedHeroIds(undefined);
    } else {
      setFriendBannedHeroIds(getItemHeroIds(friendBans));
    }

    const enemyBans = items.filter(filterEnemyBans);
    if (!enemyBans.length) {
      setEnemyBannedHeroIds(undefined);
    } else {
      setEnemyBannedHeroIds(getItemHeroIds(enemyBans));
    }
  });

  const onChangeType = event => {
    const [index, type] = event.currentTarget.dataset.type.split('-');
    setItems(prev => {
      const next = [...prev];

      if (type === TYPE_NONE) {
        return next.filter((_, i) => i != index); // eslint-disable-line eqeqeq
      }

      next[index] = { ...prev[index], type };
      return next;
    });
  };

  const renderItem = (item, index) => (
    <ListItem key={item.heroId + item.type} selectable={false}>
      <ListItemElement>
        <HeroImage id={item.heroId} />
      </ListItemElement>
      <ListItemText primary={<HeroName id={item.heroId} />} />
      <ListItemElement maxWidth="initial">
        <SelectMenu>
          <summary>
            <LabelButton>
              <LabelButton.Text>
                {TYPE_NAME_MAPPING[item.type]}
              </LabelButton.Text>
              <Divider vertical mr={0} ml="auto" />
              <LabelButton.Control>
                <ChevronDownIcon />
              </LabelButton.Control>
            </LabelButton>
          </summary>
          <SelectMenu.Modal>
            <SelectMenu.List>
              {Object.keys(TYPE_NAME_MAPPING).map(typ => (
                <MenuItem key={typ} data-type={`${index}-${typ}`} onClick={onChangeType}>
                  {TYPE_NAME_MAPPING[typ]}
                </MenuItem>
              ))}
            </SelectMenu.List>
          </SelectMenu.Modal>
        </SelectMenu>
      </ListItemElement>
    </ListItem>
  );

  return (
    <Filter canClear={canClear} onClear={onClear} onSave={onSave} {...restProps}>
      <QuickSearch
        getResultProps={getResultProps}
        sections={searchSections}
      />
      {items.length > 0 && (
        <>
          <Box marginBottom={2} />
          <List>
            {items.map(renderItem)}
          </List>
        </>
      )}
    </Filter>
  );
};

const HeroFilter = createFilter(HeroFilterDisclosure, HeroFilterInput);

HeroFilter.propTypes = {
  heroIds: PropTypes.arrayOf(PropTypes.number),
  setHeroIds: PropTypes.func,
  friendHeroIds: PropTypes.arrayOf(PropTypes.number),
  setFriendHeroIds: PropTypes.func,
  enemyHeroIds: PropTypes.arrayOf(PropTypes.number),
  setEnemyHeroIds: PropTypes.func,
  friendBannedHeroIds: PropTypes.arrayOf(PropTypes.number),
  setFriendBannedHeroIds: PropTypes.func,
  enemyBannedHeroIds: PropTypes.arrayOf(PropTypes.number),
  setEnemyBannedHeroIds: PropTypes.func,
};
HeroFilterDisclosure.propTypes = HeroFilter.propTypes;
HeroFilterInput.propTypes = HeroFilter.propTypes;

export default HeroFilter;
