import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '@hitagi/core/Box';
import StratzIcon from '@hitagi/icons/StratzBrand';
import Text from '@hitagi/core/Text';
import { Wrapper, Triangle } from './Footer.styled';
import FooterPageLinks from './FooterPageLinks';
import FooterSocialLinks from './FooterSocialLinks';
import messages from './messages';

const currentYear = new Date().getFullYear();
const stratzCopyrightValues = { currentYear };

const Footer = props => (
  <Wrapper {...props}>
    <Triangle />
    <Box marginTop={-3} marginBottom={3}>
      <Link to="/">
        <StratzIcon height={64} />
      </Link>
    </Box>
    <FooterSocialLinks />
    <FooterPageLinks />
    <Box marginY={3}>
      <Text textAlign="center" color="text.disabled">
        <FormattedMessage {...messages.stratzCopyright} values={stratzCopyrightValues} />
        <br />
        <FormattedMessage {...messages.stratzTrademark} />
        <br />
        <FormattedMessage {...messages.dotaTrademark} />
      </Text>
    </Box>
  </Wrapper>
);

export default Footer;
