import React from 'react';
import ArrowRightIcon from '@hitagi/icons/ArrowCircleRightSolid';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import MenuItem from '@hitagi/core/MenuItem';
import SteamIcon from '@hitagi/icons/SteamBrand';
import { useUser } from '../../utils/user';

const HeaderUserMenuAccountItem = () => {
  const user = useUser();

  const handleClick = () => {
    if (user.isLoggedIn) {
      user.logout();
    } else {
      user.login();
    }
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemElement>
        {user.isLoggedIn ? <ArrowRightIcon /> : <SteamIcon />}
      </ListItemElement>
      <ListItemText
        primary={user.isLoggedIn ? 'Log out' : 'Log in'}
      />
    </MenuItem>
  );
};

export default HeaderUserMenuAccountItem;
