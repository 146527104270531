import { defineMessages } from 'react-intl';

export default defineMessages({
  date: 'Date',
  defeat: 'Defeat',
  duration: 'Duration',
  firstPick: '1st pick',
  matchId: 'Match ID',
  score: 'Score',
  victory: 'Victory',
});
