import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

const middleware = [thunkMiddleware];
if (process.env.NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger({
    collapsed: true,
  });
  middleware.push(loggerMiddleware);
}

export default middleware;
