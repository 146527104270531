import React from 'react';
import PropTypes from 'prop-types';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import { DataImage } from '@hitagi/core/DataImage';
import { HeroImage } from '@hitagi/dota/Hero';
import styled from 'styled-components';
import { get } from '@hitagi/utils';
import { matchType } from '../../../../MatchRow/Draft/propTypes';

const StyledDataImage = styled(DataImage)`
  box-shadow: ${props => props.theme.palette.black(0.4)} 0px 0px 8px;
`;

const renderPlayer = player => {
  const playerName = get(['steamAccount', 'proSteamAccount', 'name'], player) || get(['steamAccount', 'name'], player);
  return (
    <Box key={player.heroId} col alignItems="center" width={82}>
      <StyledDataImage width={64}>
        <HeroImage id={player.heroId} variant="horz" />
      </StyledDataImage>
      <Box maxWidth="100%" marginTop={1}>
        <Text noWrap color="secondary">
          {player.steamAccount ? (
            <a href={`https://stratz.com/players/${player.steamAccount.id}`} target="_blank" rel="noreferrer">
              {playerName}
            </a>
          ) : playerName}
        </Text>
      </Box>
    </Box>
  );
};

const renderRadiantPlayer = player => player.isRadiant && renderPlayer(player);
const renderDirePlayer = player => !player.isRadiant && renderPlayer(player);

const MatchTeam = ({ match, isRadiant, ...restProps }) => (
  <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gridColumnGap={1} {...restProps}>
    {match.players.map(isRadiant ? renderRadiantPlayer : renderDirePlayer)}
  </Box>
);

MatchTeam.propTypes = {
  match: matchType,
  isRadiant: PropTypes.bool,
};

export default MatchTeam;
