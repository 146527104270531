import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import BaseCSS from '@hitagi/core/BaseCSS';

const AppCSS = createGlobalStyle`
  :root {
    color-scheme: ${props => props.theme.palette.type};
  }

  html,
  body {
    height: 100%;
  }

  body {
    min-width: 360px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  #root {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const GlobalStyle = () => (
  <>
    <BaseCSS />
    <AppCSS />
  </>
);

export const MainWrapper = styled.main`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
