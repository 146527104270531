import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@hitagi/core/ListItem';
import ListItemText from '@hitagi/core/ListItemText';
import ListItemElement from '@hitagi/core/ListItemElement';
import Spinner from '@hitagi/core/Spinner';
import CheckmarkIcon from '@hitagi/icons/Checkmark';
import BugIcon from '@hitagi/icons/Bug';
import Ellipsis from '@hitagi/core/Ellipsis';
import Text from '@hitagi/core/Text';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

const IMPORT_MATCH = gql`
  mutation ImportMatch($teamId: Long!, $matchId: Long!) {
      yogurt {
        importMatch(matchReplayUploadTeamId: $teamId, matchId: $matchId)
      }
    }
`;

const renderProgressIcon = state => {
  const { loading, error, called } = state;
  if (error) {
    return <BugIcon />;
  }
  if (loading) {
    return <Spinner />;
  }
  if (called) {
    return <CheckmarkIcon />;
  }
  return null;
};

const renderProgressCaption = state => {
  const { loading, error, called } = state;
  if (error) {
    return (
      <Text color="red.main" noWrap>
        Error: {error.message}
      </Text>
    );
  }
  if (loading) {
    return <Ellipsis>Importing</Ellipsis>;
  }
  if (called) {
    return 'Done!';
  }
  return <Ellipsis />;
};

const Match = ({ id }) => {
  const params = useParams();
  const teamId = +params.teamId;

  const [importMatch, state] = useMutation(IMPORT_MATCH);

  useEffect(() => {
    importMatch({ variables: { teamId, matchId: id } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ListItem>
      <ListItemText
        primary={`Match ${id}`}
        secondary={renderProgressCaption(state)}
      />
      <ListItemElement>
        {renderProgressIcon(state)}
      </ListItemElement>
    </ListItem>
  );
};

Match.propTypes = {
  id: PropTypes.number,
};

export default Match;
