import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: 1px solid ${props => props.theme.palette.divider};
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  padding: ${props => props.theme.spacing(2, 4)};
  display: flex;

  a {
    margin: ${props => props.theme.spacing(0, 4)};

    svg {
      height: 24px;
      display: block;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${props => props.theme.media.lt(props.breakWidth)} {
    a {
      margin: ${props => props.theme.spacing(0, 2)};
    }
  }
`;
