import React from 'react';
import ListItemText from '@hitagi/core/ListItemText';
import MenuItem from '@hitagi/core/MenuItem';
import Toggle from '@hitagi/core/Toggle';
import ListItemElement from '@hitagi/core/ListItemElement';
import MoonSolidIcon from '@hitagi/icons/MoonSolid';
import SunSolidIcon from '@hitagi/icons/SunSolid';
import { useTheme } from '../../utils/theme';

const HeaderUserMenuThemeItem = () => {
  const theme = useTheme();

  return (
    <MenuItem selectable={false}>
      <ListItemElement>
        {theme.isDark ? <MoonSolidIcon /> : <SunSolidIcon />}
      </ListItemElement>
      <ListItemText primary="Dark Theme" />
      <ListItemElement>
        <Toggle checked={theme.isDark} onChange={theme.toggle} />
      </ListItemElement>
    </MenuItem>
  );
};

export default HeaderUserMenuThemeItem;
