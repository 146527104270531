import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const teamIntoType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  isDefault: PropTypes.bool,
  team: PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.string,
  }),
});
