import React from 'react';
import { Cirlect, CirlectGraphic, CirlectItem } from '@hitagi/core/Cirlect';
import Modal from '@hitagi/core/Modal';
import Text from '@hitagi/core/Text';
import ModalContent from '@hitagi/core/ModalContent';
import { Position, PositionIcon, PositionName } from '@hitagi/dota/Position';
import { MatchPlayerType } from '../../../MatchRow/Draft/propTypes';

type ModifyPositionModalProps = {
  player: NonNullable<MatchPlayerType>
  onClose: () => void
  onChange: (newPosition: Position.Enum) => void
}

const ModifyPositionModal = (props: ModifyPositionModalProps) => {
  const { player, onClose, onChange } = props;

  const handleChange = (_: unknown, newPosition: string) => {
    if (Position.isGqlEnum(newPosition)) {
      onChange(newPosition);
    }
    onClose();
  };

  return (
    <Modal open onClose={onClose} preserveDocumentOverflow>
      {/* @ts-expect-error TODO: convert ModalContent to ts */}
      <ModalContent col alignItems="stretch" opaque p={2}>
        <Text variant="title" alignSelf="center">
          Select new position
        </Text>
        <Cirlect value={player.position} onChange={handleChange} mt={2} alignSelf="center">
          {Position.gqlEnums.map(em => (
            <CirlectItem key={em} value={em}>
              <CirlectGraphic variant="icon">
                <PositionIcon enum={em} />
              </CirlectGraphic>
              <Text color="text.secondary">
                <PositionName enum={em} />
              </Text>
            </CirlectItem>
          ))}
        </Cirlect>
      </ModalContent>
    </Modal>
  );
};

export default ModifyPositionModal;
