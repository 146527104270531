import React, { useRef } from 'react';
import useScrollWidth from '@hitagi/core/useScrollWidth';
import { Wrapper } from './FooterSocialLinks.styled';
import { SOCIAL_LINKS } from '../../router/externalLinks';

const renderLink = link => (
  <a key={link.href} target="_blank" rel="noreferrer noopener" href={link.href}>
    {link.icon}
  </a>
);

const FooterSocialLinks = () => {
  const ref = useRef();
  const width = useScrollWidth(ref);

  return (
    <Wrapper ref={ref} breakWidth={width}>
      {SOCIAL_LINKS.map(renderLink)}
    </Wrapper>
  );
};

export default FooterSocialLinks;
