import React, { useState, useEffect } from 'react';
import Container from '@hitagi/core/Layout/Container';
import { EMPTY_ARRAY } from '@hitagi/utils/empties';
import { get } from '@hitagi/utils';
import useQueryStringReducer from '@hitagi/core/useQueryStringReducer';
import Text from '@hitagi/core/Text';
import Box from '@hitagi/core/Box';
import Button from '@hitagi/core/Button';
import { Link, useParams } from 'react-router-dom';
import Phantom from '@hitagi/core/Phantom';
import ActionHeader from '../../../ActionHeader';
import { reducer, initialState, stringifiers, parsers } from '../flux';
import Filters from '../Filters';
import { useTeamMatchesQuery } from '../../queries';
import Series from './Series';
import { groupMatches } from '../helpers';
import DraftsSummary from './DraftsSummary';
import DraftsHeroSummary from './DraftsHeroSummary';
import PageError from '../../../PageError';
import PageSpinner from '../../../PageSpinner';

const renderFeedback = (loading, error, data) => {
  if (error) {
    return <PageError error={error} />;
  }

  if (loading) {
    return <PageSpinner />;
  }

  if (!data.yogurt.basicOverview.matchCount) {
    const teamId = data.yogurt.team.id;
    return (
      <Container center col>
        <ActionHeader title="You don't have any matches" />
        <Text>
          When you import or upload, and review match, it&apos;ll show up here
        </Text>
        <Box row marginTop={2} alignItems="center">
          <Link to={`/teams/${teamId}/upload`}>
            <Button bg="blue.glass">Upload</Button>
          </Link>
          <Box marginX={2}>or</Box>
          <Link to={`/teams/${teamId}/import`}>
            <Button bg="blue.glass">Import</Button>
          </Link>
        </Box>
      </Container>
    );
  }

  if (!data.yogurt.overview.matches.length) {
    return (
      <Container center col>
        <ActionHeader title="There are no matches matching filter criteria" />
      </Container>
    );
  }

  return null;
};

const Drafts = () => {
  const params = useParams();
  const teamId = +params.teamId;

  const [state, dispatch] = useQueryStringReducer(
    reducer,
    initialState,
    stringifiers,
    parsers,
  );
  const [series, setSeries] = useState(EMPTY_ARRAY);

  const { loading, error, data } = useTeamMatchesQuery(teamId, {
    ...state,
    isValidated: true,
  });

  const matches = get(['yogurt', 'overview', 'matches'], data);
  const heroes = get(['yogurt', 'heroSummary'], data);

  useEffect(() => {
    if (matches) {
      setSeries(groupMatches(matches));
    }
  }, [matches]);

  const renderSeries = (s) => (
    <Phantom key={s[0].id}>
      {() => (
        <Series
          series={s}
          dotaTeamId={data.yogurt.team.team?.id}
          yogurtTeamId={teamId}
        />
      )}
    </Phantom>
  );

  return (
    <>
      <Filters state={state} dispatch={dispatch} matches={matches} />
      {renderFeedback(loading, error, data) || (
        <>
          <DraftsSummary info={data.yogurt} />
          <DraftsHeroSummary heroes={heroes} info={data.yogurt} />
          <Container marginTop={3}>{series.map(renderSeries)}</Container>
        </>
      )}
    </>
  );
};

export default Drafts;
