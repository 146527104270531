import React from 'react';
import PropTypes from 'prop-types';
import Text from '@hitagi/core/Text';

const ActionHeader = ({ emoji, ariaLabel, title }) => (
  <>
    <Text variant="display">
      <span role="img" aria-label={ariaLabel}>{emoji}</span>
    </Text>
    <Text variant="display2" textAlign="center">
      {title}
    </Text>
  </>
);

ActionHeader.propTypes = {
  emoji: PropTypes.node,
  ariaLabel: PropTypes.string,
  title: PropTypes.node,
};

export default ActionHeader;
