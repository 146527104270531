import React from 'react';
import PropTypes from 'prop-types';
import Button from '@hitagi/core/Button';
import Menu from '@hitagi/core/Menu';
import Box from '@hitagi/core/Box';
import EllipsisVIcon from '@hitagi/icons/EllipsisV';
import { usePopperState } from '@hitagi/core/Popper';
import { matchType } from '../../../../MatchRow/Draft/propTypes';
import UnlinkItem from './UnlinkItem';

const SeriesMenu = ({ series }) => {
  const menu = usePopperState();

  return (
    <>
      <Button isIconOnly size="small" disableOutline disableBg onClick={menu.onOpen}>
        <EllipsisVIcon />
      </Button>
      <Menu {...menu} placement="bottom-end">
        <UnlinkItem series={series} />
        <Box minWidth={128} />
      </Menu>
    </>
  );
};

SeriesMenu.propTypes = {
  series: PropTypes.arrayOf(matchType),
};

export default SeriesMenu;
