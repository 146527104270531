import styled from 'styled-components';

export const Wrapper = styled.footer`
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  background-color: ${props => props.theme.palette.background.opaqueGlass[2]};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.theme.spacing(8)};

  a {
    color: ${props => props.theme.palette.text.secondary};
    transition: ${props => props.theme.transitions.create(['color'])};

    &:hover {
      color: ${props => props.theme.palette.text.primary};
    }
  }

  @media print {
    display: none;
  }
`;

export const Triangle = styled.div`
  border-left: 50vw solid ${props => props.theme.palette.background.opaqueGlass[1]};
  border-right: 50vw solid ${props => props.theme.palette.background.opaqueGlass[1]};
  border-bottom: 50px solid transparent;
`;
