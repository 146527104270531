import { createStore, applyMiddleware } from 'redux';
import reducer from './reducer';
import middleware from './middleware';

const store = createStore(reducer, applyMiddleware(...middleware));

if (process.env !== 'production') {
  // eslint-disable-next-line no-underscore-dangle
  window._redux = store;
}

export default store;
