import { getColor, disableBr, DisableBrProps } from '@hitagi/core/styles/system';
import { pseudoOutline } from '@hitagi/core/styles/cssChunks';
import Box, { BoxProps } from '@hitagi/core/Box';
import styled, { css } from 'styled-components';

type Variant = 'outlined' | 'outlined-hard'

const OUTLINE_COLOR_MAPPING: Record<Variant, string> = {
  outlined: 'background.clearGlass.1',
  'outlined-hard': 'text.primary',
};

type RichProps = {
  elevation?: number
  variant?: Variant
  actionable?: boolean
  opaque?: boolean
}

type TintedProps = {
  tinted?: boolean
}

function isRichProps<T>(props: (RichProps | TintedProps) & T): props is RichProps & T {
  return (props as TintedProps).tinted === undefined;
}

function isTintedProps<T>(props: (RichProps | TintedProps) & T): props is TintedProps & T {
  return (props as TintedProps).tinted !== undefined;
}

export type GlassProps = (RichProps | TintedProps)
  & BoxProps
  & DisableBrProps

const getBackgroundColor = (props: GlassProps & { theme: any }) => {
  if (isTintedProps(props)) {
    return props.tinted && props.theme.palette.background.tintedGlass;
  }
  const glass = props.opaque
    ? props.theme.palette.background.opaqueGlass
    : props.theme.palette.background.clearGlass;
  return glass[props.elevation ?? 1];
};

const PROPS = ['elevation', 'variant', 'actionable', 'opaque', ...disableBr.propNames!];

const Glass = styled(Box)
  .withConfig<GlassProps>({
    shouldForwardProp: (prop, defaultValidatorFn) => (
      defaultValidatorFn(prop) && !PROPS.includes(prop as any)
    ),
  })<GlassProps>(props => {
    const richProps = isRichProps(props) ? props : undefined;
    return css`
      background-color: ${getBackgroundColor};
      border-radius: ${props.theme.shape.borderRadius}px;
      position: ${props.position ?? 'relative'};

      ${richProps?.actionable && css`
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: ${props.theme.palette.background.clearGlass[2]};
        }

        &:active {
          background-color: ${props.theme.palette.background.clearGlass[3]};
        }
      `}

      ${richProps?.variant && css`
        ${pseudoOutline}

        &::after {
          box-shadow: inset 0 0 0 1px ${getColor(OUTLINE_COLOR_MAPPING[richProps.variant], props.theme.palette)};
        }
      `}

      ${disableBr}
    `;
  });

export default Glass;
