import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ window });

if (process.env !== 'production') {
  // eslint-disable-next-line no-underscore-dangle
  window._history = history;
}

export default history;
