import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import Divider from '@hitagi/core/Divider';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import LabelButton from '@hitagi/core/LabelButton';
import Phantom from '@hitagi/core/Phantom';
import SelectMenu from '@hitagi/core/SelectMenu';
import SvgIcon from '@hitagi/icons/SvgIcon';
import messages from './messages';
import PlayerImage from '../PlayerImage';

export const STEAM_ACCOUNT_PAGE_MODIFIER_FRAMGNET = `
  fragment SteamAccountPageModifierSteamAccountTypeFragment on SteamAccountType {
    id
    name
    avatar
    proSteamAccount {
      name
      team {
        id # keyField
        tag
        name
      }
    }
  }
`;

export type SteamAccountPageModifierSteamAccountTypeFragment = {
  id: number
  name: string
  avatar: string
  proSteamAccount: {
    name: string
    team: {
      tag: string
      name: string
    } | null
  } | null
}

type SteamAccountPageModifierProps = {
  steamAccountId: number | undefined
  setSteamAccountId: (steamAccountId: number | undefined) => void;
  unlistUnion?: boolean;
  steamAccounts: SteamAccountPageModifierSteamAccountTypeFragment[]
};

const SteamAccountPageModifier = (props: SteamAccountPageModifierProps) => {
  const {
    steamAccountId,
    setSteamAccountId,
    unlistUnion,
    steamAccounts,
    ...restProps
  } = props;

  const intl = useIntl();

  const [search, setSearch] = useState('');
  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };
  const handleSelection = (event: React.MouseEvent<HTMLLIElement>) => {
    const { value } = event.currentTarget.dataset;
    const normalizedValue = value === undefined ? undefined : +value;
    setSteamAccountId(normalizedValue);
    setSearch('');
  };

  const normalizedSearch = search.toLowerCase();
  const renderItem = (steamAccount: typeof steamAccounts[0]) => {
    if (search) {
      const found = steamAccount.proSteamAccount?.name.toLowerCase().includes(normalizedSearch)
        || steamAccount.name.toLowerCase().includes(normalizedSearch);
      if (!found) {
        return null;
      }
    }

    return (
      <SelectMenu.Item
        key={steamAccount.id}
        selected={steamAccount.id === steamAccountId}
        data-value={steamAccount.id}
        onClick={handleSelection}
      >
        <ListItemElement maxWidth={24} maxHeight={24}>
          <PlayerImage
            steamAccountId={steamAccount.proSteamAccount ? steamAccount.id : undefined}
            avatar={steamAccount.avatar}
            width={24}
          />
        </ListItemElement>
        <ListItemText
          primary={steamAccount.proSteamAccount?.name || steamAccount.name}
          secondary={steamAccount.proSteamAccount?.team?.name}
        />
      </SelectMenu.Item>
    );
  };

  const steamAccount = steamAccountId !== undefined
    && steamAccounts.find(sa => sa.id === steamAccountId);

  return (
    <SelectMenu {...restProps}>
      <summary>
        <LabelButton>
          {steamAccount && (
            <LabelButton.Graphic size={LabelButton.Graphic.size.max}>
              <PlayerImage
                steamAccountId={steamAccount.proSteamAccount ? steamAccount.id : undefined}
                avatar={steamAccount.avatar}
              />
            </LabelButton.Graphic>
          )}
          <LabelButton.Text>
            {steamAccount
              ? steamAccount.proSteamAccount?.name || steamAccount.name
              : intl.formatMessage(messages.allPlayers)}
          </LabelButton.Text>
          <Divider vertical margin={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.Filter
          placeholder={intl.formatMessage(messages.filterPlayers)}
          value={search}
          onChange={handleSearch}
        />
        <SelectMenu.List>
          {!unlistUnion && !search && (
            <SelectMenu.Item
              selected={steamAccountId === undefined}
              onClick={handleSelection}
            >
              <ListItemElement>
                <SvgIcon />
              </ListItemElement>
              <ListItemText primary={intl.formatMessage(messages.allPlayers)} />
            </SelectMenu.Item>
          )}
          <Phantom>
            {() => steamAccounts.map(renderItem)}
          </Phantom>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default SteamAccountPageModifier;
