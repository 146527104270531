import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Material from '@hitagi/core/Material';
import { Draggable } from 'react-beautiful-dnd';
import MoveIcon from '@hitagi/icons/Move';
import { matchType } from '../../../MatchRow/Draft/propTypes';
import ErrorBoundary from '../MatchRowErrorBoundary';
import MatchRow from './MatchRow';
import { DragHandle, RightButtonsCol } from './styled';
import ValidationHandle from './ValidationHandle';
import DeletionHandle from './DeletionHandle';

const DraggableMaterial = styled(Material).attrs({ elevation: 0, padding: 0 })`
  border-bottom: 1px solid ${props => props.isDragging
  ? 'transparent'
  : props.theme.palette.divider};
  position: relative;
  background-color: ${props => props.isDragging
    ? props.theme.palette.background.opaqueGlass[4]
    : undefined};

  &:last-of-type {
    border-bottom-color: transparent;
  }
`;

const DraggableMatch = ({ match, index, isPrimaryTeamRadiant, teamId, isDraggable }) => {
  const matchRow = (
    <MatchRow
      match={match}
      isPrimaryTeamRadiant={isPrimaryTeamRadiant}
      teamId={teamId}
    />
  );

  const rightButtonsCol = (
    <RightButtonsCol>
      {!match.isValidated && <ValidationHandle teamId={teamId} matchId={match.id} />}
      <DeletionHandle yogurtTeamId={teamId} matchId={match.id} />
    </RightButtonsCol>
  );

  if (match.seriesId) {
    return (
      <ErrorBoundary>
        <DraggableMaterial>
          {matchRow}
          {rightButtonsCol}
        </DraggableMaterial>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Draggable draggableId={`match-${match.id}`} index={index}>
        {(provided, snapshot) => (
          <DraggableMaterial
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
          >
            {isDraggable && (
              <DragHandle isIconOnly disableOutline disableBg {...provided.dragHandleProps}>
                <MoveIcon />
              </DragHandle>
            )}
            {matchRow}
            {rightButtonsCol}
          </DraggableMaterial>
        )}
      </Draggable>
    </ErrorBoundary>
  );
};

DraggableMatch.propTypes = {
  match: matchType,
  index: PropTypes.number,
  isPrimaryTeamRadiant: PropTypes.bool,
  teamId: PropTypes.number,
  isDraggable: PropTypes.bool,
};

export default React.memo(DraggableMatch);
