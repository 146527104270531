const createFragmentOn = type => `
  fragment MatchDraftRow on ${type} {
    didRadiantWin
    startDateTime
    durationSeconds
    id
    gameMode
    pickBans {
      isRadiant
      isPick
      order
      heroId
      bannedHeroId
      wasBannedSuccessfully
    }
    players {
      isRadiant
      steamAccount {
        id
        name
        proSteamAccount {
          name
        }
      }
      kills
      heroId
      position
    }
    radiantTeam {
      id
      name
    }
    direTeam {
      id
      name
    }
  }
`;

export const matchTypeFragment = createFragmentOn('MatchType');
export const matchReplayUploadTypeFragment = createFragmentOn('MatchReplayUploadMatchType');
