import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@hitagi/core/Box';
import { HeroImage, HeroName } from '@hitagi/dota/Hero';
import Text from '@hitagi/core/Text';

const Row = styled.li`
  align-items: center;
  display: flex;
  padding: ${props => props.theme.spacing(1)};

  &:nth-child(odd) {
    background-color: ${props => props.theme.palette.background.clearGlass[1]};
  }
`;

const Avatar = styled.div`
  flex-shrink: 0;
  height: 30px;
  margin-right: ${props => props.theme.spacing(1)};
  width: 50px;

  img {
    border-radius: ${props => props.theme.shape.borderRadius}px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const Name = styled(Text)`
  width: 110px;
`;

const TableRow = ({ heroId, children }) => (
  <Row key={heroId}>
    <Box width="100%" row alignItems="center">
      <Avatar>
        <HeroImage id={heroId} variant="horz" />
      </Avatar>
      <Name as="span" noWrap>
        <HeroName id={heroId} />
      </Name>
    </Box>
    {children}
  </Row>
);

TableRow.propTypes = {
  heroId: PropTypes.number,
  children: PropTypes.node,
};

export default TableRow;
