import { EMPTY_OBJECT } from '@hitagi/utils';
import keymirror from 'keymirror';
import { getLocalDotaConstants } from '../utils/dotaConstants';

const actionTypes = keymirror({
  SET_DOTA_CONSTANTS: null,
});

export const setDotaConstants = dotaConstants => ({
  type: actionTypes.SET_DOTA_CONSTANTS,
  dotaConstants,
});

export const dotaConstantsReducer = (state = getLocalDotaConstants() ?? EMPTY_OBJECT, action) => {
  if (action.type === actionTypes.SET_DOTA_CONSTANTS) {
    return action.dotaConstants;
  }
  return state;
};

export const selectDotaConstants = state => state.frontend.dotaConstants;
