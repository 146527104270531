import React from 'react';
import DiscordIcon from '@hitagi/icons/DiscordBrand';
import FacebookIcon from '@hitagi/icons/FacebookBrand';
import MediumIcon from '@hitagi/icons/MediumBrand';
import TwitterIcon from '@hitagi/icons/TwitterBrand';
import YoutubeIcon from '@hitagi/icons/YoutubeBrand';

export const PRODUCT_LINKS = [
  {
    name: 'STRATZ',
    path: 'https://stratz.com',
  },
  {
    name: 'STRATZ+',
    path: 'https://stratz.com/plus',
  },
  {
    name: 'REST API',
    path: 'https://docs.stratz.com',
  },
  {
    name: 'GraphQL API',
    path: 'https://api.stratz.com/graphiql',
  },
];

export const SOCIAL_LINKS = [
  {
    href: 'https://www.facebook.com/StratzDota',
    icon: <FacebookIcon />,
  },
  {
    href: 'https://www.twitter.com/StratzDota',
    icon: <TwitterIcon />,
  },
  {
    href: 'https://discordapp.com/invite/5WWngXa',
    icon: <DiscordIcon />,
  },
  {
    href: 'https://medium.com/@STRATZ',
    icon: <MediumIcon />,
  },
  {
    href: 'https://www.youtube.com/channel/UCqTK9cWhervApIlnp3VDV_g/videos',
    icon: <YoutubeIcon />,
  },
];
