import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@hitagi/core/Box';
import Material from '@hitagi/core/Material';
import UpIcon from '@hitagi/icons/ChevronUp';
import DownIcon from '@hitagi/icons/ChevronDown';
import Text from '@hitagi/core/Text';
import { DotaContext } from '@hitagi/dota/Context';
import { getWinRate } from '../Matches/Drafts/DraftsHeroSummary';
import Row from './TableRow';
import Stat from './TableStat';
import { Header } from './styled';
import { sortByName } from './helpers';

const renderHero = hero => {
  const {
    heroId,
    matchCountWith,
    winCountWith,
    matchCountAgainst,
    winCountAgainst,
  } = hero;

  const winRateWith = getWinRate(matchCountWith, winCountWith) || 0;
  const winRateAgainst = getWinRate(matchCountAgainst, winCountAgainst) || 0;

  return (
    <Row key={heroId} heroId={heroId}>
      <Stat
        stat={`${winCountWith} - ${matchCountWith - winCountWith}`}
        percent={winRateWith}
      />
      <Stat
        stat={`${winCountAgainst} - ${matchCountAgainst - winCountAgainst}`}
        percent={winRateAgainst}
      />
    </Row>
  );
};

const ascWith = (heroA, heroB) => heroA.withScore - heroB.withScore;
const descWith = (heroA, heroB) => heroB.withScore - heroA.withScore;
const ascAgainst = (heroA, heroB) => heroA.againstScore - heroB.againstScore;
const descAgainst = (heroA, heroB) => heroB.againstScore - heroA.againstScore;

const descMatchCountWith = (heroA, heroB) => heroB.matchCountWith - heroA.matchCountWith;
const descMatchCountAgainst = (heroA, heroB) => heroB.matchCountAgainst - heroA.matchCountAgainst;

const icons = {
  asc: <UpIcon height="0.8em" />,
  desc: <DownIcon height="0.8em" />,
};

const TableWin = ({ heroes: heroesProp, ...restProps }) => {
  const [sortName, setSortName] = useState('');
  const [sortWith, setSortWith] = useState('desc');
  const [sortAgainst, setSortAgainst] = useState('');
  const dota = useContext(DotaContext);

  const onClickWith = () => {
    setSortName('');
    setSortAgainst('');
    setSortWith(prev => {
      if (prev === '') {
        return 'desc';
      }
      if (prev === 'desc') {
        return 'asc';
      }
      if (prev === 'asc') {
        return 'desc';
      }
      return '';
    });
  };

  const onClickAgainst = () => {
    setSortName('');
    setSortWith('');
    setSortAgainst(prev => {
      if (prev === '') {
        return 'desc';
      }
      if (prev === 'desc') {
        return 'asc';
      }
      if (prev === 'asc') {
        return 'desc';
      }
      return '';
    });
  };

  const onClickTitle = () => {
    setSortWith('');
    setSortAgainst('');
    setSortName(prev => {
      if (prev === '') {
        return 'desc';
      }
      if (prev === 'desc') {
        return 'asc';
      }
      if (prev === 'asc') {
        return 'desc';
      }
      return '';
    });
  };

  const heroes = heroesProp.slice();
  if (sortName) {
    sortByName(heroes, dota, sortByName);
  } else if (sortAgainst === 'desc') {
    heroes.sort(descMatchCountAgainst).sort(descAgainst);
  } else if (sortAgainst === 'asc') {
    heroes.sort(descMatchCountAgainst).sort(ascAgainst);
  } else if (sortWith === 'desc') {
    heroes.sort(descMatchCountWith).sort(descWith);
  } else if (sortWith === 'asc') {
    heroes.sort(descMatchCountWith).sort(ascWith);
  }

  return (
    <Box width="100%" marginRight={1} {...restProps}>
      <Header>
        <Box as="span" onClick={onClickTitle}>
          <Text variant="head2" as="span">WIN</Text> {icons[sortName]}
        </Box>
        <Box flexGrow={1} />
        <Box width="12ch" as="span" onClick={onClickWith}>
          WITH {icons[sortWith]}
        </Box>
        <Box width="10ch" as="span" onClick={onClickAgainst}>
          AGAINST {icons[sortAgainst]}
        </Box>
      </Header>
      <Material padding={0} component="ul">
        {heroes.map(renderHero)}
      </Material>
    </Box>
  );
};

TableWin.propTypes = {
  heroes: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

export default TableWin;
