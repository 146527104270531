import React from 'react';
import Box from '@hitagi/core/Box';
import { LinksRow } from './FooterPageLinks.styled';
import { PRODUCT_LINKS } from '../../router/externalLinks';

const renderOtherLink = link => (
  <a key={link.path} href={link.path} target="_blank" rel="noreferrer noopener">
    {link.name}
  </a>
);

const FooterPageLinks = () => (
  <Box col alignItems="center">
    <LinksRow>
      {PRODUCT_LINKS.map(renderOtherLink)}
    </LinksRow>
  </Box>
);

export default FooterPageLinks;
