import { useCallback, useMemo } from 'react';
import { Client } from '@hitagi/redux/client';
import { getPageUrl, get } from '@hitagi/utils';
import { useDispatch, useSelector } from 'react-redux';
import { logMeOut } from '@hitagi/redux/actions/me';
import { getMyProfileData, getMyProfileLoading, getMyProfileError } from '@hitagi/redux/selectors/me';

const login = () => {
  const url = [
    Client.url,
    Client.urlVersion,
    Client.getAuthRoute(),
  ].filter(Boolean).join('/');

  const returnUrl = getPageUrl({
    includePathname: true,
    includeSearch: true,
    includeHash: true,
  });

  window.location.href = `${url}?returnUrl=${returnUrl}`;
};

// eslint-disable-next-line import/prefer-default-export
export const useUser = () => {
  const data = useSelector(getMyProfileData);
  const loading = useSelector(getMyProfileLoading);
  const error = useSelector(getMyProfileError);

  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(logMeOut()), [dispatch]);

  return useMemo(() => ({
    loading,
    data,
    error,
    isLoggedIn: Boolean(get(['steamAccount', 'id'], data)),
    login,
    logout,
  }), [loading, data, error, logout]);
};
