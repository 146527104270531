import React from 'react';
import PropTypes from 'prop-types';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';

export const IndexColSizes = {
  width: 21,
};

const IndexCol = ({ index, ...restProps }) => (
  <Box width={IndexColSizes.width} textAlign="end" {...restProps}>
    <Text color="text.disabled">
      {index + 1}
    </Text>
  </Box>
);

IndexCol.propTypes = {
  index: PropTypes.number,
};

export default IndexCol;
