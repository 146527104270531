import { combineReducers } from 'redux';
import ui from '@hitagi/redux/reducers/ui';
import me from '@hitagi/redux/reducers/me';
import notificationStack from '@hitagi/redux/reducers/notificationStack';
import { dotaConstantsReducer } from './allDotaConstants';

const reducer = combineReducers({
  hitagi: combineReducers({
    ui,
    notificationStack,
    me,
  }),
  frontend: combineReducers({
    dotaConstants: dotaConstantsReducer,
  }),
});

export default reducer;
