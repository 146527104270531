import React from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '@hitagi/page/PageHeader';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import * as PageVar from '@hitagi/page/PageVar';
import TeamActions from './TeamActions';
import PageNoData from '../../PageNoData';
import { getTeamImageUrl } from '../../TeamImage';
import { TEAM_HEADER_QUERY } from './common';

const StyledImg = styled.img`
  height: 64px !important;
  width: 64px;
  min-width: auto !important;
  border-radius: ${props => props.theme.shape.borderRadius}px;
`;

const StyledPageHeader = styled(PageHeader)`
  @media print {
    display: none;
  }
`;

const TeamHeader = () => {
  const params = useParams();
  const teamId = +params.teamId;

  const { error, loading, data } = useQuery(
    TEAM_HEADER_QUERY,
    {
      variables: { teamId },
    },
  );

  PageVar.useSet(
    'name', data?.yogurt.team?.name,
  );

  if (error || loading) {
    return <StyledPageHeader skeleton />;
  }

  const team = data?.yogurt.team;
  if (!team) {
    return <PageNoData />;
  }

  const teamImageUrl = getTeamImageUrl(team.team?.id ?? 0);

  return (
    <StyledPageHeader
      avatar={<StyledImg src={teamImageUrl} />}
      backgroundUrl={teamImageUrl}
      title={team.name}
      actions={<TeamActions info={team} />}
    />
  );
};

export default TeamHeader;
