import { gql } from 'apollo-boost';

export const TEAM_HEADER_QUERY = gql`
  query TeamHeader($teamId: Long!) {
    yogurt {
      team(matchReplayUploadTeamId: $teamId) {
        id
        name
        isDefault
        team {
          id
        }
      }
    }
  }
`;
