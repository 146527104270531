import React from 'react';
import PropTypes from 'prop-types';
import { matchType } from '../../../MatchRow/Draft/propTypes';
import MatchSeriesHeader from '../MatchSeriesHeader';
import Menu from './SeriesMenu';

const SeriesHeader = ({ series }) => (
  <MatchSeriesHeader
    series={series}
    menu={<Menu series={series} />}
  />
);

SeriesHeader.propTypes = {
  series: PropTypes.arrayOf(matchType),
};

export default SeriesHeader;
