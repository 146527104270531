import React from 'react';
import PropTypes from 'prop-types';
import DetailItem from '@hitagi/core/DetailItem';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { formatSeconds } from '@hitagi/utils';
import Box from '@hitagi/core/Box';
import { GameMode, GameModeName } from '@hitagi/dota/GameMode';
import styled, { css } from 'styled-components';
import messages from './messages';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1.25fr 0.75fr;
  grid-row-gap: ${props => props.theme.spacing(1)};
  grid-column-gap: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(1)};

  @media print {
    grid-template-columns: 1fr;
  }
`;

const StyledDetailItem = styled(DetailItem)`
  @media print {
    /* dt is label */
    dt {
      display: none;
    }

    ${props => props.dontPrint && css`
      display: none;
    `}
  }
`;

const MatchDetails = ({ startDate, duration, matchId, score, gameMode }) => (
  <Box>
    <StyledGrid>
      <StyledDetailItem
        label={<FormattedMessage {...messages.date} />}
        value={(
          <FormattedDate
            value={startDate}
            day="numeric"
            month="short"
            year="numeric"
          />
        )}
        variant="secondary"
      />
      <StyledDetailItem
        label={<FormattedMessage {...messages.duration} />}
        value={formatSeconds(duration)}
        variant="secondary"
      />
      <StyledDetailItem
        label={<FormattedMessage {...messages.matchId} />}
        value={
          matchId ? (
            <a
              href={`https://stratz.com/matches/${matchId}`}
              target="_blank"
              rel="noreferrer"
            >
              {matchId}
            </a>
          ) : (
            '-'
          )
        }
        variant="secondary"
        dontPrint
      />
      <StyledDetailItem
        label={<FormattedMessage {...messages.score} />}
        value={`${score[0]} - ${score[1]}`}
        variant="secondary"
      />
    </StyledGrid>
    <StyledDetailItem
      label="Game mode"
      value={<GameModeName id={gameMode} />}
      variant="secondary"
    />
  </Box>
);

MatchDetails.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  duration: PropTypes.number,
  matchId: PropTypes.number,
  score: PropTypes.arrayOf(PropTypes.number),
  gameMode: PropTypes.oneOf(GameMode.ids),
};

export default MatchDetails;
