import React from 'react';
import styled from 'styled-components';

export const getLeagueImageUrl = (id: number | 'unknown') => (
  `${process.env.REACT_APP_CDN_HOST}/images/dota2/leagues/${id}.png`
);

const StyledImg = styled.img`
  object-fit: contain;
`;

type LeagueImageProps = {
  id: number | 'unknown'
} & Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'srcSet' | 'sizes' | 'id' | 'onError'
>

const LeagueImage = ({ id, ...restProps }: LeagueImageProps) => (
  <StyledImg src={getLeagueImageUrl(id)} {...restProps} />
);

export default LeagueImage;
