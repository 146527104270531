import React from 'react';
import styled from 'styled-components';

export const getTeamImageUrl = (id: number | 'unknown') => (
  `${process.env.REACT_APP_CDN_HOST}/images/dota2/teams/${id}.png`
);

const StyledImg = styled.img`
  object-fit: contain;
`;

type TeamImageProps = {
  id?: number | 'unknown'
} & Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'srcSet' | 'sizes' | 'id'
>

const TeamImage = ({ id = 'unknown', ...restProps }: TeamImageProps) => (
  <StyledImg src={getTeamImageUrl(id)} {...restProps} />
);

export default TeamImage;
