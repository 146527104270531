import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popper, { usePopperState } from '@hitagi/core/Popper';
import Alert from '@hitagi/core/Alert';
import CheckmarkIcon from '@hitagi/icons/Checkmark';
import BugIcon from '@hitagi/icons/Bug';
import Spinner from '@hitagi/core/Spinner';
import Button from '@hitagi/core/Button';
import Material from '@hitagi/core/Material';
import { useMatchValidationMutation } from './queries';

const renderIcon = (state, hasError) => {
  if (hasError) {
    return <BugIcon />;
  }
  if (state.loading || (!state.error && state.called)) {
    return <Spinner />;
  }
  return <CheckmarkIcon />;
};

const ValidationHandle = ({ teamId, matchId }) => {
  const [validate, state] = useMatchValidationMutation({ teamId, matchId });

  const ref = useRef();
  const popper = usePopperState();
  const [didPoppedUpError, setDidPoppedUpError] = useState(false);

  useEffect(() => {
    if (state.error && !popper.anchorEl && !didPoppedUpError) {
      popper.onOpen({ currentTarget: ref.current });
      setDidPoppedUpError(true);
    }
  }, [state.error, popper, didPoppedUpError]);

  useEffect(() => {
    if (state.loading) {
      setDidPoppedUpError(false);
    }
  }, [state.loading]);

  const hasError = Boolean(state.error && didPoppedUpError && popper.anchorEl);

  return (
    <>
      <div ref={ref}>
        <Button
          isIconOnly
          // bg={hasError ? undefined : 'blue.glass'}
          bg="blue.glass"
          onClick={hasError ? popper.onOpen : validate}
        >
          {renderIcon(state, hasError)}
        </Button>
      </div>
      {state.error && (
        <Popper {...popper} placement="left-start">
          <Material padding={0} marginRight={1} maxWidth={420}>
            <Alert
              severity="error"
              title="Error"
              description={state.error.message}
            />
          </Material>
        </Popper>
      )}
    </>
  );
};

ValidationHandle.propTypes = {
  teamId: PropTypes.number,
  matchId: PropTypes.number,
};

export default ValidationHandle;
