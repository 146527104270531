/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import Button from '@hitagi/core/Button';
import StarIcon from '@hitagi/icons/Star';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Spinner from '@hitagi/core/Spinner';
import BugIcon from '@hitagi/icons/Bug';
import Popper, { usePopperState } from '@hitagi/core/Popper';
import Material from '@hitagi/core/Material';
import Alert from '@hitagi/core/Alert';
import { teamIntoType } from '../../propTypes';
import { useUser } from '../../../../utils/user';
import { GET_TEAMS } from '../../../../utils/team';
import { TEAM_HEADER_QUERY } from '../common';

const MAKE_TEAM_DEFAULT = gql`
  mutation MakeTeamDefault($userId: ID!, $teamId: Long!) {
    yogurt {
      setTeamMemberDefaultTeam(
        captainJackIdentityId: $userId
        matchReplayUploadTeamId: $teamId
      )
    }
  }
`;

const renderIcon = (shouldShowErr, loading) => {
  if (shouldShowErr) {
    return <BugIcon />;
  }
  if (loading) {
    return <Spinner />;
  }
  return <StarIcon />;
};

const renderError = (error, popper) => {
  if (!error) {
    return null;
  }
  return (
    <Popper {...popper} placement="bottom-start">
      <Material padding={0} marginTop={1} maxWidth={420}>
        <Alert
          severity="error"
          title="Error"
          description={error.message}
        />
      </Material>
    </Popper>
  );
};

const MakeDefaultAction = ({ info }) => {
  const ref = useRef();
  const [isHoveringOver, setIsHoveringOver] = useState(false);

  const user = useUser();

  const [makeDefault, { loading, error, data }] = useMutation(MAKE_TEAM_DEFAULT, {
    variables: {
      teamId: info.id,
      userId: user.data.profile.captainJackIdentityId,
    },
    refetchQueries: [
      {
        query: TEAM_HEADER_QUERY,
        variables: { teamId: info.id },
      },
      {
        query: GET_TEAMS,
      },
    ],
  });

  const popper = usePopperState();

  const [, setDidShowErr] = useState(false);

  useEffect(() => {
    if (loading) {
      setDidShowErr(false);
    }
  }, [loading]);

  useEffect(() => {
    setDidShowErr(prev => {
      if (error && !prev && !popper.anchorEl) {
        popper.onOpen({ currentTarget: ref.current });
        return true;
      }
      return prev;
    });
  }, [error, popper]);

  if (info.isDefault || (data && data.yogurt.setTeamMemberDefaultTeam)) {
    return null;
  }

  const onMouseEnter = () => setIsHoveringOver(true);
  const onMouseLeave = () => setIsHoveringOver(false);

  const shouldShowErr = Boolean(error && popper.anchorEl);

  return (
    <div ref={ref}>
      <Button
        bg={isHoveringOver ? 'blue.glass' : undefined}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={makeDefault}
        disabled={loading || shouldShowErr}
        startIcon={renderIcon(shouldShowErr, loading)}
      >
        {shouldShowErr ? 'Error' : 'Make Default'}
      </Button>
      {renderError(error, popper)}
    </div>
  );
};

MakeDefaultAction.propTypes = {
  info: teamIntoType,
};

export default MakeDefaultAction;
