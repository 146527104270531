import { Position } from '@hitagi/dota/Position';
import PropTypes from 'prop-types';

const kenDateType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

const proSteamAccountType = PropTypes.shape({
  name: PropTypes.string,
});

const steamAccountType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  proSteamAccount: proSteamAccountType,
});

const matchPlayerType = PropTypes.shape({
  isRadiant: PropTypes.bool,
  steamAccount: steamAccountType,
  kills: PropTypes.number,
  heroId: PropTypes.number,
  position: PropTypes.oneOf(Position.gqlEnums),
});

const matchTeamType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const matchStatsPickBanType = PropTypes.shape({
  isRadiant: PropTypes.bool,
  isPick: PropTypes.bool,
  order: PropTypes.number,
  heroId: PropTypes.number,
  bannedHeroId: PropTypes.number,
  wasBannedSuccessfully: PropTypes.bool,
});

const matchStatsType = PropTypes.shape({
  pickBans: PropTypes.arrayOf(matchStatsPickBanType),
});

export const matchType = PropTypes.shape({
  didRadiantWin: PropTypes.bool,
  startDateTime: kenDateType,
  durationSeconds: PropTypes.number,
  id: PropTypes.number.isRequired,
  stats: matchStatsType,
  players: PropTypes.arrayOf(matchPlayerType.isRequired).isRequired,
  radiantTeam: matchTeamType,
  direTeam: matchTeamType,
});

export type MatchType = PropTypes.InferType<typeof matchType>
export type MatchPlayerType = PropTypes.InferType<typeof matchPlayerType>
