import React from 'react';
import PropTypes from 'prop-types';
import Popper, { usePopperState } from '@hitagi/core/Popper';
import Backdrop from '@hitagi/core/Backdrop';
import Box from '@hitagi/core/Box';
import Spinner from '@hitagi/core/Spinner';
import PenIcon from '@hitagi/icons/Pen';
import BugIcon from '@hitagi/icons/Bug';
import Tooltip from '@hitagi/core/Tooltip';
import styled from 'styled-components';
import { Label as BaseLabel } from '../../../../../MatchRow/Draft/styled';
import MutableTeamNameContent from './MutableTeamNameContent';
import { useTeamNameMutation } from '../../queries';

const Label = styled(BaseLabel)`
  background-color: ${props => props.emphasize && props.theme.palette.red.main};
  cursor: pointer;
`;

const renderIcon = state => {
  if (state.error) {
    return <BugIcon height="0.9em" />;
  }
  if (state.loading) {
    return <Spinner height="0.9em" />;
  }
  return <PenIcon height="0.9em" />;
};

const MutableTeamName = ({
  children,
  matchId,
  isRadiant,
  dotaTeamId,
  teamId,
  ...restProps
}) => {
  const mutation = useTeamNameMutation();
  const [, mutationState] = mutation;

  const popper = usePopperState();
  const isOpen = Boolean(popper.anchorEl);

  return (
    <>
      <Box component="span" display="inline-flex" alignItems="center" zIndex={+isOpen} position="relative" {...restProps}>
        <Backdrop in={isOpen} />
        <Label emphasize={!children} onClick={popper.onOpen}>
          <Box compoennt="span" position="relative">
            <Tooltip content={dotaTeamId ? 'Edit Team' : 'Set Team'} placement="top">
              {children || <>&#8203;</>}
              <Box component="span" display="inline-flex" marginLeft={children && '0.6em'}>
                {renderIcon(mutationState)}
              </Box>
            </Tooltip>
          </Box>
        </Label>
      </Box>
      <Popper {...popper} placement="bottom">
        {isOpen && (
          <MutableTeamNameContent
            dotaTeamId={dotaTeamId}
            teamId={teamId}
            matchId={matchId}
            isRadiant={isRadiant}
            close={popper.onClose}
            mutation={mutation}
          />
        )}
      </Popper>
    </>
  );
};

MutableTeamName.propTypes = {
  children: PropTypes.node,
  dotaTeamId: PropTypes.number,
  teamId: PropTypes.number,
  matchId: PropTypes.number,
  isRadiant: PropTypes.bool,
};

export default MutableTeamName;
