import { numberArrayFromString, boolFromString, arrayToString, numberFromString, arrayFromString } from '@hitagi/utils';

export const initialState = {
  skip: 0,
  take: 100,
  isValidated: true,
  byTeamId: undefined,
  byLobbyType: undefined,
  byGameMode: undefined,
  byLeagueId: undefined,
  bySteamAccountId: undefined,
  withFriendHeroId: undefined,
  withEnemyHeroId: undefined,
  withFriendBannedHeroId: undefined,
  withEnemyBannedHeroId: undefined,
  byHeroId: undefined,
  filterPositionIsUs: undefined,
  filterPosition: undefined,
  filterPositionOrder: undefined,
  firstPick: undefined,
  isRadiant: undefined,
};

let e = 0;
/* eslint-disable no-multi-assign */
const SKIP = e += 1;
const TAKE = e += 1;
const IS_VALIDATED = e += 1;
const BY_TEAM_ID = e += 1;
const BY_LOBBY_TYPE = e += 1;
const BY_GAME_MODE = e += 1;
const BY_LEAGUE_ID = e += 1;
const BY_STEAM_ACCOUNT_IDS = e += 1;
const WITH_FRIEND_HERO_ID = e += 1;
const WITH_ENEMY_HERO_ID = e += 1;
const WITH_FRIEND_BANNED_HERO_ID = e += 1;
const WITH_ENEMY_BANNED_HERO_ID = e += 1;
const BY_HERO_ID = e += 1;
const FILTER_POSITION_IS_US = e += 1;
const FILTER_POSITION = e += 1;
const FILTER_POSITION_ORDER = e += 1;
const FIRST_PICK = e += 1;
const IS_RADIANT = e += 1;
/* eslint-enable no-multi-assign */

export const setTake = payload => ({ type: TAKE, payload });
export const setSkip = payload => ({ type: SKIP, payload });
export const setIsValidated = payload => ({ type: IS_VALIDATED, payload });
export const setByTeamId = payload => ({ type: BY_TEAM_ID, payload });
export const setByLobbyType = payload => ({ type: BY_LOBBY_TYPE, payload });
export const setByGameMode = payload => ({ type: BY_GAME_MODE, payload });
export const setByLeagueId = payload => ({ type: BY_LEAGUE_ID, payload });
export const setBySteamAccountId = payload => ({ type: BY_STEAM_ACCOUNT_IDS, payload });
export const setWithFriendHeroId = payload => ({ type: WITH_FRIEND_HERO_ID, payload });
export const setWithEnemyHeroId = payload => ({ type: WITH_ENEMY_HERO_ID, payload });
export const setWithFriendBannedHeroId = payload => ({ type: WITH_FRIEND_BANNED_HERO_ID, payload });
export const setWithEnemyBannedHeroId = payload => ({ type: WITH_ENEMY_BANNED_HERO_ID, payload });
export const setByHeroId = payload => ({ type: BY_HERO_ID, payload });
export const setFilterPositionIsUs = payload => ({ type: FILTER_POSITION_IS_US, payload });
export const setFilterPosition = payload => ({ type: FILTER_POSITION, payload });
export const setFilterPositionOrder = payload => ({ type: FILTER_POSITION_ORDER, payload });
export const setFirstPick = payload => ({ type: FIRST_PICK, payload });
export const setIsRadiant = payload => ({ type: IS_RADIANT, payload });

export const reducer = (state, action) => {
  switch (action.type) {
  case SKIP:
    return { ...state, skip: action.payload };
  case TAKE:
    return { ...state, take: action.payload };
  case IS_VALIDATED:
    return { ...state, isValidated: action.payload };
  case BY_TEAM_ID:
    return { ...state, byTeamId: action.payload };
  case BY_LOBBY_TYPE:
    return { ...state, byLobbyType: action.payload };
  case BY_GAME_MODE:
    return { ...state, byGameMode: action.payload };
  case BY_LEAGUE_ID:
    return { ...state, byLeagueId: action.payload };
  case BY_STEAM_ACCOUNT_IDS:
    return { ...state, bySteamAccountId: action.payload };
  case WITH_FRIEND_HERO_ID:
    return { ...state, withFriendHeroId: action.payload };
  case WITH_ENEMY_HERO_ID:
    return { ...state, withEnemyHeroId: action.payload };
  case WITH_FRIEND_BANNED_HERO_ID:
    return { ...state, withFriendBannedHeroId: action.payload };
  case WITH_ENEMY_BANNED_HERO_ID:
    return { ...state, withEnemyBannedHeroId: action.payload };
  case BY_HERO_ID:
    return { ...state, byHeroId: action.payload };
  case FILTER_POSITION_IS_US:
    return { ...state, filterPositionIsUs: action.payload };
  case FILTER_POSITION:
    return { ...state, filterPosition: action.payload };
  case FILTER_POSITION_ORDER:
    return { ...state, filterPositionOrder: action.payload };
  case FIRST_PICK:
    return { ...state, firstPick: action.payload };
  case IS_RADIANT:
    return { ...state, isRadiant: action.payload };
  default:
    return state;
  }
};

export const stringifiers = {
  skip: String,
  take: String,
  isValidated: String,
  byTeamId: String,
  byLobbyType: arrayToString,
  byGameMode: arrayToString,
  byLeagueId: String,
  bySteamAccountId: String,
  withFriendHeroId: arrayToString,
  withEnemyHeroId: arrayToString,
  withFriendBannedHeroId: arrayToString,
  withEnemyBannedHeroId: arrayToString,
  byHeroId: arrayToString,
  filterPositionIsUs: String,
  filterPosition: String,
  filterPositionOrder: arrayToString,
  firstPick: String,
  isRadiant: String,
};

export const parsers = {
  skip: numberFromString,
  take: numberFromString,
  isValidated: boolFromString,
  byTeamId: numberFromString,
  byLobbyType: numberArrayFromString,
  byGameMode: numberArrayFromString,
  byLeagueId: numberFromString,
  bySteamAccountId: numberFromString,
  withFriendHeroId: numberArrayFromString,
  withEnemyHeroId: numberArrayFromString,
  withFriendBannedHeroId: numberArrayFromString,
  withEnemyBannedHeroId: numberArrayFromString,
  byHeroId: numberArrayFromString,
  filterPositionIsUs: boolFromString,
  filterPosition: String,
  filterPositionOrder: arrayFromString,
  firstPick: boolFromString,
  isRadiant: boolFromString,
};
