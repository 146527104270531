export const MAX_ROWS = 10;

export const HERO_DUOS_HERO_FRAGMENT = `
  fragment MatchReplayUploadHeroSummaryTypeFragment on MatchReplayUploadHeroSummaryType {
    heroId
    matchCountWith
    winCountWith
    matchCountAgainst
    winCountAgainst
    duos {
      heroId
      matchCountWith
      winCountWith
      matchCountAgainst
      winCountAgainst
    }
  }
`;

export type MatchReplayUploadHeroSummaryTypeFragment = {
  heroId: number
  matchCountWith: number
  winCountWith: number
  matchCountAgainst: number
  winCountAgainst: number
  duos: Array<{
    heroId: number
    matchCountWith: number
    winCountWith: number
    matchCountAgainst: number
    winCountAgainst: number
  }>
}

export type ComputedDuo = MatchReplayUploadHeroSummaryTypeFragment['duos'][0] & {
  winRateWith: number
  wilsonScoreWith: number
  winRateAgainst: number
  wilsonScoreAgainst: number
}

export type ComputedHero = Omit<MatchReplayUploadHeroSummaryTypeFragment, 'duos'> & {
  duos: ComputedDuo[]
  duoMaxWilsonScoreWith: number
  duoMinWilsonScoreWith: number
  duoMaxWilsonScoreAgainst: number
  duoMinWilsonScoreAgainst: number
}
