import styled from 'styled-components';
import Container from '@hitagi/core/Layout/Container';

const PageModifiersContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => props.marginTop === undefined && props.theme.spacing(3)};
  margin-bottom: ${props => props.marginBottom === undefined && props.theme.spacing(1)};

  > * {
    margin-right: ${props => props.theme.spacing(2)};
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

export default PageModifiersContainer;
