import SelectMenu from '@hitagi/core/SelectMenu';
import LabelButton from '@hitagi/core/LabelButton';
import { ComponentProps } from '@hitagi/utils/types';
import React from 'react';
import Divider from '@hitagi/core/Divider';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import { boolFromString } from '@hitagi/utils';
import CheckmarkIcon from '@hitagi/icons/Checkmark';
import CrossmarkIcon from '@hitagi/icons/Crossmark';
import ListItemElement from '@hitagi/core/ListItemElement';
import SvgIcon from '@hitagi/icons/SvgIcon';
import ListItemText from '@hitagi/core/ListItemText';

type FirstPickPageModifierProps = {
  firstPick: boolean | undefined;
  setFirstPick: (firstPick: boolean | undefined) => void;
} & ComponentProps<typeof SelectMenu>;

const FirstPickPageModifier = (props: FirstPickPageModifierProps) => {
  const { firstPick, setFirstPick, ...restProps } = props;

  const handleClick = (ev: React.MouseEvent<HTMLLIElement>) => {
    const { value } = ev.currentTarget.dataset;
    setFirstPick(value === undefined ? undefined : boolFromString(value));
  };

  return (
    <SelectMenu {...restProps}>
      <summary>
        <LabelButton>
          {firstPick !== undefined && (
            <LabelButton.Graphic size={LabelButton.Graphic.size.medium}>
              {firstPick === true && <CheckmarkIcon />}
              {firstPick === false && <CrossmarkIcon />}
            </LabelButton.Graphic>
          )}
          <LabelButton.Text>First pick</LabelButton.Text>
          <Divider vertical m={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.List>
          <SelectMenu.Item
            selected={firstPick === undefined}
            onClick={handleClick}
          >
            <ListItemElement>
              <SvgIcon />
            </ListItemElement>
            <ListItemText primary="Any" />
          </SelectMenu.Item>
          <SelectMenu.Item
            selected={firstPick === true}
            data-value
            onClick={handleClick}
          >
            <ListItemElement>
              <CheckmarkIcon />
            </ListItemElement>
            <ListItemText primary="Yes" />
          </SelectMenu.Item>
          <SelectMenu.Item
            selected={firstPick === false}
            data-value={false}
            onClick={handleClick}
          >
            <ListItemElement>
              <CrossmarkIcon />
            </ListItemElement>
            <ListItemText primary="No" />
          </SelectMenu.Item>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default FirstPickPageModifier;
