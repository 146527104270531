/* eslint-disable no-use-before-define */
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { GET_TEAM_MATCHES } from '../../queries';

const matchesInput = {
  take: 100,
  skip: 0,
};

export const LINK_SERIES_ID = gql`
  mutation LinkSeriesId($teamId: Long!, $matchIds: [Long]!) {
    yogurt {
      linkSeriesId(matchReplayUploadTeamId: $teamId, matchIds: $matchIds)
    }
  }
`;
export const useLinkMutation = () => {
  const params = useParams();
  const teamId = +params.teamId || 0;

  return useMutation(LINK_SERIES_ID, {
    refetchQueries: [
      {
        query: GET_TEAM_MATCHES,
        variables: {
          teamId,
          input: matchesInput,
        },
      },
    ],
    variables: { teamId },
  });
};

export const UNLINK_SERIES_ID = gql`
  mutation UnlinkSeriesId($teamId: Long!, $matchIds: [Long]!) {
    yogurt {
      removeSeriesId(matchReplayUploadTeamId: $teamId, matchIds: $matchIds)
    }
  }
`;
export const useUnlinkMutation = variables => {
  const params = useParams();
  const teamId = +params.teamId || 0;

  return useMutation(UNLINK_SERIES_ID, {
    refetchQueries: [
      {
        query: GET_TEAM_MATCHES,
        variables: {
          teamId,
          input: matchesInput,
        },
      },
    ],
    variables: { teamId, ...variables },
  });
};

export const UPDATE_TEAM_NAME = gql`
  mutation UpdateTeamName($input: UpdateMatchReplayUploadObjectType!) {
    yogurt {
      update(updateMatchReplayUploadObject: $input)
    }
  }
`;
export const useTeamNameMutation = () => {
  const params = useParams();
  const teamId = +params.teamId || 0;

  return useMutation(UPDATE_TEAM_NAME, {
    refetchQueries: [
      {
        query: GET_TEAM_MATCHES,
        variables: {
          teamId,
          input: matchesInput,
        },
      },
    ],
  });
};

export const VALIDATE_MATCH = gql`
  mutation ValidateMatch($teamId: Long!, $matchId: Long!) {
    yogurt {
      validate(matchReplayUploadTeamId: $teamId, matchId: $matchId)
    }
  }
`;
export const useMatchValidationMutation = variables => useMutation(VALIDATE_MATCH, {
  variables,
  refetchQueries: [
    {
      query: GET_TEAM_MATCHES,
      variables: {
        teamId: variables.teamId,
        input: matchesInput,
      },
    },
  ],
});
