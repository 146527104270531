import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@hitagi/core/Box';
import Material from '@hitagi/core/Material';
import UpIcon from '@hitagi/icons/ChevronUp';
import DownIcon from '@hitagi/icons/ChevronDown';
import Text from '@hitagi/core/Text';
import { DotaContext } from '@hitagi/dota/Context';
import { getBanRate } from '../Matches/Drafts/DraftsHeroSummary';
import Row from './TableRow';
import Stat from './TableStat';
import { Header } from './styled';
import { sortByName } from './helpers';

const renderHero = (hero, matchCount) => {
  const {
    heroId,
    banCountWith,
    banCountAgainst,
  } = hero;

  const banRateUs = getBanRate(matchCount, banCountWith) || 0;
  const banRateOpponent = getBanRate(matchCount, banCountAgainst) || 0;

  return (
    <Row key={heroId} heroId={heroId}>
      <Stat
        stat={banCountWith}
        percent={banRateUs}
      />
      <Stat
        stat={banCountAgainst}
        percent={banRateOpponent}
      />
    </Row>
  );
};

const ascUs = (heroA, heroB) => heroA.banCountWith - heroB.banCountWith;
const descUs = (heroA, heroB) => heroB.banCountWith - heroA.banCountWith;
const ascOpponent = (heroA, heroB) => heroA.banCountAgainst - heroB.banCountAgainst;
const descOpponent = (heroA, heroB) => heroB.banCountAgainst - heroA.banCountAgainst;

const icons = {
  asc: <UpIcon height="0.8em" />,
  desc: <DownIcon height="0.8em" />,
};

const TableBan = ({ heroes: heroesProp, matchCount, ...restProps }) => {
  const heroes = useMemo(() => heroesProp.slice().sort(descUs), [heroesProp]);

  const [sortName, setSortName] = useState('');
  const [sortUs, setSortUs] = useState('desc');
  const [sortOpponent, setSortOpponent] = useState('');
  const dota = useContext(DotaContext);

  const onClickUs = () => {
    sortByName(heroes, dota);
    setSortName('');
    setSortOpponent('');
    setSortUs(prev => {
      if (prev === '') {
        heroes.sort(descUs);
        return 'desc';
      }
      if (prev === 'desc') {
        heroes.sort(ascUs);
        return 'asc';
      }
      if (prev === 'asc') {
        heroes.sort(descUs);
        return 'desc';
      }
      return '';
    });
  };

  const onClickOpponent = () => {
    sortByName(heroes, dota);
    setSortName('');
    setSortUs('');
    setSortOpponent(prev => {
      if (prev === '') {
        heroes.sort(descOpponent);
        return 'desc';
      }
      if (prev === 'desc') {
        heroes.sort(ascOpponent);
        return 'asc';
      }
      if (prev === 'asc') {
        heroes.sort(descOpponent);
        return 'desc';
      }
      return '';
    });
  };

  const onClickTitle = () => {
    setSortUs('');
    setSortOpponent('');
    setSortName(prev => {
      if (prev === '') {
        sortByName(heroes, dota);
        return 'desc';
      }
      if (prev === 'desc') {
        sortByName(heroes, dota, 'asc');
        return 'asc';
      }
      if (prev === 'asc') {
        sortByName(heroes, dota);
        return 'desc';
      }
      return '';
    });
  };

  return (
    <Box width="100%" marginRight={1} {...restProps}>
      <Header>
        <Box as="span" onClick={onClickTitle}>
          <Text variant="head2" as="span">Ban</Text> {icons[sortName]}
        </Box>
        <Box flexGrow={1} />
        <Box width="9ch" as="span" onClick={onClickUs}>
          Us {icons[sortUs]}
        </Box>
        <Box width="12ch" as="span" onClick={onClickOpponent}>
          Opponent {icons[sortOpponent]}
        </Box>
      </Header>
      <Material padding={0} component="ul">
        {heroes.map(hero => renderHero(hero, matchCount))}
      </Material>
    </Box>
  );
};

TableBan.propTypes = {
  matchCount: PropTypes.number,
  heroes: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

export default TableBan;
