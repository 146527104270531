import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'fast-memoize';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import { DataImage } from '@hitagi/core/DataImage';
import { HeroImage } from '@hitagi/dota/Hero';
import DetailItem from '@hitagi/core/DetailItem';
import Container from '@hitagi/core/Layout/Container';
import { get } from '@hitagi/utils';
import { FormattedNumber } from 'react-intl';
import SummaryCard from './SummaryCard';

// TODO: move to utils in hitagi (not dota utils, applicable to probably all games)
export const getWinRate = (matchCount, winCount) => winCount / matchCount;
// TODO: move to utils in hitagi (not dota utils, applicable to probably all games)
export const getPickRate = (matchCount, pickCount) => pickCount / matchCount;
// TODO: move to utils in hitagi (not dota utils, applicable to probably all games)
export const getBanRate = (matchCount, banCount) => banCount / matchCount;

const renderDetailItem = (hero, labelValue, valueText) => {
  const avatar = (
    <DataImage width={32} height={32}>
      <HeroImage id={hero.heroId} variant="icon" />
    </DataImage>
  );
  return (
    <DetailItem
      key={hero.heroId}
      avatar={avatar}
      label={<FormattedNumber value={labelValue} style="percent" />}
      value={valueText}
      variant="secondary"
    />
  );
};

const getBestWorstWithByScore = heroes => {
  const atLeastOneMatch = heroes
    .filter(h => h.matchCountWith > 0)
    .sort((a, b) => b.withScore - a.withScore);
  return {
    best: atLeastOneMatch.slice(0, 3),
    worst: atLeastOneMatch.slice(atLeastOneMatch.length - 3, atLeastOneMatch.length),
  };
};
const memoizedBestWorstWithByScore = memoize(getBestWorstWithByScore);
const renderBestWith = heroes => {
  const { best } = memoizedBestWorstWithByScore(heroes);
  return (
    <SummaryCard title="Best With" subtitle="Winrate / Match Count">
      {best.map(hero => renderDetailItem(
        hero,
        getWinRate(hero.matchCountWith, hero.winCountWith),
        hero.matchCountWith,
      ))}
    </SummaryCard>
  );
};

const renderWorstWith = heroes => {
  const { worst } = memoizedBestWorstWithByScore(heroes);
  return (
    <SummaryCard title="Worst With" marginTop={1}>
      {worst.map(hero => renderDetailItem(
        hero,
        getWinRate(hero.matchCountWith, hero.winCountWith),
        hero.matchCountWith,
      ))}
    </SummaryCard>
  );
};

const getBestWorstAgainstByScore = heroes => {
  const atLeastOneMatch = heroes
    .filter(h => h.matchCountAgainst > 0)
    .sort((a, b) => b.againstScore - a.againstScore);
  return {
    best: atLeastOneMatch.slice(0, 3),
    worst: atLeastOneMatch.slice(atLeastOneMatch.length - 3, atLeastOneMatch.length),
  };
};
const memoizedBestWorstAgainstByScore = memoize(getBestWorstAgainstByScore);
const renderBestAgainst = heroes => {
  const { best } = memoizedBestWorstAgainstByScore(heroes);
  return (
    <SummaryCard title="Best Against" subtitle="Winrate / Match Count">
      {best.map(hero => renderDetailItem(
        hero,
        getWinRate(hero.matchCountAgainst, hero.winCountAgainst),
        hero.matchCountAgainst,
      ))}
    </SummaryCard>
  );
};

const renderWorstAgainst = heroes => {
  const { worst } = memoizedBestWorstAgainstByScore(heroes);
  return (
    <SummaryCard title="Worst Against" marginTop={1}>
      {worst.map(hero => renderDetailItem(
        hero,
        getWinRate(hero.matchCountAgainst, hero.winCountAgainst),
        hero.matchCountAgainst,
      ))}
    </SummaryCard>
  );
};

const getAllyOpponentPick = heroes => {
  const ally = heroes
    .slice()
    .sort((a, b) => b.matchCountWith - a.matchCountWith)
    .slice(0, 3);
  const opponent = heroes
    .slice()
    .sort((a, b) => b.matchCountAgainst - a.matchCountAgainst)
    .slice(0, 3);
  return {
    ally: ally.slice(0, 3),
    opponent: opponent.slice(0, 3),
  };
};
const memoizedAllyOpponentPick = memoize(getAllyOpponentPick);
const renderAllyPick = (heroes, totalMatchCount) => {
  const { ally } = memoizedAllyOpponentPick(heroes);
  return (
    <SummaryCard title="Ally Pick" subtitle="Pick Rate / Match Count">
      {ally.map(hero => renderDetailItem(
        hero,
        getPickRate(totalMatchCount, hero.matchCountWith),
        hero.matchCountWith,
      ))}
    </SummaryCard>
  );
};

const renderOpponentPick = (heroes, totalMatchCount) => {
  const { opponent } = memoizedAllyOpponentPick(heroes);
  return (
    <SummaryCard title="Opponent Pick" marginTop={1}>
      {opponent.map(hero => renderDetailItem(
        hero,
        getPickRate(totalMatchCount, hero.matchCountAgainst),
        hero.matchCountAgainst,
      ))}
    </SummaryCard>
  );
};

const getAllyOpponentBan = heroes => {
  const ally = heroes
    .slice()
    .sort((a, b) => b.banCountWith - a.banCountWith)
    .slice(0, 3);
  const opponent = heroes
    .slice()
    .sort((a, b) => b.banCountAgainst - a.banCountAgainst)
    .slice(0, 3);
  return {
    ally: ally.slice(0, 3),
    opponent: opponent.slice(0, 3),
  };
};

const memoizedAllyOpponentBan = memoize(getAllyOpponentBan);
const renderAllyBan = (heroes, totalMatchCount) => {
  const { ally } = memoizedAllyOpponentBan(heroes);
  return (
    <SummaryCard title="Ally Ban" subtitle="Ban Rate / Match Count">
      {ally.map(hero => renderDetailItem(
        hero,
        getBanRate(totalMatchCount, hero.banCountWith),
        hero.banCountWith,
      ))}
    </SummaryCard>
  );
};

const renderOpponentBan = (heroes, totalMatchCount) => {
  const { opponent } = memoizedAllyOpponentBan(heroes);
  return (
    <SummaryCard title="Opponent Ban" marginTop={1}>
      {opponent.map(hero => renderDetailItem(
        hero,
        getBanRate(totalMatchCount, hero.banCountAgainst),
        hero.banCountAgainst,
      ))}
    </SummaryCard>
  );
};

const DraftsHeroSummary = ({ heroes, info }) => {
  const totalMatchCount = get(['overview', 'matchCount'], info);
  return (
    <Container marginTop={3}>
      <Text variant="head2">
        Hero Summary
      </Text>
      <Box row mt={1}>
        <Box col width="100%" marginRight={1}>
          {renderBestWith(heroes)}
          {renderWorstWith(heroes)}
        </Box>
        <Box col width="100%" marginRight={1}>
          {renderBestAgainst(heroes)}
          {renderWorstAgainst(heroes)}
        </Box>
        <Box col width="100%" marginRight={1}>
          {renderAllyPick(heroes, totalMatchCount)}
          {renderOpponentPick(heroes, totalMatchCount)}
        </Box>
        <Box col width="100%">
          {renderAllyBan(heroes, totalMatchCount)}
          {renderOpponentBan(heroes, totalMatchCount)}
        </Box>
      </Box>
    </Container>
  );
};

DraftsHeroSummary.propTypes = {
  heroes: PropTypes.arrayOf(PropTypes.shape()),
  info: PropTypes.shape({}),
};

export default DraftsHeroSummary;
