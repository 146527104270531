import React from 'react';
import { BoxProps } from '@hitagi/core/Box';
import Alert from '@hitagi/core/Alert';
import Container from '@hitagi/core/Layout/Container';
import ExclamationCircleIcon from '@hitagi/icons/ExclamationCircle';

type PageNoDataProps = {
  disablePadding?: boolean
  disableMargin?: boolean
  // message is usuallly something in form of `No guides found.`.
  message?: React.ReactNode
} & BoxProps

const PageNoData = (props: PageNoDataProps) => {
  const { message, disablePadding, disableMargin, ...restProps } = props;
  if (disablePadding) (restProps as BoxProps).padding = '0 !important';

  return (
    <Container marginY={disableMargin ? undefined : 3} {...restProps}>
      {/* @ts-expect-error TODO: it wants `nonce` prop; nani? */}
      <Alert
        title="No Data"
        description={message}
        icon={<ExclamationCircleIcon />}
      />
    </Container>
  );
};

export default PageNoData;
