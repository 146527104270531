import React from 'react';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import Button from '@hitagi/core/Button';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../utils/user';
import ActionHeader from '../ActionHeader';
import PageSpinner from '../PageSpinner';

const GetStarted = () => {
  const user = useUser();

  if (user.loading) {
    return <PageSpinner />;
  }

  if (user.isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <Box col alignItems="center" p={3} m="auto">
      <ActionHeader
        emoji="👋"
        ariaLabel="wave"
        title="Welcome!"
      />
      <Box col alignItems="center">
        <Text>
          To get started, log in with your steam account
        </Text>
        <Button mt={2} onClick={user.login}>
          Log In
        </Button>
      </Box>
    </Box>
  );
};

export default GetStarted;
