import React from 'react';
import { UserMenu, UserMenuView } from '@hitagi/user-menu';
import CogIcon from '@hitagi/icons/Cog';
import Menu, { MenuDisclosure, useMenuState } from '@hitagi/core/Menu';
import styled from 'styled-components';
import HeaderUserMenuAccountItem from './HeaderUserMenuAccountItem';
import HeaderUserMenuThemeItem from './HeaderUserMenuThemeItem';

const NonPrintableMenuDisclosure = styled(MenuDisclosure)`
  @media print {
    display: none;
  }
`;

const DesktopHeaderUserMenu = props => {
  const menu = useMenuState();

  return (
    <>
      <NonPrintableMenuDisclosure isIconOnly disableOutline disableBg {...menu} {...props}>
        <CogIcon />
      </NonPrintableMenuDisclosure>
      <Menu placement="bottom-end" {...menu}>
        <UserMenu viewId="main">
          <UserMenuView id="main">
            <HeaderUserMenuThemeItem />
            <HeaderUserMenuAccountItem />
          </UserMenuView>
        </UserMenu>
      </Menu>
    </>
  );
};

export default DesktopHeaderUserMenu;
