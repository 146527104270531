import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@hitagi/core/Alert';

export default class MatchRowErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/* error */) {
    return { hasError: true };
  }

  componentDidCatch(/* error, errorInfo */) {
    // TODO: log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return <Alert severity="error" message="Error" description="Something went wrong." />;
    }

    return this.props.children;
  }
}

MatchRowErrorBoundary.propTypes = {
  children: PropTypes.node,
};
