import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Text from '@hitagi/core/Text';

const Wrapper = styled.div`
  margin: 0 auto;
  border: 2px dashed ${props => props.theme.palette.text.disabled};
  padding: ${props => props.theme.spacing(4)};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  text-align: center;
  background-color: ${props => props.isDragActive && props.theme.palette.background.dim};
`;

const UploadDropzone = ({ onFiles }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onFiles });

  return (
    <Wrapper {...getRootProps()} isDragActive={isDragActive}>
      <input {...getInputProps()} />
      <Text variant="subdisplay">
        {isDragActive
          ? 'drop the files here ...'
          : 'drag & drop replays here, or click to select'}
      </Text>
    </Wrapper>
  );
};

UploadDropzone.propTypes = {
  onFiles: PropTypes.func,
};

export default UploadDropzone;
