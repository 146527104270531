import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Header = styled.div`
  align-items: flex-end;
  display: flex;
  padding: ${props => props.theme.spacing(1)};
  text-transform: uppercase;

  span {
    cursor: pointer;
  }
`;
