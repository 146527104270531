import Button from '@hitagi/core/Button';
import SelectMenu from '@hitagi/core/SelectMenu';
import React, { useState } from 'react';
import styled from 'styled-components';
import EllipsisVIcon from '@hitagi/icons/EllipsisV';
import ListItemText from '@hitagi/core/ListItemText';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Spinner from '@hitagi/core/Spinner';
import { useDispatch } from 'react-redux';
import { addNotification } from '@hitagi/redux/actions/notificationStack';
import { Position } from '@hitagi/dota/Position';
import Draft from '../../../MatchRow/Draft';
import { MatchPlayerType, MatchType } from '../../../MatchRow/Draft/propTypes';
import ModifyPositionModal from './ModifyPositionModal';

const INVALIDATE_MATCH_MUTATION = gql`
  mutation InvalidateMatch($yogurtTeamId: Long!, $matchId: Long!) {
    yogurt {
      invalidate(matchReplayUploadTeamId: $yogurtTeamId, matchId: $matchId)
    }
  }
`;

type InvalidateMatchMutation = {
  yogurt: {
    invalidate: boolean;
  };
};

type InvalidateMatchMutationVariables = {
  yogurtTeamId: number;
  matchId: number;
};

const UPDATE_MATCH_MUTATION = gql`
  mutation UpdatePlayerPosition($input: UpdateMatchReplayUploadObjectType!) {
    yogurt {
      update(updateMatchReplayUploadObject: $input)
    }
  }
`;

type UpdateMatchMutation = {
  yogurt: {
    update: boolean;
  };
};

type UpdateMatchMutationVariables = {
  input: {
    matchReplayUploadTeamId: number;
    matchId: number;
    players: Array<{
      steamAccountId: number;
      position: Position.Enum;
    }>;
  };
};

const MenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  padding-left: ${(props) => props.theme.spacing(1)};

  &:not(.visible) {
    ${Button.sc} {
      visibility: hidden;
    }
  }

  &:hover {
    ${Button.sc} {
      visibility: visible;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;

  &:hover {
    ${MenuWrapper} ${Button.sc} {
      visibility: visible;
    }
  }
`;

type DraftMatchRowContainerProps = {
  yogurtTeamId: number;
  match: NonNullable<MatchType>
  isPrimaryTeamRadiant: boolean;
};

const DraftMatchRowContainer = (props: DraftMatchRowContainerProps) => {
  const { yogurtTeamId, match, isPrimaryTeamRadiant } = props;

  const dispatch = useDispatch();

  const [invalidateMatch, invalidateMatchResult] = useMutation<
    InvalidateMatchMutation,
    InvalidateMatchMutationVariables
  >(INVALIDATE_MATCH_MUTATION, {
    onError: (error) => {
      dispatch(
        addNotification({
          severity: 'error',
          variant: 'filled',
          title: 'Error',
          description: `Could not invalidate match ${match.id}: ${error.message}`,
        }),
      );
    },
    onCompleted: () => {
      dispatch(
        addNotification({
          severity: 'info',
          variant: 'filled',
          title: 'Info',
          description: `Match ${match.id} has been removed from the dataset and returned to the Review tab`,
        }),
      );
    },
  });

  const handleInvalidateMatchClick = () => {
    invalidateMatch({
      variables: { yogurtTeamId, matchId: match.id },
      update: (proxy) => {
        proxy.writeFragment({
          id: `MatchReplayUploadMatchType:${match.id}`,
          fragment: gql`
            fragment MatchInvalidationFragment on MatchReplayUploadMatchType {
              invalidated
            }
          `,
          data: {
            invalidated: true,
            __typename: 'MatchReplayUploadMatchType',
          },
        });
      },
    });
  };

  const [updateMatch, updateMatchResult] = useMutation<
    UpdateMatchMutation,
    UpdateMatchMutationVariables
  >(UPDATE_MATCH_MUTATION, {
    onError: (error) => {
      dispatch(
        addNotification({
          severity: 'error',
          variant: 'filled',
          title: 'Error',
          description: `Could not update position: ${error.message}`,
        }),
      );
    },
  });

  const [changePositionPlayer, setChangePositionPlayer] = useState<
    MatchPlayerType | undefined
  >(undefined);
  const handleModifyPositionModalClose = () => {
    setChangePositionPlayer(undefined);
  };
  const handlePositionClick = (_: unknown, steamAccountId: number) => {
    const player = match.players.find(p => p.steamAccount?.id === steamAccountId);
    setChangePositionPlayer(player);
  };
  const handlePositionChange = (newPosition: Position.Enum) => {
    updateMatch({
      variables: {
        input: {
          matchReplayUploadTeamId: yogurtTeamId,
          matchId: match.id,
          players: [{
            steamAccountId: changePositionPlayer!.steamAccount!.id,
            position: newPosition,
          }],
        },
      },
      update: (proxy) => {
        proxy.writeFragment({
          id: `MatchReplayUploadMatchType:${match.id}`,
          fragment: gql`
            fragment MatchInvalidationFragment on MatchReplayUploadMatchType {
              invalidated
            }
          `,
          data: {
            invalidated: true,
            __typename: 'MatchReplayUploadMatchType',
          },
        });
      },
    });
  };

  const shouldSpin = (invalidateMatchResult.called && !invalidateMatchResult.error)
    || (updateMatchResult.called && !updateMatchResult.error);

  return (
    <Wrapper>
      <Draft
        match={match}
        isPrimaryTeamRadiant={isPrimaryTeamRadiant}
        onPositionClick={handlePositionClick}
      />

      <MenuWrapper className={shouldSpin ? 'visible' : undefined}>
        <SelectMenu>
          <Button isIconOnly disableOutline disableBg as="summary">
            {shouldSpin ? <Spinner /> : <EllipsisVIcon />}
          </Button>
          <SelectMenu.Modal align="right">
            <SelectMenu.List>
              <SelectMenu.Item onClick={handleInvalidateMatchClick}>
                <ListItemText primary="Invalidate" />
              </SelectMenu.Item>
            </SelectMenu.List>
          </SelectMenu.Modal>
        </SelectMenu>
      </MenuWrapper>

      {changePositionPlayer && (
        <ModifyPositionModal
          player={changePositionPlayer}
          onClose={handleModifyPositionModalClose}
          onChange={handlePositionChange}
        />
      )}
    </Wrapper>
  );
};

export default DraftMatchRowContainer;
