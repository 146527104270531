import React from 'react';
import { AppBarLogo } from '@hitagi/page/AppBar';
import StratzBrandIcon from '@hitagi/icons/StratzBrand';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useUser } from '../../utils/user';

const NonPrintableStratzBrandIcon = styled(StratzBrandIcon)`
  @media print {
    display: none;
  }
`;

const PrintableLogo = styled.img.attrs({ src: '/printableLogo.svg' })`
  height: 40px;
  display: none;

  @media print {
    display: block;
  }
`;

const HeaderLogo = () => {
  const user = useUser();

  return (
    <AppBarLogo as={Link} to={user.isLoggedIn ? '/dashboard' : '/'}>
      <NonPrintableStratzBrandIcon height={34} variant="auto" />
      <PrintableLogo />
    </AppBarLogo>
  );
};

export default HeaderLogo;
