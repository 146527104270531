import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import Divider from '@hitagi/core/Divider';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import LabelButton from '@hitagi/core/LabelButton';
import Phantom from '@hitagi/core/Phantom';
import SelectMenu from '@hitagi/core/SelectMenu';
import SvgIcon from '@hitagi/icons/SvgIcon';
import messages from './messages';
import TeamImage from '../TeamImage';

type Team = {
  id: number
  name: string
}

const byName = (a: Team, b: Team) => {
  if (!a.name || !b.name) return 0;
  return a.name.localeCompare(b.name);
};

type TeamPageModifierProps = {
  teamId?: number;
  setTeamId: (teamId?: number) => void;
  unlistUnion?: boolean
  teams: (Team | null)[] // In old leagues the team can be null
};

const TeamPageModifier = (props: TeamPageModifierProps) => {
  const {
    teamId,
    setTeamId,
    teams: teamsProp,
    unlistUnion,
    ...restProps
  } = props;

  const teams = useMemo(() => {
    const filteredTeams = teamsProp.filter(Boolean) as Team[];
    return filteredTeams.sort(byName);
  }, [teamsProp]);

  const intl = useIntl();

  const [search, setSearch] = useState('');
  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };
  const handleSelection = (event: React.MouseEvent<HTMLLIElement>) => {
    const { value } = event.currentTarget.dataset;
    const normalizedValue = value === undefined ? undefined : +value;
    setTeamId(normalizedValue);
    setSearch('');
  };

  const normalizedSearch = search.toLowerCase();
  const renderItem = (team: Team) => {
    const string = team.name.toLowerCase();
    if (!string?.includes(normalizedSearch)) return null;
    return (
      <SelectMenu.Item
        key={team.id}
        selected={team.id === teamId}
        data-value={team.id}
        onClick={handleSelection}
      >
        <ListItemElement maxWidth={24} maxHeight={24}>
          <TeamImage id={team.id} width={24} height={24} />
        </ListItemElement>
        <ListItemText primary={team.name} />
      </SelectMenu.Item>
    );
  };

  const unionSelected = teamId === undefined;
  const team = teamId === undefined ? undefined : teams.find(t => t.id === teamId);

  return (
    <SelectMenu {...restProps}>
      <summary>
        <LabelButton>
          {!unionSelected && (
            <LabelButton.Graphic size={LabelButton.Graphic.size.large}>
              <TeamImage id={teamId} />
            </LabelButton.Graphic>
          )}
          <LabelButton.Text>
            {unionSelected
              ? intl.formatMessage(messages.allTeams)
              : team?.name ?? teamId}
          </LabelButton.Text>
          <Divider vertical margin={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.Filter
          placeholder={intl.formatMessage(messages.filterTeams)}
          value={search}
          onChange={handleSearch}
        />
        <SelectMenu.List>
          {!unlistUnion && !search && (
            <SelectMenu.Item
              selected={unionSelected}
              onClick={handleSelection}
            >
              <ListItemElement>
                <SvgIcon />
              </ListItemElement>
              <ListItemText primary={intl.formatMessage(messages.allTeams)} />
            </SelectMenu.Item>
          )}
          <Phantom>
            {() => teams.map(renderItem)}
          </Phantom>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default TeamPageModifier;
