import React from 'react';
import MakeDefaultAction from './MakeDefaultAction';
import { teamIntoType } from '../../propTypes';

const TeamActions = ({ info }) => (
  <>
    <MakeDefaultAction info={info} />
  </>
);

TeamActions.propTypes = {
  info: teamIntoType,
};

export default TeamActions;
