import React from 'react';
import MenuItem from '@hitagi/core/MenuItem';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import Alert from '@hitagi/core/Alert';
import Button from '@hitagi/core/Button';
import Divider from '@hitagi/core/Divider';
import Skeleton from '@hitagi/core/Skeleton';
import SelectMenu from '@hitagi/core/SelectMenu';
import LabelButton from '@hitagi/core/LabelButton';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import { Link, useLocation } from 'react-router-dom';
import SparklesIcon from '@hitagi/icons/Sparkles';
import StarIcon from '@hitagi/icons/Star';
import Box from '@hitagi/core/Box';
import { useTeamsQuery } from '../../utils/team';
import TeamImage from '../TeamImage';

const HeaderTeam = () => {
  const location = useLocation();
  const [, page, id] = location.pathname.split('/');
  const teamId = (page === 'teams' && +id) || 0;

  const { loading, error, data } = useTeamsQuery();

  if (loading) {
    return <Skeleton variant="rect" width={146} height={34} />;
  }

  if (error) {
    return <Alert description="something unexpected happened" severity="error" />;
  }

  const teams = data && data.yogurt.teams;
  if (!teams.length) {
    return (
      <Button as={Link} to="/teams/new">
        Create a Dataset
      </Button>
    );
  }

  const defaultTeam = teams.find(team => team.isDefault);
  const selectedTeam = teams.find(team => team.id === teamId);

  const renderTeam = team => (
    <MenuItem
      key={team.id}
      selected={teamId === team.id}
      as={Link}
      to={`/teams/${team.id}`}
    >
      <ListItemElement maxWidth={24} maxHeight={24}>
        <TeamImage id={team.team?.id ?? 0} width={24} height={24} />
      </ListItemElement>
      <ListItemText primary={team.name} />
      {team.isDefault && (
        <ListItemElement>
          <Button isIconOnly disableOutline disableBg disabled size="small">
            <StarIcon />
          </Button>
        </ListItemElement>
      )}
    </MenuItem>
  );

  return (
    <SelectMenu>
      <summary>
        <LabelButton>
          {selectedTeam && (
            <LabelButton.Graphic size={LabelButton.Graphic.size.large}>
              <TeamImage id={selectedTeam.team?.id ?? 0} />
            </LabelButton.Graphic>
          )}
          <LabelButton.Text>
            {selectedTeam?.name ?? 'Dataset'}
          </LabelButton.Text>
          <Divider vertical mr={0} ml="auto" />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal align="right">
        <SelectMenu.List>
          {defaultTeam && renderTeam(defaultTeam)}
          {teams?.map(team => team !== defaultTeam && renderTeam(team))}
          {teams && teams.length > 0 && <Divider m="4px" />}
          <MenuItem as={Link} to="/teams/new">
            <ListItemElement as={Box} width="100%" height="100%">
              <SparklesIcon />
            </ListItemElement>
            <ListItemText primary="Create a dataset" />
          </MenuItem>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default HeaderTeam;
