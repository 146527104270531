import React from 'react';
import PropTypes from 'prop-types';
import Text from '@hitagi/core/Text';
import ListItemText from '@hitagi/core/ListItemText';
import { StyledListItemElement } from '@hitagi/quick-search/Results/styled';
import MenuItem from '@hitagi/core/MenuItem';

const Team = props => {
  const { id, name, ...restProps } = props;
  return (
    <MenuItem {...restProps}>
      <StyledListItemElement>
        <img src={`https://cdn.stratz.com/images/dota2/teams/${id}.png`} />
      </StyledListItemElement>
      <ListItemText
        primary={(
          <Text fontWeight="bold">
            {name}
          </Text>
        )}
      />
    </MenuItem>
  );
};

Team.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
};

export default Team;
