import styled from 'styled-components';
import Button from '@hitagi/core/Button';

export const DragHandle = styled(Button).attrs({ forwardedAs: 'div' })`
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: ${props => props.theme.spacing(1)};

  svg {
    height: 1em;
    width: 1em;
  }
`;

export const RightButtonsCol = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: ${props => props.theme.spacing(1)};

  svg {
    height: 1em;
    width: 1em;
  }

  > * {
    margin-bottom: ${props => props.theme.spacing(1)};
  }
`;
