import React from 'react';
import PropTypes from 'prop-types';
import { DataImage } from '@hitagi/core/DataImage';
import { HeroImage } from '@hitagi/dota/Hero';
import Text from '@hitagi/core/Text';
import styled from 'styled-components';
import { PickBan } from './styled';

const StyledDataImage = styled(DataImage)`
  @media print {
    filter: brightness(2);
  }
`;

const HeroBan = ({ heroId, order, ...restProps }) => (
  <PickBan {...restProps}>
    {typeof order === 'number' && (
      <Text width="3ch" textAlign="center">
        {order}
      </Text>
    )}
    <StyledDataImage disabled square>
      <HeroImage id={heroId} height={24} variant="horz" />
    </StyledDataImage>
  </PickBan>
);

HeroBan.propTypes = {
  heroId: PropTypes.number,
  order: PropTypes.number,
};

export default HeroBan;
