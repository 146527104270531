// eslint-disable-next-line import/prefer-default-export
export const sortByName = (heroes, dotaContext, order = 'desc') => {
  const sortFn = (heroA, heroB) => {
    const nameA = dotaContext.heroes[heroA.heroId]?.language?.displayName;
    const nameB = dotaContext.heroes[heroB.heroId]?.language?.displayName;

    if (order === 'asc') {
      return nameB.localeCompare(nameA);
    }

    return nameA.localeCompare(nameB);
  };

  return heroes.sort(sortFn);
};
