import styled from 'styled-components';

export const LinksRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(2)};

  a {
    margin: ${props => props.theme.spacing(1, 2, 0, 2)};
    text-align: center;
  }
`;
