import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Material from '@hitagi/core/Material';
import Button from '@hitagi/core/Button';
import Box from '@hitagi/core/Box';
import QuickSearch from '@hitagi/quick-search';
import Divider from '@hitagi/core/Divider';
import MenuItem from '@hitagi/core/MenuItem';
import ListItemText from '@hitagi/core/ListItemText';
import ListItemElement from '@hitagi/core/ListItemElement';
import Spinner from '@hitagi/core/Spinner';
import Alert from '@hitagi/core/Alert';
import teamSection from '../../../../../QuickSearchResultsTeam/teamSection';
import TeamImage from '../../../../../TeamImage';

const QUICK_SEARCH_SECTIONS = [teamSection];

const renderResult = team => (
  <Box marginTop={2}>
    <MenuItem selected selectable={false}>
      <ListItemText primary={team.name} />
      <ListItemElement>
        <TeamImage id={team.id} />
      </ListItemElement>
    </MenuItem>
  </Box>
);

const renderError = error => (
  <Alert
    marginTop={2}
    maxWidth={300}
    severity="error"
    title="Error"
    description={error.message}
  />
);

const MutableTeamNameContent = ({
  dotaTeamId,
  teamId,
  matchId,
  isRadiant,
  close,
  mutation,
}) => {
  const [updateTeam, { loading, error }] = mutation;

  const [result, setResult] = useState();

  const getResultProps = useCallback((_, team) => ({
    onClick: () => setResult(team),
  }), []);

  const onClear = useCallback(() => setResult(undefined), []);
  const canClear = !loading && Boolean(result);

  const onSave = () => {
    if (!result) {
      return;
    }

    const input = {
      matchReplayUploadTeamId: teamId,
      matchId,
      [isRadiant ? 'radiantTeamId' : 'direTeamId']: result.id,
    };

    updateTeam({ variables: { input } });
    close();
  };
  const canSave = !loading && result && result.id !== dotaTeamId;

  return (
    <Material marginTop={1} padding={0}>
      <Box padding={2}>
        <QuickSearch
          placeholder="Team Search"
          getResultProps={getResultProps}
          sections={QUICK_SEARCH_SECTIONS}
        />
        {result && renderResult(result)}
        {error && renderError(error)}
      </Box>
      <Divider margin={0} />
      <Box row justifyContent="space-between" padding={2}>
        <Button disableOutline disableBg disabled={!canClear} onClick={onClear}>
          Clear
        </Button>
        <Button isIconOnly={loading} disabled={!canSave} onClick={onSave}>
          {loading ? <Spinner /> : 'Save'}
        </Button>
      </Box>
    </Material>
  );
};

MutableTeamNameContent.propTypes = {
  dotaTeamId: PropTypes.number,
  teamId: PropTypes.number,
  matchId: PropTypes.number,
  isRadiant: PropTypes.bool,
  close: PropTypes.func,
  mutation: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default MutableTeamNameContent;
