import React, { useState, useCallback } from 'react';
import Container from '@hitagi/core/Layout/Container';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import List from '@hitagi/core/List';
import Material from '@hitagi/core/Material';
import QuickSearch, { matchSection } from '@hitagi/quick-search';
import Match from './Match';

const QUICK_SEARCH_SECTIONS = [matchSection];

const renderMatch = match => <Match key={match.id} {...match} />;

const Import = () => {
  const [matches, setMatches] = useState([]);

  const getResultProps = useCallback((type, result) => {
    const onResultClick = () => setMatches(prev => {
      if (prev.findIndex(m => m.id === result.id) === -1) {
        return [result, ...prev];
      }
      return prev;
    });
    return { onClick: onResultClick };
  }, []);

  return (
    <Container marginY={3}>
      <Box width={420} margin="0 auto">
        <QuickSearch
          placeholder="Match ID"
          getResultProps={getResultProps}
          sections={QUICK_SEARCH_SECTIONS}
        />
        {matches.length > 0 && (
          <Material padding={0} marginTop={3}>
            <List>
              {matches.map(renderMatch)}
            </List>
          </Material>
        )}
      </Box>
      <Box display="flex" justifyContent="center" mt={3}>
        <Text color="text.secondary" textAlign="center" width={600}>
          After you load your matches, go to the Review tab to make
          any changes and bring them into your Dataset.
          Then, you can see your Draft and Heroes
          data on their respective pages.
        </Text>
      </Box>
    </Container>
  );
};

export default Import;
