import React, { useReducer, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';

// BrowserRouter is a re-implementation of react-router-dom's BrowserRouter,
// but with our own history instance.
const BrowserRouter = ({ history, ...restProps }) => {
  const [state, dispatch] = useReducer(
    (_, action) => action,
    {
      action: history.action,
      location: history.location,
    },
  );

  useLayoutEffect(() => history.listen(dispatch), [history]);

  return (
    <Router
      action={state.action}
      location={state.location}
      navigator={history}
      {...restProps}
    />
  );
};

BrowserRouter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
};

export default BrowserRouter;
