/* eslint-disable  no-underscore-dangle, lines-between-class-members */

export const METER_WIDTH = 72;

export type MeterColSizes = {
  readonly valueWidth: number
  readonly meterWidth: number
  readonly width: number
}

// NOTE: classes is poop, but this usecase can be quite good.
// based on https://doc.rust-lang.org/1.0.0/style/ownership/builders.html
export class MeterColSizesBuilder {
  private _maxValueLength = 6;
  private _minMeterWidth = METER_WIDTH;

  maxValueLength(ch: number) {
    this._maxValueLength = ch;
    return this;
  }

  minMeterWidth(px: number) {
    this._minMeterWidth = px;
    return this;
  }

  build(): MeterColSizes {
    const valueWidth = this._maxValueLength * 8;
    let width = valueWidth + this._minMeterWidth;
    if (valueWidth > 0) width += 8; // gap
    return {
      valueWidth,
      meterWidth: this._minMeterWidth,
      width,
    } as const;
  }
}

export const DefaultMeterColSizes = new MeterColSizesBuilder().build();
