import React from 'react';
import PropTypes from 'prop-types';
import Text from '@hitagi/core/Text';
import Box from '@hitagi/core/Box';
import Tooltip from '@hitagi/core/Tooltip';
import { HeroImage } from '@hitagi/dota/Hero';
import { Position, PositionIcon, PositionName } from '@hitagi/dota/Position';
import styled, { css } from 'styled-components';
import { PickBan } from './styled';

const PositionWrapper = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.actionable && css`
    &:hover {
      background-color: ${props.theme.palette.background.clearGlass[4]};
      cursor: pointer;
    }
  `}
`;

const HeroPick = (props) => {
  const {
    heroId,
    playerName,
    steamAccountId,
    order,
    position,
    onPositionClick,
    ...restProps
  } = props;
  const handlePositionClick = onPositionClick && ((ev) => {
    onPositionClick(ev, steamAccountId);
  });
  return (
    <Box col width={82} {...restProps}>
      <Text variant="caption" noWrap mb="0.2em">
        {steamAccountId ? (
          <a href={`https://stratz.com/players/${steamAccountId}`} target="_blank" rel="noreferrer">
            {playerName}
          </a>
        ) : playerName}
      </Text>
      <PickBan>
        <Box width="3ch" alignSelf="stretch">
          <Box height="50%" col justifyContent="center">
            <Text lineHeight={1} textAlign="center">
              {order || '-'}
            </Text>
          </Box>
          {Position.isGqlEnum(position) && (
            <Tooltip
              content={<PositionName enum={position} />}
              placement="left"
              as={PositionWrapper}
              actionable={Boolean(handlePositionClick)}
              type={handlePositionClick ? 'button' : undefined}
              onClick={handlePositionClick}
            >
              <PositionIcon enum={position} width="0.9em" height="0.9em" />
            </Tooltip>
          )}
        </Box>
        <HeroImage id={heroId} height={34} variant="horz" />
      </PickBan>
    </Box>
  );
};

HeroPick.propTypes = {
  heroId: PropTypes.number,
  playerName: PropTypes.string,
  steamAccountId: PropTypes.number,
  order: PropTypes.number,
  position: PropTypes.oneOf(Position.gqlEnums),
  onPositionClick: PropTypes.func,
};

export default HeroPick;
