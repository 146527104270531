import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import { HeroImage, HeroName } from '@hitagi/dota/Hero';
import React, { useState } from 'react';
import { DataRow, DataRowProvider, getComparator, HeadCol, useSorting } from '@hitagi/core/DataRow';
import { DataImage } from '@hitagi/core/DataImage';
import { FormattedNumber } from 'react-intl';
import Button from '@hitagi/core/Button';
import Glass from '../../Glass';
import IndexCol, { IndexColSizes } from '../../DataRowCols/IndexCol';
import WinLossCol, { WinLossHeadCol } from '../../DataRowCols/WinLossCol';
import WinRateMeterCol, { WinRateMeterColSizes } from '../../DataRowCols/WinRateMeterCol';
import RatingMeterCol, { RatingMeterColSizes } from '../../DataRowCols/RatingMeterCol';
import { ComputedDuo, ComputedHero, MAX_ROWS } from './common';

const renderDataRowHead = (against: boolean) => (
  <Glass disableBrBottom>
    <DataRow disableUnderline px={1}>
      <Box width={IndexColSizes.width} />
      <HeadCol flexGrow={1}>
        {against ? 'Played against' : 'Played with'}
      </HeadCol>
      <WinLossHeadCol />
      <HeadCol<ComputedDuo>
        width={WinRateMeterColSizes.width}
        sortField={against ? 'winRateAgainst' : 'winRateWith'}
      >
        Win rate
      </HeadCol>
      <HeadCol<ComputedDuo>
        width={RatingMeterColSizes.width}
        sortField={against ? 'wilsonScoreAgainst' : 'wilsonScoreWith'}
      >
        Rating
      </HeadCol>
    </DataRow>
  </Glass>
);

const renderHeroCol = (heroId: number) => (
  <Box row justifyContent="center" flexGrow={1}>
    <HeroImage id={heroId} variant="icon" width={30} height={30} />
  </Box>
);

type HeroDuosHeroProps = {
  computedHero: ComputedHero
}

const HeroDuosHero = React.memo((props: HeroDuosHeroProps) => {
  const { computedHero } = props;

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  const sortingStateWith = useSorting<ComputedDuo>('wilsonScoreWith');
  const sortedWith = computedHero.duos
    .filter(duo => duo.matchCountWith > 0)
    .sort(getComparator(sortingStateWith.sortField, sortingStateWith.sortDirection));
  let rowsWith = sortedWith.map((duo, i) => (
    <DataRow key={duo.heroId} px={1}>
      <IndexCol index={i} />
      {renderHeroCol(duo.heroId)}
      <WinLossCol w={duo.winCountWith} l={duo.matchCountWith - duo.winCountWith} />
      <WinRateMeterCol winRate={duo.winRateWith} />
      <RatingMeterCol
        value={duo.wilsonScoreWith}
        minValue={computedHero.duoMinWilsonScoreWith}
        maxValue={computedHero.duoMaxWilsonScoreWith}
      />
    </DataRow>
  ));
  if (!expanded && sortedWith.length > MAX_ROWS) {
    rowsWith = rowsWith.slice(0, MAX_ROWS);
  }

  const sortingStateAgainst = useSorting<ComputedDuo>('wilsonScoreAgainst');
  const sortedAgainst = computedHero.duos
    .filter(duo => duo.matchCountAgainst > 0)
    .sort(getComparator(sortingStateAgainst.sortField, sortingStateAgainst.sortDirection));
  let rowsAgainst = sortedAgainst.map((duo, i) => (
    <DataRow key={duo.heroId} px={1}>
      <IndexCol index={i} />
      {renderHeroCol(duo.heroId)}
      <WinLossCol w={duo.winCountAgainst} l={duo.matchCountAgainst - duo.winCountAgainst} />
      <WinRateMeterCol winRate={duo.winRateAgainst} />
      <RatingMeterCol
        value={duo.wilsonScoreAgainst}
        minValue={computedHero.duoMinWilsonScoreAgainst}
        maxValue={computedHero.duoMaxWilsonScoreAgainst}
      />
    </DataRow>
  ));
  if (!expanded && sortedAgainst.length > MAX_ROWS) {
    rowsAgainst = rowsAgainst.slice(0, MAX_ROWS);
  }

  return (
    <Glass p={2} col mt={3}>
      <Box row alignItems="center">
        <DataImage>
          <HeroImage id={computedHero.heroId} variant="horz" height={40} />
        </DataImage>
        <Text variant="title" ml={1}>
          <HeroName id={computedHero.heroId} />
        </Text>
        <Box col alignItems="end" ml="auto">
          <Text color="text.secondary">
            With
            {': '}
            Win rate
            {' '}
            <Text variant="inherit" display="inline-block" width="5ch" textAlign="right">
              <FormattedNumber
                value={computedHero.matchCountWith
                  ? computedHero.winCountWith / computedHero.matchCountWith : 0}
                style="percent"
              />
            </Text>
            {' - '}
            Matches
            {' '}
            <Text variant="inherit" display="inline-block" width="3ch" textAlign="right">
              <FormattedNumber value={computedHero.matchCountWith} />
            </Text>
          </Text>

          <Text color="text.secondary">
            Against
            {': '}
            Win rate
            {' '}
            <Text variant="inherit" display="inline-block" width="5ch" textAlign="right">
              <FormattedNumber
                value={computedHero.matchCountAgainst
                  ? computedHero.winCountAgainst / computedHero.matchCountAgainst : 0}
                style="percent"
              />
            </Text>
            {' - '}
            Matches
            {' '}
            <Text variant="inherit" display="inline-block" width="3ch" textAlign="right">
              <FormattedNumber value={computedHero.matchCountAgainst} />
            </Text>
          </Text>
        </Box>
      </Box>
      <Box row alignItems="start" mt={2}>
        <Glass flexGrow={1} flexBasis={0} col>
          <DataRowProvider disableMobileMatch sortingState={sortingStateWith}>
            {renderDataRowHead(false)}
            {rowsWith}
            {!rowsWith.length && (
              <DataRow>
                <Text color="text.secondary" textAlign="center" flexGrow={1}>
                  no matches
                </Text>
              </DataRow>
            )}
          </DataRowProvider>
        </Glass>
        <Box mx={1} />
        <Glass flexGrow={1} flexBasis={0} col>
          <DataRowProvider disableMobileMatch sortingState={sortingStateAgainst}>
            {renderDataRowHead(true)}
            {rowsAgainst}
            {!rowsAgainst.length && (
              <DataRow>
                <Text color="text.secondary" textAlign="center" flexGrow={1}>
                  no matches
                </Text>
              </DataRow>
            )}
          </DataRowProvider>
        </Glass>
      </Box>
      {(sortedWith.length > MAX_ROWS || sortedAgainst.length > MAX_ROWS) && (
        <Button onClick={handleExpandClick} disableOutline disableBg size="small" mt={2} mx="auto">
          {expanded ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Glass>
  );
});

export default HeroDuosHero;
