export const groupMatches = matches => matches.reduce((acc, match) => {
  // NOTE: invalidated is @client field.
  // it is being set by mutation in DraftMatchRowContainer.tsx
  // deleted is also @client field, set in DeletionHandle.tsx
  if (match.invalidated || match.deleted) {
    return acc;
  }

  const series = match.seriesId && acc.find(s => s[0].seriesId === match.seriesId);
  if (series) {
    series.push(match);
    return acc;
  }
  acc.push([match]);
  return acc;
}, []);

export const getIsPrimaryTeamRadiant = (teamId, radiantTeam, direTeam) => {
  if (radiantTeam && radiantTeam.id === teamId) {
    return true;
  }
  if (direTeam && direTeam.id === teamId) {
    return false;
  }
  return undefined;
};
