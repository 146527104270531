import React from 'react';
import PropTypes from 'prop-types';
import Material from '@hitagi/core/Material';
import Divider from '@hitagi/core/Divider';
import { matchType } from '../../../MatchRow/Draft/propTypes';
import ErrorBoundary from '../MatchRowErrorBoundary';
import MatchSeriesHeader from '../MatchSeriesHeader';
import { getIsPrimaryTeamRadiant } from '../helpers';
import DraftMatchRowContainer from './DraftMatchRowContainer';

const Series = React.memo(
  ({ series, dotaTeamId, yogurtTeamId }) => {
    const renderMatch = (match, i, arr) => {
      const isPrimaryTeamRadiant = getIsPrimaryTeamRadiant(
        dotaTeamId,
        match.radiantTeam,
        match.direTeam,
      );
      return (
        <ErrorBoundary key={match.id}>
          <DraftMatchRowContainer
            yogurtTeamId={yogurtTeamId}
            match={match}
            isPrimaryTeamRadiant={isPrimaryTeamRadiant}
          />
          {i < arr.length - 1 && <Divider margin={0} />}
        </ErrorBoundary>
      );
    };

    return (
      <Material padding={0} marginBottom={2} elevation={0}>
        {(series.length > 1 || series[0].seriesId) && (
          <MatchSeriesHeader series={series} />
        )}
        {series.map(renderMatch)}
      </Material>
    );
  },
  (prevProps, nextProps) => (
    prevProps.series.length === nextProps.series.length
      && prevProps.series.every((match, i) => match.id === nextProps.series[i].id)
      && prevProps.dotaTeamId === nextProps.dotaTeamId
      && prevProps.yogurtTeamId === nextProps.yogurtTeamId
  ),
);

Series.propTypes = {
  series: PropTypes.arrayOf(matchType),
  dotaTeamId: PropTypes.number,
  yogurtTeamId: PropTypes.number,
};

export default Series;
