import React from 'react';
import { AppBar } from '@hitagi/page/AppBar';
import Box from '@hitagi/core/Box';
import Logo from './HeaderLogo';
import Team from './HeaderTeam';
import HeaderUserMenu from './HeaderUserMenu';
import ProtectedNode from '../ProtectedNode';

const Header = () => (
  <AppBar>
    <Logo />
    <Box flexGrow={1} />
    <ProtectedNode>
      <Box display="flex" marginX={2}>
        <Team />
      </Box>
    </ProtectedNode>
    <Box display="flex">
      <HeaderUserMenu />
    </Box>
  </AppBar>
);

export default Header;
