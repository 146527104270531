import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTheme } from '@hitagi/redux/selectors/ui';
import { setTheme } from '@hitagi/redux/actions/ui';
import { get } from '@hitagi/utils';
import { createTheme as createCoreTheme } from '@hitagi/core/styles';
import { createDotaPalette } from '@hitagi/dota/styles';

export const createTheme = themeType => createCoreTheme({
  palette: {
    type: themeType,
    dota: createDotaPalette({ type: themeType }),
  },
});

export const useTheme = () => {
  const theme = useSelector(getTheme);
  const isDark = get(['palette', 'type'], theme) === 'dark';
  const dispatch = useDispatch();
  const toggle = useCallback(() => {
    dispatch(setTheme(createTheme(isDark ? 'light' : 'dark')));
  }, [dispatch, isDark]);

  return useMemo(() => ({
    ...theme,
    isDark,
    toggle,
  }), [theme, isDark, toggle]);
};
