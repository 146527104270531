import React from 'react';
import PropTypes from 'prop-types';
import Box from '@hitagi/core/Box';
import Text from '@hitagi/core/Text';
import Tooltip from '@hitagi/core/Tooltip';
import { FactionImage, FactionName } from '@hitagi/dota/Faction';
import { Label } from '../../../../MatchRow/Draft/styled';
import { matchType } from '../../../../MatchRow/Draft/propTypes';
import MutableTeamName from './MutableTeamName';

const getIsWin = (match, isRadiant) => (isRadiant && match.didRadiantWin)
  || (!isRadiant && !match.didRadiantWin);

const getStringWidth = (...args) => `${Math.max(...args.map(s => s.length))}ch`;
const outcomeStringWidth = getStringWidth('Victory', 'Defeat');

const renderFactionId = isRadiant => (
  <Tooltip content={<FactionName isRadiant={isRadiant} />} placement="top" as={Box} display="inline-flex">
    <FactionImage isRadiant={isRadiant} width={18} />
  </Tooltip>
);

const MatchTeamHeader = ({ match, isRadiant, teamId, ...restProps }) => {
  const isWin = getIsWin(match, isRadiant);

  const team = isRadiant ? match.radiantTeam : match.direTeam;

  return (
    <Box row alignItems="center" {...restProps}>
      {renderFactionId(isRadiant)}
      <MutableTeamName
        matchId={match.id}
        isRadiant={isRadiant}
        dotaTeamId={team ? team.id : undefined}
        teamId={teamId}
        marginX={1}
      >
        {team && team.name}
      </MutableTeamName>
      <Box flexGrow={1} />
      <Label>
        <Box width={outcomeStringWidth}>
          <Text color={isWin ? 'radiant' : 'dire'} fontWeight="bold">
            {isWin ? 'Victory' : 'Defeat'}
          </Text>
        </Box>
      </Label>
    </Box>
  );
};

MatchTeamHeader.propTypes = {
  match: matchType,
  isRadiant: PropTypes.bool,
  teamId: PropTypes.number,
};

export default MatchTeamHeader;
