import React from 'react';
import styled from 'styled-components';
import Box from '@hitagi/core/Box';

const StyledBox = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

export default function MenuActions(props) {
  return <StyledBox row padding={2} {...props} />;
}
