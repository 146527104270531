import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Button from '@hitagi/core/Button';
import CrossmarkIcon from '@hitagi/icons/Crossmark';
import { useDispatch } from 'react-redux';
import { addNotification } from '@hitagi/redux/actions/notificationStack';

const DELETE_MATCH_MUTATION = gql`
  mutation DeleteMatch($yogurtTeamId: Long!, $matchId: Long!) {
    yogurt {
      delete(matchReplayUploadTeamId: $yogurtTeamId, matchId: $matchId)
    }
  }
`;

type DeleteMatchMutation = {
  yogurt: {
    invalidate: boolean;
  };
};

type DeleteMatchMutationVariables = {
  yogurtTeamId: number;
  matchId: number;
};

type DeletionHandleProps = {
  yogurtTeamId: number;
  matchId: number;
};

const DeletionHandle = (props: DeletionHandleProps) => {
  const { yogurtTeamId, matchId } = props;

  const dispatch = useDispatch();

  const [deleteMatch] = useMutation<
    DeleteMatchMutation,
    DeleteMatchMutationVariables
  >(DELETE_MATCH_MUTATION, {
    onError: (error) => {
      dispatch(addNotification({
        severity: 'error',
        variant: 'filled',
        title: 'Error',
        description: `Could not delete match ${matchId}: ${error.message}`,
      }));
    },
  });

  const handleDeleteMatchClick = () => {
    deleteMatch({
      variables: { yogurtTeamId, matchId },
      update: (proxy) => {
        proxy.writeFragment({
          id: `MatchReplayUploadMatchType:${matchId}`,
          fragment: gql`
            fragment MatchInvalidationFragment on MatchReplayUploadMatchType {
              deleted
            }
          `,
          data: {
            deleted: true,
            __typename: 'MatchReplayUploadMatchType',
          },
        });
      },
    });
  };

  return (
    <Button isIconOnly disableBg bg="red.glass" onClick={handleDeleteMatchClick}>
      <CrossmarkIcon />
    </Button>
  );
};

export default DeletionHandle;
