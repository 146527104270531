import React from 'react';
import Box from '@hitagi/core/Box';
import Meter from '@hitagi/core/Meter';
import { MeterColSizes, MeterColSizesBuilder } from './common';

export const RatingMeterColSizes = new MeterColSizesBuilder()
  .maxValueLength(0)
  .build();

type RatingMeterColProps = {
  value: number
  minValue: number
  maxValue: number
  sizes?: MeterColSizes
}

const RatingMeterCol = (props: RatingMeterColProps) => {
  const {
    value,
    minValue,
    maxValue,
    sizes = RatingMeterColSizes,
    ...restProps
  } = props;

  const correctedStartValue = value - minValue;
  const range = maxValue - minValue;
  const percentage = correctedStartValue / range;

  return (
    <Box row width={sizes.width} {...restProps}>
      <Meter value={percentage} foreground="text.secondary" />
    </Box>
  );
};

export default RatingMeterCol;
