import React from 'react';
import PropTypes from 'prop-types';
import Box from '@hitagi/core/Box';
import { getScore } from '@hitagi/dota/utils/match';
import { kenDate } from '@hitagi/utils';
import { matchType } from '../../../../MatchRow/Draft/propTypes';
import MatchDetails from '../../../../MatchRow/Draft/MatchDetails';
import { Cell } from '../../../../MatchRow/Draft/styled';
import MatchTeamHeader from './MatchTeamHeader';
import MatchTeam from './MatchTeam';

const MatchRow = ({ match, isPrimaryTeamRadiant, teamId }) => {
  const score = getScore(match.players);
  if (isPrimaryTeamRadiant) {
    score.reverse();
  }

  return (
    <Box display="grid" gridTemplateColumns="0px 216px 1fr 1fr">
      <div />
      <Cell>
        <MatchDetails
          startDate={kenDate(match.startDateTime)}
          duration={match.durationSeconds}
          matchId={match.id}
          score={score}
          gameMode={match.gameMode}
        />
      </Cell>
      <Cell>
        <MatchTeamHeader match={match} isRadiant={isPrimaryTeamRadiant} teamId={teamId} />
        <MatchTeam match={match} isRadiant={isPrimaryTeamRadiant} marginTop={1} />
      </Cell>
      <Cell>
        <MatchTeamHeader match={match} isRadiant={!isPrimaryTeamRadiant} teamId={teamId} />
        <MatchTeam match={match} isRadiant={!isPrimaryTeamRadiant} marginTop={1} />
      </Cell>
    </Box>
  );
};

MatchRow.propTypes = {
  match: matchType,
  isPrimaryTeamRadiant: PropTypes.bool,
  teamId: PropTypes.number,
};

MatchRow.defaultProps = {
  isPrimaryTeamRadiant: true,
};

export default MatchRow;
