import React from 'react';
import PropTypes from 'prop-types';
import Container from '@hitagi/core/Layout/Container';
import { useUser } from '../utils/user';
import PageSpinner from './PageSpinner';

const ProtectedRoute = props => {
  const { loading, isLoggedIn } = useUser();

  if (loading) {
    return <PageSpinner />;
  }

  if (!isLoggedIn) {
    return (
      <Container center>
        unauthenticated
      </Container>
    );
  }

  return props.children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};

export default ProtectedRoute;
