import React, { useState } from 'react';
import { LobbyType, LobbyTypeName, LOBBY_TYPE_NAME_MESSAGES } from '@hitagi/dota/LobbyType';
import { useIntl } from 'react-intl';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import Divider from '@hitagi/core/Divider';
import LabelButton from '@hitagi/core/LabelButton';
import Phantom from '@hitagi/core/Phantom';
import SelectMenu from '@hitagi/core/SelectMenu';
import messages from './messages';

const ALL_VALUES = LobbyType.ids.slice(1); // cut off NONE

export type LobbyTypePageModifierProps = {
  lobbyTypeId: LobbyType.Id | undefined
  setLobbyTypeId: (lobbyTypeId: LobbyType.Id | undefined) => void
  unlistUnion?: boolean
  include?: LobbyType.Id[]
}

const LobbyTypePageModifier = (props: LobbyTypePageModifierProps) => {
  const {
    lobbyTypeId,
    setLobbyTypeId,
    unlistUnion,
    include,
    ...restProps
  } = props;

  const intl = useIntl();

  const [search, setSearch] = useState('');
  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };
  const handleSelection = (event: React.MouseEvent<HTMLLIElement>) => {
    const { value } = event.currentTarget.dataset;
    const normalizedValue = value === undefined ? undefined : (+value as LobbyType.Id);
    setLobbyTypeId(normalizedValue);
    setSearch('');
  };

  const normalizedSearch = search.toLowerCase();
  const renderItem = (value: LobbyType.Id) => {
    if (include !== undefined && !include.includes(value)) return null;
    const string = intl.formatMessage(LOBBY_TYPE_NAME_MESSAGES[value]).toLowerCase();
    if (!string.includes(normalizedSearch)) return null;
    return (
      <SelectMenu.Item
        key={value}
        selected={value === lobbyTypeId}
        data-value={value}
        onClick={handleSelection}
      >
        <LobbyTypeName id={value} />
      </SelectMenu.Item>
    );
  };

  const unionSelected = lobbyTypeId === undefined;

  return (
    <SelectMenu {...restProps}>
      <summary>
        <LabelButton>
          <LabelButton.Text>
            {unionSelected
              ? intl.formatMessage(messages.allLobbyTypes)
              : <LobbyTypeName id={lobbyTypeId!} />}
          </LabelButton.Text>
          <Divider vertical margin={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.Filter
          placeholder={intl.formatMessage(messages.filterLobbyTypes)}
          value={search}
          onChange={handleSearch}
        />
        <SelectMenu.List>
          {!unlistUnion && !search && (
            <SelectMenu.Item
              selected={unionSelected}
              onClick={handleSelection}
            >
              {intl.formatMessage(messages.allLobbyTypes)}
            </SelectMenu.Item>
          )}
          <Phantom>
            {() => ALL_VALUES.map(renderItem)}
          </Phantom>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default LobbyTypePageModifier;
