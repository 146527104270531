import React, { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Defer from '@hitagi/core/Defer';
import TeamHeader from './TeamHeader';
import PageSpinner from '../PageSpinner';

const Team = () => {
  const location = useLocation();
  const [,, id, subpage] = location.pathname.split('/');

  return (
    <>
      <TeamHeader />
      <Suspense fallback={<PageSpinner />}>
        <Defer fallback={<PageSpinner />} key={id + subpage}>
          <Outlet />
        </Defer>
      </Suspense>
    </>
  );
};

export default Team;
