import React, { Suspense, useCallback, useState } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { DotaProvider } from '@hitagi/dota/Context';
import { useDispatch, useSelector } from 'react-redux';
import { getTheme } from '@hitagi/redux/selectors/ui';
import { PageProvider } from '@hitagi/page/Context';
import { useRoutes } from 'react-router-dom';
import { NotificationStackProvider } from '@hitagi/core/NotificationStack';
import * as notiStackActions from '@hitagi/redux/actions/notificationStack';
import * as notiStackSelectors from '@hitagi/redux/selectors/notificationStack';
import Helmet from 'react-helmet';
import SvgIconGradients from '@hitagi/icons/SvgIconGradients';
import { routes } from '../../router';
import Header from '../Header';
import Footer from '../Footer';
import { selectDotaConstants } from '../../store/allDotaConstants';
import PageSpinner from '../PageSpinner';
import { GlobalStyle, MainWrapper } from './styled';

const PrintCSS = createGlobalStyle`
  @media print {
    div {
      break-inside: avoid;
    }

    * {
      color: black !important;
    }
  }
`;

const NotificationStackWrapper1 = styled.div`
  position: sticky;
  top: 0;
  height: 0;
  /* TODO establish zIndexes in hitagi */
  z-index: 3;
  pointer-events: none;
`;

const NotificationStackWrapper2 = styled.div`
  position: absolute;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
`;

const NotificationStackWrapper3 = styled.div`
  padding: ${(props) => props.theme.spacing(1)};
  margin-left: auto;
  max-width: 100%;
  pointer-events: all;
`;

// NOTE: if we'll use this hook inside of App - it'll trigger re-render of
// everything in there.
const Routes = () => useRoutes(routes);

const App = () => {
  const theme = useSelector(getTheme);
  const dispatch = useDispatch();

  const dotaConstants = useSelector(selectDotaConstants);

  const [notiStackMountContainer, setNotiStackMountContainer] = useState();
  const notiStack = useSelector(notiStackSelectors.getStack);
  const setNotiStack = useCallback(
    (value) => {
      dispatch(notiStackActions.setStack(value));
    },
    [dispatch],
  );
  const notiStackAddQueue = useSelector(notiStackSelectors.getAddQueue);
  const notiStackDelQueue = useSelector(notiStackSelectors.getDelQueue);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en">
        <DotaProvider constants={dotaConstants}>
          <PageProvider routes={routes}>
            <NotificationStackProvider
              mountContainer={notiStackMountContainer}
              stack={notiStack}
              setStack={setNotiStack}
              addQueue={notiStackAddQueue}
              delQueue={notiStackDelQueue}
              ttl={10000}
            >
              <GlobalStyle />
              <PrintCSS />
              <SvgIconGradients />
              <Helmet defaultTitle="STRATZ Yogurt" titleTemplate="%s - STRATZ Yogurt" defer={false} />
              <Header />
              <NotificationStackWrapper1>
                <NotificationStackWrapper2>
                  <NotificationStackWrapper3 ref={setNotiStackMountContainer} />
                </NotificationStackWrapper2>
              </NotificationStackWrapper1>
              <MainWrapper>
                <Suspense fallback={<PageSpinner />}>
                  <Routes />
                </Suspense>
              </MainWrapper>
              <Footer />
            </NotificationStackProvider>
          </PageProvider>
        </DotaProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
