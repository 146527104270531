import React from 'react';
import PropTypes from 'prop-types';
import { get } from '@hitagi/utils';
import Box from '@hitagi/core/Box';
import { GameMode } from '@hitagi/dota/GameMode';
import HeroPick from './HeroPick';
import HeroBan from './HeroBan';
import { matchType } from './propTypes';
import TeamDraftHeader from './TeamDraftHeader';
import { proNamePath, namePath } from './paths';

const renderBan = ban => (
  <HeroBan
    key={ban.bannedHeroId}
    order={ban.order + 1}
    heroId={ban.bannedHeroId}
  />
);
const renderRadiantBan = pickban => pickban.isRadiant
  && pickban.wasBannedSuccessfully
  && renderBan(pickban);
const renderDireBan = pickban => !pickban.isRadiant
  && pickban.wasBannedSuccessfully
  && renderBan(pickban);

const TeamDraft = ({ match, isRadiant, onPositionClick }) => {
  let picks;
  if (match.pickBans) {
    picks = match.pickBans.map(pickBan => {
      if (!pickBan.isPick) {
        return null;
      }
      const player = match.players.find(p => p.heroId === pickBan.heroId);
      if (isRadiant ? !player.isRadiant : player.isRadiant) {
        return null;
      }
      return (
        <HeroPick
          key={player.heroId}
          heroId={player.heroId}
          playerName={get(proNamePath, player) || get(namePath, player)}
          steamAccountId={player.steamAccount?.id}
          order={pickBan.order + 1}
          position={player.position}
          onPositionClick={onPositionClick}
        />
      );
    });
  } else {
    picks = match.players.map(player => {
      if (isRadiant ? !player.isRadiant : player.isRadiant) {
        return null;
      }
      return (
        <HeroPick
          key={player.heroId}
          heroId={player.heroId}
          playerName={get(proNamePath, player) || get(namePath, player)}
          steamAccountId={player.steamAccount?.id}
          position={player.position}
          onPositionClick={onPositionClick}
        />
      );
    });
  }

  return (
    <>
      <TeamDraftHeader match={match} isRadiant={isRadiant} />
      <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gridColumnGap={1} marginTop={1}>
        {picks}
      </Box>
      {match.pickBans && match.gameMode === GameMode.CAPTAINS_MODE && (
        <Box display="grid" gridTemplateColumns="repeat(7, 1fr)" gridColumnGap={1} marginTop={1}>
          {match.pickBans.map(isRadiant ? renderRadiantBan : renderDireBan)}
        </Box>
      )}
    </>
  );
};

TeamDraft.propTypes = {
  match: matchType,
  isRadiant: PropTypes.bool,
  onPositionClick: PropTypes.func,
};

export default TeamDraft;
