import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import Divider from '@hitagi/core/Divider';
import ListItemElement from '@hitagi/core/ListItemElement';
import ListItemText from '@hitagi/core/ListItemText';
import LabelButton from '@hitagi/core/LabelButton';
import Phantom from '@hitagi/core/Phantom';
import SelectMenu from '@hitagi/core/SelectMenu';
import SvgIcon from '@hitagi/icons/SvgIcon';
import messages from './messages';
import LeagueImage from '../LeagueImage';

type League = {
  id: number
  displayName: string
}

type LeaguePageModifierProps = {
  leagueId?: number;
  setLeagueId: (leagueId?: number) => void;
  unlistUnion?: boolean
  leagues: League[]
};

const LeaguePageModifier = (props: LeaguePageModifierProps) => {
  const {
    leagueId,
    setLeagueId,
    leagues,
    unlistUnion,
    ...restProps
  } = props;

  const intl = useIntl();

  const [search, setSearch] = useState('');
  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };
  const handleSelection = (event: React.MouseEvent<HTMLLIElement>) => {
    const { value } = event.currentTarget.dataset;
    const normalizedValue = value === undefined ? undefined : +value;
    setLeagueId(normalizedValue);
    setSearch('');
  };

  const normalizedSearch = search.toLowerCase();
  const renderItem = (league: League) => {
    const string = league.displayName.toLowerCase();
    if (!string?.includes(normalizedSearch)) return null;
    return (
      <SelectMenu.Item
        key={league.id}
        selected={league.id === leagueId}
        data-value={league.id}
        onClick={handleSelection}
      >
        <ListItemElement maxWidth={24} maxHeight={24}>
          <LeagueImage id={league.id} width={24} height={24} />
        </ListItemElement>
        <ListItemText primary={league.displayName} />
      </SelectMenu.Item>
    );
  };

  const unionSelected = leagueId === undefined;
  const league = leagueId === undefined ? undefined : leagues.find(t => t.id === leagueId);

  return (
    <SelectMenu {...restProps}>
      <summary>
        <LabelButton>
          {!unionSelected && (
            <LabelButton.Graphic size={LabelButton.Graphic.size.max}>
              <LeagueImage id={leagueId} />
            </LabelButton.Graphic>
          )}
          <LabelButton.Text>
            {unionSelected
              ? intl.formatMessage(messages.allLeagues)
              : league?.displayName ?? leagueId}
          </LabelButton.Text>
          <Divider vertical margin={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.Filter
          placeholder={intl.formatMessage(messages.filterLeagues)}
          value={search}
          onChange={handleSearch}
        />
        <SelectMenu.List>
          {!unlistUnion && !search && (
            <SelectMenu.Item
              selected={unionSelected}
              onClick={handleSelection}
            >
              <ListItemElement>
                <SvgIcon />
              </ListItemElement>
              <ListItemText primary={intl.formatMessage(messages.allLeagues)} />
            </SelectMenu.Item>
          )}
          <Phantom>
            {() => leagues.map(renderItem)}
          </Phantom>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default LeaguePageModifier;
