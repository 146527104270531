import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Material from '@hitagi/core/Material';
import { matchType } from '../../../MatchRow/Draft/propTypes';
import SeriesHeader from './SeriesHeader';
import DraggableMatch from './DraggableMatch';
import { getIsPrimaryTeamRadiant } from '../helpers';

const DroppableMaterial = styled(Material).attrs({ elevation: 0, padding: 0 })`
  background-color: ${props => props.isDraggingOver && props.theme.palette.background.clearGlass[3]};
  transition: ${props => props.theme.transitions.create(['background-color', 'padding'])};
  margin-bottom: ${props => props.theme.spacing(2)};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const DroppableSeries = ({ series, teamId, dotaTeamId }) => {
  const renderMatch = (match, index) => {
    const isPrimaryTeamRadiant = getIsPrimaryTeamRadiant(
      dotaTeamId,
      match.radiantTeam,
      match.direTeam,
    );
    return (
      <DraggableMatch
        key={match.id}
        match={match}
        index={index}
        isPrimaryTeamRadiant={isPrimaryTeamRadiant}
        teamId={teamId}
        isDraggable={series.length < 2}
      />
    );
  };

  const [{ seriesId, id: matchId }] = series;

  return (
    <Droppable
      key={seriesId || matchId}
      droppableId={seriesId ? `series-${seriesId}` : `match-${matchId}`}
    >
      {(provided, snapshot) => (
        <DroppableMaterial
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.isDraggingOver}
        >
          {(series.length > 1 || seriesId > 0)
            && <SeriesHeader series={series} />}
          {series.map(renderMatch)}
          {provided.placeholder}
        </DroppableMaterial>
      )}
    </Droppable>
  );
};

DroppableSeries.propTypes = {
  teamId: PropTypes.number,
  dotaTeamId: PropTypes.number,
  series: PropTypes.arrayOf(matchType),
};

const getTeamId = team => team && team.id;

const areEqual = (prevProps, nextProps) => !(
  prevProps.teamId !== nextProps.teamId
    || prevProps.dotaTeamId !== nextProps.dotaTeamId
    || prevProps.series.length !== nextProps.series.length
    || prevProps.series.some((m, i) => m.seriesId !== nextProps.series[i].seriesId
      || m.isValidated !== nextProps.series[i].isValidated
      || getTeamId(m.radiantTeam) !== getTeamId(nextProps.series[i].radiantTeam)
      || getTeamId(m.direTeam) !== getTeamId(nextProps.series[i].direTeam))
);

export default React.memo(DroppableSeries, areEqual);
