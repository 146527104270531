import SelectMenu from '@hitagi/core/SelectMenu';
import LabelButton from '@hitagi/core/LabelButton';
import { ComponentProps } from '@hitagi/utils/types';
import React from 'react';
import Divider from '@hitagi/core/Divider';
import ChevronDownIcon from '@hitagi/icons/ChevronDown';
import { boolFromString } from '@hitagi/utils';
import ListItemElement from '@hitagi/core/ListItemElement';
import SvgIcon from '@hitagi/icons/SvgIcon';
import ListItemText from '@hitagi/core/ListItemText';
import { FactionImage, FactionName } from '@hitagi/dota/Faction';

type FactionPageModifierProps = {
  isRadiant: boolean | undefined;
  setIsRadiant: (isRadiant: boolean | undefined) => void;
} & ComponentProps<typeof SelectMenu>;

const FactionPageModifier = (props: FactionPageModifierProps) => {
  const { isRadiant, setIsRadiant, ...restProps } = props;

  const handleClick = (ev: React.MouseEvent<HTMLLIElement>) => {
    const { value } = ev.currentTarget.dataset;
    setIsRadiant(value === undefined ? undefined : boolFromString(value));
  };

  return (
    <SelectMenu {...restProps}>
      <summary>
        <LabelButton>
          {isRadiant !== undefined && (
            <LabelButton.Graphic>
              <FactionImage isRadiant={isRadiant} size="unset" />
            </LabelButton.Graphic>
          )}
          <LabelButton.Text>
            {isRadiant === undefined
              ? 'All factions'
              : <FactionName isRadiant={isRadiant} />}
          </LabelButton.Text>
          <Divider vertical m={0} />
          <LabelButton.Control>
            <ChevronDownIcon />
          </LabelButton.Control>
        </LabelButton>
      </summary>
      <SelectMenu.Modal>
        <SelectMenu.List>
          <SelectMenu.Item
            selected={isRadiant === undefined}
            onClick={handleClick}
          >
            <ListItemElement maxWidth={24} maxHeight={24}>
              <SvgIcon />
            </ListItemElement>
            <ListItemText primary="All factions" />
          </SelectMenu.Item>
          <SelectMenu.Item
            selected={isRadiant === true}
            data-value
            onClick={handleClick}
          >
            <ListItemElement maxWidth={24} maxHeight={24}>
              <FactionImage isRadiant />
            </ListItemElement>
            <ListItemText primary={<FactionName isRadiant />} />
          </SelectMenu.Item>
          <SelectMenu.Item
            selected={isRadiant === false}
            data-value={false}
            onClick={handleClick}
          >
            <ListItemElement maxWidth={24} maxHeight={24}>
              <FactionImage isRadiant={false} />
            </ListItemElement>
            <ListItemText primary={<FactionName isRadiant={false} />} />
          </SelectMenu.Item>
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  );
};

export default FactionPageModifier;
