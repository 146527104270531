import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GameMode } from '@hitagi/dota/GameMode';
import styled from 'styled-components';
import { LobbyType } from '@hitagi/dota/LobbyType';
import { HeroName } from '@hitagi/dota/Hero';
import { Container } from '@hitagi/core/Layout';
import { PositionName } from '@hitagi/dota/Position';
import Hero from '../../../Filters/Hero';
import * as flux from '../flux';
import PositionPickOrderFilter from './PositionPickOrderFilter';
import PageModifiersContainer from '../../../PageModifiers/Container';
import FirstPickPageModifier from './FirstPickPageModifier';
import GameModePageModifier from '../../../PageModifiers/GameMode';
import FactionPageModifier from './FactionPageModifier';
import TeamPageModifier from '../../../PageModifiers/Team';
import LeaguePageModifier from '../../../PageModifiers/League';
import SteamAccountPageModifier from '../../../PageModifiers/SteamAccount';
import LobbyTypePageModifier from '../../../PageModifiers/LobbyType';

const NonPrintablePageModifiersContainer = styled(PageModifiersContainer)`
  @media print {
    display: none;
  }
`;

const OnlyPrintableContainer = styled(Container)`
  display: none !important;

  @media print {
    display: block !important;
  }
`;

const renderHeroFromHeroesFilter = (acc, heroId, i, arr) => {
  acc.push((
    <React.Fragment key={heroId}>
      <HeroName id={heroId} />
      {arr[i + 1] ? ', ' : '.'}
    </React.Fragment>
  ));
  return acc;
};

const Filters = ({ state, dispatch, matches }) => {
  const set = useMemo(
    () => ({
      teamId: (v) => dispatch(flux.setByTeamId(v === undefined ? undefined : [v])),
      lobbyType: (v) => dispatch(flux.setByLobbyType(v === undefined ? undefined : [v])),
      gameMode: (v) => dispatch(flux.setByGameMode(v === undefined ? undefined : [v])),
      leagueId: (v) => dispatch(flux.setByLeagueId(v === undefined ? undefined : [v])),
      bySteamAccountId: (v) => dispatch(flux.setBySteamAccountId(v)),
      withFriendHeroId: (v) => dispatch(flux.setWithFriendHeroId(v)),
      withEnemyHeroId: (v) => dispatch(flux.setWithEnemyHeroId(v)),
      withFriendBannedHeroId: (v) => dispatch(flux.setWithFriendBannedHeroId(v)),
      withEnemyBannedHeroId: (v) => dispatch(flux.setWithEnemyBannedHeroId(v)),
      byHeroId: (v) => dispatch(flux.setByHeroId(v)),
      filterPositionIsUs: (v) => dispatch(flux.setFilterPositionIsUs(v)),
      filterPosition: (v) => dispatch(flux.setFilterPosition(v)),
      filterPositionOrder: (v) => dispatch(flux.setFilterPositionOrder(v)),
      firstPick: (v) => dispatch(flux.setFirstPick(v)),
      isRadiant: (v) => dispatch(flux.setIsRadiant(v)),
    }),
    [dispatch],
  );

  const pageModifiersData = useMemo(() => {
    const teamMap = {};
    const leagueMap = {};
    const steamAccountMap = {};
    const heroIdMap = {};
    matches?.forEach(match => {
      if (match.radiantTeam) teamMap[match.radiantTeam.id] = match.radiantTeam;
      if (match.direTeam) teamMap[match.direTeam.id] = match.direTeam;
      if (match.league) leagueMap[match.league.id] = match.league;
      match.players.forEach(player => {
        if (player.steamAccount) {
          steamAccountMap[player.steamAccount.id] = player.steamAccount;
        }
        heroIdMap[player.heroId] = player.heroId;
      });
    });
    return {
      teams: Object.values(teamMap),
      leagues: Object.values(leagueMap),
      steamAccounts: Object.values(steamAccountMap),
      heroIds: Object.values(heroIdMap),
    };
  }, [matches]);

  return (
    <>
      <PageModifiersContainer>
        <TeamPageModifier
          teamId={state.byTeamId}
          setTeamId={set.teamId}
          teams={pageModifiersData.teams}
        />
        <LobbyTypePageModifier
          lobbyTypeId={state.byLobbyType?.[0]}
          setLobbyTypeId={set.lobbyType}
          include={[
            LobbyType.UNRANKED,
            LobbyType.PRACTICE,
            LobbyType.TOURNAMENT,
            LobbyType.RANKED,
            LobbyType.BATTLE_CUP,
          ]}
        />
        <LeaguePageModifier
          leagueId={state.byLeagueId}
          setLeagueId={set.leagueId}
          leagues={pageModifiersData.leagues}
        />
        <SteamAccountPageModifier
          steamAccountId={state.bySteamAccountId}
          setSteamAccountId={set.bySteamAccountId}
          steamAccounts={pageModifiersData.steamAccounts}
        />
        <GameModePageModifier
          gameMode={state.byGameMode?.[0]}
          setGameMode={set.gameMode}
          include={[
            GameMode.CAPTAINS_MODE,
            GameMode.ALL_PICK_RANKED,
            GameMode.ALL_PICK,
          ]}
        />
        <FactionPageModifier
          isRadiant={state.isRadiant}
          setIsRadiant={set.isRadiant}
        />
        <FirstPickPageModifier
          firstPick={state.firstPick}
          setFirstPick={set.firstPick}
        />
      </PageModifiersContainer>
      <NonPrintablePageModifiersContainer marginTop={0}>
        <Hero
          heroIds={state.byHeroId}
          setHeroIds={set.byHeroId}
          friendHeroIds={state.withFriendHeroId}
          setFriendHeroIds={set.withFriendHeroId}
          enemyHeroIds={state.withEnemyHeroId}
          setEnemyHeroIds={set.withEnemyHeroId}
          friendBannedHeroIds={state.withFriendBannedHeroId}
          setFriendBannedHeroIds={set.withFriendBannedHeroId}
          enemyBannedHeroIds={state.withEnemyBannedHeroId}
          setEnemyBannedHeroIds={set.withEnemyBannedHeroId}
        />
        <PositionPickOrderFilter
          filterPositionIsUs={state.filterPositionIsUs}
          setFilterPositionIsUs={set.filterPositionIsUs}
          filterPosition={state.filterPosition}
          setFilterPosition={set.filterPosition}
          filterPositionOrder={state.filterPositionOrder}
          setFilterPositionOrder={set.filterPositionOrder}
        />
      </NonPrintablePageModifiersContainer>
      <OnlyPrintableContainer mb={3}>
        Other filters:
        {state.byHeroId && (
          <div>
            Heroes(Any): {state.byHeroId.reduce(renderHeroFromHeroesFilter, [])}
          </div>
        )}
        {state.withFriendHeroId && (
          <div>
            Heroes(Friend): {state.withFriendHeroId.reduce(renderHeroFromHeroesFilter, [])}
          </div>
        )}
        {state.withEnemyHeroId && (
          <div>
            Heroes(Enemy): {state.withEnemyHeroId.reduce(renderHeroFromHeroesFilter, [])}
          </div>
        )}
        {state.withFriendBannedHeroId && (
          <div>
            Heroes(Friend Banned): {state.withFriendHeroId.reduce(renderHeroFromHeroesFilter, [])}
          </div>
        )}
        {state.withEnemyBannedHeroId && (
          <div>
            Heroes(Enemy Banned): {state.withEnemyHeroId.reduce(renderHeroFromHeroesFilter, [])}
          </div>
        )}
        {state.filterPosition !== undefined
          && state.filterPositionOrder !== undefined
          && state.filterPositionIsUs !== undefined && (
          <div>
            {'Position: '}
            <PositionName enum={state.filterPosition} />
            {'; Pick order: '}
            {state.filterPositionOrder.reduce((acc, orderEnum, i, arr) => {
              acc.push((
                <React.Fragment key={orderEnum}>
                  {orderEnum.replace('_', ' ').toLowerCase()}
                  {arr[i + 1] && ', '}
                </React.Fragment>
              ));
              return acc;
            }, [])}
            {'; Team: '}
            {state.filterPositionIsUs ? 'Us' : 'Opponent'}
            .
          </div>
        )}
      </OnlyPrintableContainer>
    </>
  );
};

Filters.propTypes = {
  state: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  dispatch: PropTypes.func.isRequired,
  matches: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Filters;
